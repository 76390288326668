import { NgModule } from '@angular/core';
import { GoogleMap, MapMarker } from '@angular/google-maps';

import { GoogleMapsComponent } from './google-maps.component';
import { CommonModule } from '@angular/common';

const declarations = [GoogleMapsComponent];

@NgModule({
  imports: [GoogleMap, MapMarker, CommonModule],
  declarations: [...declarations],
  exports: [GoogleMap, MapMarker, ...declarations],
})
export class GoogleMapsModule {}
