<div class="data-table"
     data-cy="problem-attachments-form">

  <omg-section-title [altBackgroundColor]="true">
    PROBLEMS
    <span omgTooltip
          tooltipText="Active Problems"
          tooltipPosition="right"
          class="padding-half-horizontal">
      <span class="om-icon -small icon-alert-info"></span>
    </span>

  </omg-section-title>

  <div om-layout-gutter
       om-layout="vertical"
       class="attached-problems-table"
       data-cy="problem-attacher-table">

    <!-- Table View -->
    <ul class="padding-normal-horizontal">
      <ng-container *ngIf="!loadingSelections(); else loading">
        <ng-container *ngFor="let selectedProblem of selectedProblems(); last as isLast">
          <li om-layout
              om-layout-gutter
              class="padding-half-vertical"
              data-cy="problem-attachment-item">
            <div om-flex="80"
                 om-layout-gutter>
              <strong>{{ getProblemString(selectedProblem) }}</strong>
            </div>
            <div om-flex> {{selectedProblem.code}} </div>
            <div *ngIf="_editable()"
                 om-layout
                 om-layout-align="start center">
              <span class="om-icon clickable icon-dismiss -gray"
                    omgTooltip
                    tooltipText="Remove Problem"
                    tooltipPosition="left"
                    omgStopEvent="click"
                    (click)="onRemove(selectedProblem)"
                    data-cy="problem-remove-button"></span>
            </div>
          </li>
          <hr *ngIf="!isLast"
              class="-flush" />
        </ng-container>
      </ng-container>
    </ul>

    <!-- Selection Search Bar -->
    <ng-container *ngIf="_editable()">
      <div class="padding-normal-horizontal">
        <form [formGroup]="form">
          <omg-auto-complete omgStopEvent
                             placeholder="Add active problem"
                             formControlName="linkedProblems"
                             bindLabel="problemType.clinicalDescription"
                             [items]="availableProblems()"
                             [hideClearAll]="true"
                             data-cy="problem-attacher-table-input">

            <ng-template let-optionData="optionData">
              <div om-layout
                   om-layout-gutter>
                <div om-flex="80">
                  {{ getProblemString(optionData) }}
                </div>
                <div om-flex> {{optionData.code }} </div>
              </div>
            </ng-template>

          </omg-auto-complete>
        </form>
      </div>
    </ng-container>

  </div>
</div>

<!-- Loading Template -->
<ng-template #loading>
  <omg-skeleton width="100%"
                height="24px"
                om-flex></omg-skeleton>
</ng-template>
