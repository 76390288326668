import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'omg-ml-suggestion-dismissed',
  templateUrl: './ml-suggestion-dismissed.component.html',
  styleUrls: ['./ml-suggestion-dismissed.component.scss'],
})
export class MlSuggestionDismissedComponent {
  @Input() text: string;

  @Output() giveFeedback = new EventEmitter();

  constructor() { }
}
