/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type SaveFeedbackMutationVariables = Types.Exact<{
  input: Types.SaveAppointmentRecordingFeedbackInput;
}>;


export type SaveFeedbackMutation = { __typename?: 'Mutation', saveAppointmentRecordingFeedback?: { __typename?: 'SaveAppointmentRecordingFeedbackPayload', success: boolean } | null };

export type SaveDetailedLaunchFeedbackMutationVariables = Types.Exact<{
  input: Types.SaveAppointmentRecordingDetailedLaunchFeedbackInput;
}>;


export type SaveDetailedLaunchFeedbackMutation = { __typename?: 'Mutation', saveAppointmentRecordingDetailedLaunchFeedback?: { __typename?: 'SaveAppointmentRecordingDetailedLaunchFeedbackPayload', success: boolean, appointmentRecording?: { __typename?: 'AppointmentRecording', detailedLaunchFeedback?: unknown | null } | null } | null };

export const SaveFeedbackDocument = gql`
    mutation SaveFeedback($input: SaveAppointmentRecordingFeedbackInput!) {
  saveAppointmentRecordingFeedback(input: $input) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveFeedbackMutationService extends Mutation<SaveFeedbackMutation, SaveFeedbackMutationVariables> {
    document = SaveFeedbackDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }
export const SaveDetailedLaunchFeedbackDocument = gql`
    mutation SaveDetailedLaunchFeedback($input: SaveAppointmentRecordingDetailedLaunchFeedbackInput!) {
  saveAppointmentRecordingDetailedLaunchFeedback(input: $input) {
    success
    appointmentRecording {
      detailedLaunchFeedback
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveDetailedLaunchFeedbackMutationService extends Mutation<SaveDetailedLaunchFeedbackMutation, SaveDetailedLaunchFeedbackMutationVariables> {
    document = SaveDetailedLaunchFeedbackDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }