import { effect, Injectable, signal, Signal } from '@angular/core';

export interface StopwatchSignal {
  /**
   * The current value of the stopwatch in ms. If the value is 0, the stopwatch
   * hasn't been started yet.
   */
  timeElapsedMs: Signal<number>;
  running: Signal<boolean>;
  /**
   * Starts the stopwatch.
   */
  start: () => void;
  /**
   * Pauses the stopwatch, keeping the current `value`.
   */
  stop: () => void;
  /**
   * Clears the stopwatch, setting the value to 0.
   */
  reset: () => void;
}

/**
 * Stopwatch signal. Lets you start and stop a ticking stopwatch.
 */
export function stopwatchSignal(): StopwatchSignal {
  const currentCountMs = signal<number>(0);
  const shouldRun = signal(false);

  effect(onCleanup => {
    if (shouldRun()) {
      let prevTime = Date.now();
      const interval = setInterval(() => {
        const newTime = Date.now();
        const increment = newTime - prevTime;
        currentCountMs.update(value => value + increment);
        prevTime = newTime;
      }, 10);
      onCleanup(() => {
        clearInterval(interval);
      });
    }
  });

  return {
    timeElapsedMs: currentCountMs,
    running: shouldRun,
    start: (): void => {
      shouldRun.set(true);
    },
    stop: (): void => {
      shouldRun.set(false);
    },
    reset: (): void => {
      shouldRun.set(false);
      currentCountMs.set(0);
    },
  };
}

@Injectable({
  providedIn: 'root',
})
export class StopwatchSignalService {
  createStopwatchSignal = stopwatchSignal;
}
