import { Component, inject, Input, ViewChild } from '@angular/core';
import { HealthscribeRecorderService } from '../healthscribe-recorder.service';
import {
  RecordingControlComponent,
  RENDER_MODE,
} from '../recording-control/recording-control.component';
import { AnalyticsService } from '@app/core';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { DefaultAnalyticsProps } from '@app/core/analytics/default-analytics-props';

@Component({
  selector: 'omg-recording-manager',
  templateUrl: './recording-manager.component.html',
})
export class RecordingManagerComponent {
  @Input() mode: RENDER_MODE;
  @Input({ required: true }) appointmentId: string;

  @ViewChild('recordingControl') recordingControl: RecordingControlComponent;

  private readonly recordingManagerAnalyticsProps = {
    ...DefaultAnalyticsProps,
    component: 'Recording Manager',
  };

  private healthscribeRecorderService = inject(HealthscribeRecorderService);
  private analyticsService = inject(AnalyticsService);

  recordingState = this.healthscribeRecorderService.recordingState;

  currentDurationMs = this.healthscribeRecorderService.currentDuration;

  pauseRecording() {
    this.healthscribeRecorderService.pauseRecording();
    this.analyticsService.track(
      AnalyticsEvent.HealthScribePauseRecordingClicked,
      this.recordingManagerAnalyticsProps,
    );
  }

  record() {
    this.healthscribeRecorderService.startRecording(this.appointmentId);
    this.analyticsService.track(
      AnalyticsEvent.HealthScribeStartRecordingClicked,
      this.recordingManagerAnalyticsProps,
    );
  }

  endRecording() {
    this.healthscribeRecorderService.endRecording();
    this.analyticsService.track(
      AnalyticsEvent.HealthScribeSummarizeRecordingClicked,
      this.recordingManagerAnalyticsProps,
    );
  }
}
