import { Injectable } from '@angular/core';
import {
  IMediaRecorder,
  IMediaRecorderOptions,
  MediaRecorder as ExtendableMediaRecorder,
} from 'extendable-media-recorder';

/**
 * Mock service so we don't have to mock globals.
 */
@Injectable({
  providedIn: 'root',
})
export class MediaRecorderApiService {
  isSupported(): boolean {
    return 'MediaRecorder' in window && Boolean(window.MediaRecorder);
  }

  async getMediaStream(
    constraints: MediaStreamConstraints = {},
  ): Promise<MediaStream> {
    return window.navigator.mediaDevices.getUserMedia(constraints);
  }

  newMediaRecorder(
    mediaStream: MediaStream,
    options: IMediaRecorderOptions = {},
  ): IMediaRecorder {
    return new ExtendableMediaRecorder(mediaStream, options);
  }
}
