import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { DocumentNode, print } from 'graphql';
import { Observable } from 'rxjs';

import { DetailedError } from '../errors/detailed-errors.type';
import { mapGraphQLResponse } from './graphql.utils';

export interface GraphQLResponse<T> {
  data?: T;
  errors?: any[];
  detailedErrors?: DetailedError[];
}

/**
 * @deprecated Moving forward new calls to graphql endpoints should use the apollo client.  See https://github.com/onemedical/onelife-ui/pull/5268
 */
@Injectable()
export class GraphQLService {
  constructor(private http: HttpClient) { }

  query<T>(
    query: DocumentNode,
    queryVariables?: Object,
  ): Observable<T | undefined> {
    const queryString = print(query);
    const endpoint = `${environment.api.baseUrl}/graphql`;
    const headers = this.getHeaders();
    const body = {
      query: queryString,
      variables: queryVariables,
    };
    return this.http
      .post<GraphQLResponse<T>>(endpoint, body, { headers })
      .pipe(mapGraphQLResponse<T>());
  }

  private getHeaders() {
    return new HttpHeaders().set('Content-Type', 'application/json');
  }
}
