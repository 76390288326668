import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';

import { FeatureFlagNames } from '../feature-flag/shared/feature-flag.type';
import {
  isSupportedTheme,
  jadeOnemTheme,
  onemTheme,
  Theme,
} from './theme.type';
import { applyThemeProperties } from './theme.util';

@Injectable()
export class ThemeService {
  private themeChange = new BehaviorSubject<Theme>(onemTheme);
  themeChange$ = this.themeChange.asObservable();

  constructor(ldService: LaunchDarklyService) {
    ldService
      .variation$(FeatureFlagNames.frontendPlatformJadeTheme, false)
      .subscribe(useNewTheme => {
        const theme = useNewTheme ? jadeOnemTheme : onemTheme;
        this.setTheme(theme);
      });
  }

  setTheme(theme: Theme) {
    if (!isSupportedTheme(theme)) {
      throw new Error('This theme is not supported');
    }

    // this is to support the font size property change at the base html tag level - we should remove this once the metropolitan-md-theme is no longer used
    const htmlTag = document.getElementsByTagName('HTML')[0];
    const bodyTag = document.getElementsByTagName('BODY')[0];
    [htmlTag, bodyTag].forEach(el => applyThemeProperties(el, theme));
    this.themeChange.next(theme);
  }
}
