import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { sortProblemsByClinicalDescription } from '../../shared/summaries-utils';
import { SummaryProblem } from '../../shared/summaries.type';
import { isWhitespace } from '@app/shared/components/inline-insertion/inline-insertion.utils';
import {
  PatientMedication,
  PatientMedicationStatus,
} from '@app/modules/medications/shared/patient-medications.type';

@Component({
  selector: 'omg-linked-enhanced-assessment-plan',
  templateUrl: './linked-enhanced-assessment-plan.component.html',
  styleUrls: ['./linked-enhanced-assessment-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedEnhancedAssessmentPlanComponent {
  @Input() signed: boolean;
  isWhitespace = isWhitespace;

  @Input() get assessedProblems(): SummaryProblem[] {
    return this._assessedProblems;
  }

  set assessedProblems(assessedProblems: SummaryProblem[]) {
    this._assessedProblems = sortProblemsByClinicalDescription([
      ...assessedProblems,
    ]);
  }

  @Output() unlink = new EventEmitter<SummaryProblem>();

  private _assessedProblems: SummaryProblem[];

  constructor() {}

  trackByFn = (index, summaryProblem: SummaryProblem) =>
    summaryProblem.id || index;

  deleteFromNote(problem: SummaryProblem) {
    this.unlink.emit(problem);
  }

  getFilteredMedications(problem: SummaryProblem): PatientMedication[] {
    if (!problem?.attachments?.patientMedications) {
      return [];
    }
    return problem.attachments.patientMedications.filter(
      med => med.status === PatientMedicationStatus.ACTIVE,
    );
  }
}
