import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { MutationResult, QueryRef } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import {
  Problem,
  ProblemAttachmentAttachmentEnum as ProblemAttachmentType,
} from '../../../../graphql/onelife.type';
import {
  CreateProblemAttachmentMutation,
  CreateProblemAttachmentMutationService,
  DeleteProblemAttachmentMutation,
  DeleteProblemAttachmentMutationService,
  GetProblemAttachmentsQuery,
  GetProblemAttachmentsQueryService,
  GetProblemsByAttachmentDocument,
  GetProblemsByAttachmentQueryService,
} from './problem-attachments.onelife.generated';

export type GetProblemAttachmentsByProblemResponse = {
  problem: Problem;
};
export type GetProblemsByAttachmentResponse = {
  problems: Problem[];
};

export type ProblemAttachmentEdges =
  GetProblemAttachmentsQuery['problem']['attachments']['edges'];

interface GetProblemAttachmentsByProblemQueryRefTable {
  [key: string]: QueryRef<any, any>;
}

@Injectable({
  providedIn: 'root',
})
export class ProblemAttachmentsService {
  getProblemAttachmentsByProblemQueryRefs: GetProblemAttachmentsByProblemQueryRefTable =
    {};

  constructor(
    private createProblemAttachment: CreateProblemAttachmentMutationService,
    private deleteProblemAttachment: DeleteProblemAttachmentMutationService,
    private getProblemsByAttachmentQueryService: GetProblemsByAttachmentQueryService,
    private getProblemAttachmentsQueryService: GetProblemAttachmentsQueryService,
  ) {}

  createAttachment(
    problemId: number | string,
    attachableId: number | string,
    attachableType: ProblemAttachmentType,
  ): Observable<MutationResult<CreateProblemAttachmentMutation>> {
    return this.createProblemAttachment.mutate(
      {
        attachableId: attachableId.toString(),
        attachableType,
        problemId: problemId.toString(),
      },
      {
        refetchQueries: [
          {
            query: GetProblemsByAttachmentDocument,
            variables: {
              id: attachableId.toString(),
              type: attachableType,
            },
          },
          'GetProblemAttachments',
        ],
      },
    );
  }

  deleteAttachment(
    attachmentId: number | string,
  ): Observable<MutationResult<DeleteProblemAttachmentMutation>> {
    return this.deleteProblemAttachment.mutate(
      {
        attachmentId: attachmentId.toString(),
      },
      {
        refetchQueries: ['GetProblemsByAttachment', 'GetProblemAttachments'],
      },
    );
  }

  refetchAttachmentsByProblem(): void {
    for (const key in this.getProblemAttachmentsByProblemQueryRefs) {
      this.getProblemAttachmentsByProblemQueryRefs[key].refetch();
    }
  }

  getAttachmentsByProblem(
    problemId: number | string,
  ): Observable<ProblemAttachmentEdges> {
    this.getProblemAttachmentsByProblemQueryRefs[problemId.toString()] =
      this.getProblemAttachmentsQueryService.watch(
        {
          id: problemId.toString(),
        },
        { fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first' },
      );
    return this.getProblemAttachmentsByProblemQueryRefs[
      problemId.toString()
    ].valueChanges.pipe(
      map((response: ApolloQueryResult<GetProblemAttachmentsQuery>) => {
        return (response.data.problem.attachments.edges ?? []).filter(edge =>
          edge?.node?.__typename === 'Document' ? edge.node['note'] : true,
        );
      }),
    );
  }

  getProblemsByAttachment(
    attachableId: number | string,
    attachableType: ProblemAttachmentType,
  ): Observable<Problem[]> {
    return this.getProblemsByAttachmentQueryService
      .watch(
        {
          id: attachableId.toString(),
          type: attachableType,
        },
        { fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first' },
      )
      .valueChanges.pipe(
        map((response: ApolloQueryResult<GetProblemsByAttachmentResponse>) => {
          return response.data.problems;
        }),
      );
  }
}
