import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { summaryIsUnsignedStartedAppointment } from '@app/features/summaries/shared/summaries-utils';
import { Summary } from '@app/features/summaries/shared/summaries.type';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';
import { filter, map, mergeMap, Observable, of } from 'rxjs';

@Component({
  templateUrl: './readonly-recording-manager-container.component.html',
  selector: 'omg-readonly-recording-manager-container',
})
export class ReadonlyRecordingManagerContainerComponent implements OnInit {
  healthscribeInChartEnabled: boolean = false;
  appointmentId$: Observable<number | null>;

  private readonly activeSummaryUrlMatcher: RegExp =
    /chart\/summaries\/(?<summaryId>\d+)\/edit/;
  private destroyRef = inject(DestroyRef);

  constructor(
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
    private summarySelectors: SummariesSelectors,
  ) {}

  ngOnInit(): void {
    this.healthscribeInChartEnabled = this.launchDarklyService.variation(
      FeatureFlagNames.healthscribeInChart,
      false,
    );

    if (this.healthscribeInChartEnabled) {
      this.appointmentId$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => {
          // convert event to summary id or null
          const match = this.activeSummaryUrlMatcher.exec(event.url);
          const summaryIdString = match?.groups?.summaryId;
          return summaryIdString ? parseInt(summaryIdString, 10) : null;
        }),
        mergeMap((summaryId: number | null) => {
          // convert summary id to summary
          return summaryId
            ? this.summarySelectors.getSummaryById(summaryId)
            : of(null);
        }),
        map((summary: Summary | null) => {
          // convert summary to appointment id
          return summary?.appointment &&
            summaryIsUnsignedStartedAppointment(summary)
            ? summary.appointment.id
            : null;
        }),
        takeUntilDestroyed(this.destroyRef),
      );
    }
  }
}
