import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core';
import { EntityChangesPayload } from '@app/core/store/shared/entity-api-type';
import { StoreEntityState } from '@app/core/store/shared/state';
import { ProcedureOrderListItem } from '@app/features/orders-list/shared/order-list.type';
import { ProcedureOrder } from '@app/features/procedure-order/shared/procedure-order.type';
import { ProcedureSuggestion } from '@app/features/procedure-suggestion/shared/procedure-suggestion.type';

export enum ProcedureSuggestionActionType {
  GetBySummaryId = '[Procedure Suggestion] Get By Summary Id',
  GetBySummaryIdSuccess = '[Procedure Suggestion] Get By Summary Id Success',
  GetBySummaryIdError = '[Procedure Suggestion] Get By Summary Id Error',
  Dismiss = '[Procedure Suggestion] Dismiss',
  DismissSuccess = '[Procedure Suggestion] Dismiss Success',
  DismissError = '[Procedure Suggestion] Dismiss Error',
  UnsetDismissing = '[Procedure Suggestion] Unset Dismissing',
  CreateProcedureOrder = '[Procedure Suggestion] Create Procedure Order',
  CreateProcedureOrderSuccess = '[Procedure Suggestion] Create Procedure Order Success',
  CreateProcedureOrderError = '[Procedure Suggestion] Create Procedure Order Error',
  CreateProcedureInteraction = '[Procedure Suggestion] Create Procedure Interaction',
  CreateProcedureInteractionSuccess = '[Procedure Suggestion] Create Procedure Interaction Success',
  CreateProcedureInteractionError = '[Procedure Suggestion] Create Procedure Interaction Error',
  GiveFeedback = '[Proceudre Suggestion] Give Feedback',
  CloseFeedback = '[Procedure Suggestion] Close Feedback',
  SendFeedback = '[Procedure Suggestion] Send Feedback',
  SendFeedbackSuccess = '[Procedure Suggestion] Send Feedback Success',
  SendFeedbackError = '[Procedure Suggestion] Send Feedback Error',
  DeleteProcedureOrder = '[ProcedureOrder] Delete Procedure Order',
  DeleteProcedureOrderSuccess = '[ProcedureOrder] Delete Procedure Order Success',
  DeleteProcedureOrderError = '[ProcedureOrder] Delete Procedure Order Error',
  SaveProcedureOrder = '[ProcedureOrder] Save Procedure Order',
  SaveProcedureOrderSuccess = '[ProcedureOrder] Save Procedure Order Success',
  SaveProcedureOrderError = '[ProcedureOrder] Save Procedure Order Error',
  SaveProcedureInteraction = '[Procedure Suggestion] Save Procedure Interaction',
  SaveProcedureInteractionSuccess = '[Procedure Suggestion] Save Procedure Interaction Success',
  SaveProcedureInteractionError = '[Procedure Suggestion] Save Procedure Interaction Error',
  DeleteProcedureInteraction = '[Procedure Suggestion] Delete Procedure Interaction',
  DeleteProcedureInteractionSuccess = '[Procedure Suggestion] Delete Procedure Interaction Success',
  DeleteProcedureInteractionError = '[Procedure Suggestion] Delete Procedure Interaction Error',
  UpdateProcedureOrder = '[ProcedureOrder] Update Procedure Order',
  UpdateProcedureOrderSuccess = '[ProcedureOrder] Update Procedure Order Success',
  UpdateProcedureOrderError = '[ProcedureOrder] Update Procedure Order Error',
}

export interface GetProcedureSuggestionsBySummaryPayload {
  summaryId: number;
}
export class GetProcedureSuggestionsBySummary
  implements FsaAction<GetProcedureSuggestionsBySummaryPayload> {
  readonly type = ProcedureSuggestionActionType.GetBySummaryId;

  constructor(public payload: GetProcedureSuggestionsBySummaryPayload) { }
}

export interface GetProcedureSuggestionsBySummarySuccessPayload {
  procedureSuggestions: ProcedureSuggestion[];
}
export class GetProcedureSuggestionsBySummarySuccess
  implements FsaAction<GetProcedureSuggestionsBySummarySuccessPayload> {
  readonly type = ProcedureSuggestionActionType.GetBySummaryIdSuccess;

  constructor(public payload: GetProcedureSuggestionsBySummarySuccessPayload) { }
}

export interface GetProcedureSuggestionsBySummaryErrorPayload {
  error: any;
}
export class GetProcedureSuggestionsBySummaryError
  implements FsaAction<GetProcedureSuggestionsBySummaryErrorPayload> {
  readonly type = ProcedureSuggestionActionType.GetBySummaryIdError;

  constructor(public payload: GetProcedureSuggestionsBySummaryErrorPayload) { }
}

export interface DismissProcedureSuggestionPayload {
  id: number;
}
export class DismissProcedureSuggestion
  implements FsaAction<DismissProcedureSuggestionPayload> {
  readonly type = ProcedureSuggestionActionType.Dismiss;

  constructor(public payload: DismissProcedureSuggestionPayload) { }
}

export interface DismissProcedureSuggestionSuccessPayload {
  procedureSuggestion: Partial<ProcedureSuggestion>;
}
export class DismissProcedureSuggestionSuccess
  implements FsaAction<DismissProcedureSuggestionSuccessPayload> {
  readonly type = ProcedureSuggestionActionType.DismissSuccess;

  constructor(public payload: DismissProcedureSuggestionSuccessPayload) { }
}

export interface DismissProcedureSuggestionErrorPayload {
  error: any;
}
export class DismissProcedureSuggestionError
  implements FsaAction<DismissProcedureSuggestionErrorPayload> {
  readonly type = ProcedureSuggestionActionType.DismissError;

  constructor(public payload: DismissProcedureSuggestionErrorPayload) { }
}

export interface UnsetDismissingProcedureSuggestionPayload {
  id: number;
}
export class UnsetDismissingProcedureSuggestion
  implements FsaAction<UnsetDismissingProcedureSuggestionPayload> {
  readonly type = ProcedureSuggestionActionType.UnsetDismissing;

  constructor(public payload: UnsetDismissingProcedureSuggestionPayload) { }
}

export interface CreateProcedureOrderFromSuggestionPayload {
  procedureSuggestion: ProcedureSuggestion;
  order: Partial<ProcedureOrderListItem>;
}
export class CreateProcedureOrderFromSuggestion
  implements FsaAction<CreateProcedureOrderFromSuggestionPayload> {
  readonly type = ProcedureSuggestionActionType.CreateProcedureOrder;

  constructor(public payload: CreateProcedureOrderFromSuggestionPayload) { }
}

export interface CreateProcedureOrderFromSuggestionSuccessPayload {
  summaryId: number;
}
export class CreateProcedureOrderFromSuggestionSuccess
  implements FsaAction<CreateProcedureOrderFromSuggestionSuccessPayload> {
  readonly type = ProcedureSuggestionActionType.CreateProcedureOrderSuccess;

  constructor(
    public payload: CreateProcedureOrderFromSuggestionSuccessPayload,
  ) { }
}

export interface CreateProcedureOrderFromSuggestionErrorPayload {
  error: any;
}
export class CreateProcedureOrderFromSuggestionError
  implements FsaAction<CreateProcedureOrderFromSuggestionErrorPayload> {
  readonly type = ProcedureSuggestionActionType.CreateProcedureOrderError;

  constructor(public payload: CreateProcedureOrderFromSuggestionErrorPayload) { }
}

export interface CreateProcedureInteractionFromSuggestionPayload {
  patientId: number;
  problemId: number;
  procedureSuggestion: ProcedureSuggestion;
}

export class CreateProcedureInteractionFromSuggestion
  implements FsaAction<CreateProcedureInteractionFromSuggestionPayload> {
  readonly type = ProcedureSuggestionActionType.CreateProcedureInteraction;

  constructor(
    public payload: CreateProcedureInteractionFromSuggestionPayload,
  ) { }
}

export interface CreateProcedureInteractionFromSuggestionSuccessPayload {
  summaryId: number;
}

export class CreateProcedureInteractionFromSuggestionSuccess
  implements FsaAction<CreateProcedureInteractionFromSuggestionSuccessPayload> {
  readonly type =
    ProcedureSuggestionActionType.CreateProcedureInteractionSuccess;

  constructor(
    public payload: CreateProcedureInteractionFromSuggestionSuccessPayload,
  ) { }
}

export interface CreateProcedureInteractionFromSuggestionErrorPayload {
  error: any;
}
export class CreateProcedureInteractionFromSuggestionError
  implements FsaAction<CreateProcedureOrderFromSuggestionErrorPayload> {
  readonly type = ProcedureSuggestionActionType.CreateProcedureInteractionError;

  constructor(
    public payload: CreateProcedureInteractionFromSuggestionErrorPayload,
  ) { }
}
export interface GiveFeedbackPayload {
  id: number;
}
export class GiveFeedback implements FsaAction<GiveFeedbackPayload> {
  readonly type = ProcedureSuggestionActionType.GiveFeedback;

  constructor(public payload: GiveFeedbackPayload) { }
}

export interface CloseFeedbackPayload {
  id: number;
}
export class CloseFeedback implements FsaAction<CloseFeedbackPayload> {
  readonly type = ProcedureSuggestionActionType.CloseFeedback;

  constructor(public payload: CloseFeedbackPayload) { }
}

export interface SendFeedbackPayload {
  id: number;
  feedbackCategory: String;
  feedbackText: String;
}
export class SendFeedback implements FsaAction<SendFeedbackPayload> {
  readonly type = ProcedureSuggestionActionType.SendFeedback;

  constructor(public payload: SendFeedbackPayload) { }
}

export interface SendFeedbackSuccessPayload {
  id: number;
}
export class SendFeedbackSuccess
  implements FsaAction<SendFeedbackSuccessPayload> {
  readonly type = ProcedureSuggestionActionType.SendFeedbackSuccess;

  constructor(public payload: SendFeedbackSuccessPayload) { }
}

export interface SendFeedbackErrorPayload {
  error: any;
}
export class SendFeedbackError implements FsaAction<SendFeedbackErrorPayload> {
  readonly type = ProcedureSuggestionActionType.SendFeedbackError;

  constructor(public payload: SendFeedbackErrorPayload) { }
}

export interface UpdateProcedureSuggestionProcedureOrderPayload
  extends EntityChangesPayload<ProcedureOrder> {
  summaryId: number;
  patientId: number;
}

export class UpdateProcedureOrderFromSuggestion
  implements FsaAction<Partial<ProcedureOrder>> {
  readonly type = ProcedureSuggestionActionType.UpdateProcedureOrder;

  constructor(public payload: UpdateProcedureSuggestionProcedureOrderPayload) { }
}

export class UpdateProcedureOrderFromSuggestionSuccess
  implements FsaAction<Partial<ProcedureOrder>> {
  readonly type = ProcedureSuggestionActionType.UpdateProcedureOrderSuccess;

  constructor(public payload: UpdateProcedureSuggestionProcedureOrderPayload) { }
}

export interface UpdateProcedureOrderFromSuggestionErrorPayload {
  error: any;
}
export class UpdateProcedureOrderFromSuggestionError
  implements FsaAction<UpdateProcedureOrderFromSuggestionErrorPayload> {
  readonly type = ProcedureSuggestionActionType.UpdateProcedureOrderError;

  constructor(public payload: UpdateProcedureOrderFromSuggestionErrorPayload) { }
}

export class SaveProcedureOrderFromSuggestion
  implements FsaAction<Partial<ProcedureOrder>> {
  readonly type = ProcedureSuggestionActionType.SaveProcedureOrder;

  constructor(public payload: UpdateProcedureSuggestionProcedureOrderPayload) { }
}

export class SaveProcedureOrderFromSuggestionSuccess
  implements FsaAction<Partial<ProcedureOrder>> {
  readonly type = ProcedureSuggestionActionType.SaveProcedureOrderSuccess;

  constructor(public payload: UpdateProcedureSuggestionProcedureOrderPayload) { }
}

export interface SaveProcedureOrderFromSuggestionErrorPayload {
  error: any;
}
export class SaveProcedureOrderFromSuggestionError
  implements FsaAction<SaveProcedureOrderFromSuggestionErrorPayload> {
  readonly type = ProcedureSuggestionActionType.SaveProcedureOrderError;

  constructor(public payload: SaveProcedureOrderFromSuggestionErrorPayload) { }
}

export interface SaveProcedureInteractionFromSuggestionPayload {
  summaryId: number;
  patientId: number;
  procedureInteractionId: number;
}

export class SaveProcedureInteractionFromSuggestion
  implements FsaAction<SaveProcedureInteractionFromSuggestionPayload> {
  readonly type = ProcedureSuggestionActionType.SaveProcedureInteraction;

  constructor(public payload: SaveProcedureInteractionFromSuggestionPayload) { }
}

export interface SaveProcedureInteractionFromSuggestionSuccessPayload {
  patientId: number;
  summaryId: number;
}
export class SaveProcedureInteractionFromSuggestionSuccess
  implements FsaAction<SaveProcedureInteractionFromSuggestionSuccessPayload> {
  readonly type = ProcedureSuggestionActionType.SaveProcedureInteractionSuccess;

  constructor(
    public payload: SaveProcedureInteractionFromSuggestionSuccessPayload,
  ) { }
}

export interface SaveProcedureInteractionFromSuggestionErrorPayload {
  error: any;
}
export class SaveProcedureInteractionFromSuggestionError
  implements FsaAction<SaveProcedureInteractionFromSuggestionErrorPayload> {
  readonly type = ProcedureSuggestionActionType.SaveProcedureInteractionError;

  constructor(
    public payload: SaveProcedureInteractionFromSuggestionErrorPayload,
  ) { }
}

export interface DeleteProcedureOrderFromSuggestionPayload {
  id: number;
  summaryId: number;
}
export class DeleteProcedureOrderFromSuggestion
  implements FsaAction<DeleteProcedureOrderFromSuggestionPayload> {
  readonly type = ProcedureSuggestionActionType.DeleteProcedureOrder;

  constructor(public payload: DeleteProcedureOrderFromSuggestionPayload) { }
}
export class DeleteProcedureOrderFromSuggestionSuccess
  implements FsaAction<Partial<ProcedureOrder>> {
  readonly type = ProcedureSuggestionActionType.DeleteProcedureOrderSuccess;

  constructor(public summaryId: number) { }
}

export interface DeleteProcedureOrderFromSuggestionErrorPayload {
  error: any;
}
export class DeleteProcedureOrderFromSuggestionError
  implements FsaAction<DeleteProcedureOrderFromSuggestionErrorPayload> {
  readonly type = ProcedureSuggestionActionType.DeleteProcedureOrderError;

  constructor(public payload: DeleteProcedureOrderFromSuggestionErrorPayload) { }
}

export interface DeleteProcedureInteractionFromSuggestionPayload {
  id: number;
  summaryId: number;
}
export class DeleteProcedureInteractionFromSuggestion
  implements FsaAction<DeleteProcedureInteractionFromSuggestionPayload> {
  readonly type = ProcedureSuggestionActionType.DeleteProcedureInteraction;

  constructor(
    public payload: DeleteProcedureInteractionFromSuggestionPayload,
  ) { }
}

export interface DeleteProcedureInteractionFromSuggestionSuccessPayload {
  summaryId: number;
}

export class DeleteProcedureInteractionFromSuggestionSuccess
  implements FsaAction<DeleteProcedureInteractionFromSuggestionSuccessPayload> {
  readonly type =
    ProcedureSuggestionActionType.DeleteProcedureInteractionSuccess;

  constructor(public summaryId: number) { }
}

export interface DeleteProcedureInteractionFromSuggestionErrorPayload {
  error: any;
}
export class DeleteProcedureInteractionFromSuggestionError
  implements FsaAction<DeleteProcedureInteractionFromSuggestionErrorPayload> {
  readonly type = ProcedureSuggestionActionType.DeleteProcedureInteractionError;

  constructor(
    public payload: DeleteProcedureInteractionFromSuggestionErrorPayload,
  ) { }
}

export type ProcedureSuggestionAction =
  | GetProcedureSuggestionsBySummary
  | GetProcedureSuggestionsBySummarySuccess
  | GetProcedureSuggestionsBySummaryError
  | DismissProcedureSuggestion
  | DismissProcedureSuggestionSuccess
  | DismissProcedureSuggestionError
  | UnsetDismissingProcedureSuggestion
  | CreateProcedureOrderFromSuggestion
  | CreateProcedureOrderFromSuggestionSuccess
  | CreateProcedureOrderFromSuggestionError
  | CreateProcedureInteractionFromSuggestion
  | CreateProcedureInteractionFromSuggestionSuccess
  | CreateProcedureInteractionFromSuggestionError
  | GiveFeedback
  | CloseFeedback
  | SendFeedback
  | SendFeedbackSuccess
  | SendFeedbackError
  | UpdateProcedureOrderFromSuggestion
  | UpdateProcedureOrderFromSuggestionSuccess
  | UpdateProcedureOrderFromSuggestionError
  | SaveProcedureOrderFromSuggestion
  | SaveProcedureOrderFromSuggestionSuccess
  | SaveProcedureOrderFromSuggestionError
  | SaveProcedureInteractionFromSuggestion
  | SaveProcedureInteractionFromSuggestionSuccess
  | SaveProcedureInteractionFromSuggestionError
  | DeleteProcedureOrderFromSuggestion
  | DeleteProcedureOrderFromSuggestionSuccess
  | DeleteProcedureOrderFromSuggestionError
  | DeleteProcedureInteractionFromSuggestion
  | DeleteProcedureInteractionFromSuggestionSuccess
  | DeleteProcedureInteractionFromSuggestionError;

@Injectable()
export class ProcedureSuggestionActions {
  constructor(private store: Store<StoreEntityState<ProcedureSuggestion>>) { }

  getBySummaryId(summaryId: number) {
    this.store.dispatch(new GetProcedureSuggestionsBySummary({ summaryId }));
  }

  dismiss(id: number) {
    this.store.dispatch(new DismissProcedureSuggestion({ id: id }));
  }

  createProcedureOrder(
    procedureSuggestion: ProcedureSuggestion,
    order: Partial<ProcedureOrderListItem>,
  ) {
    this.store.dispatch(
      new CreateProcedureOrderFromSuggestion({ procedureSuggestion, order }),
    );
  }

  createProcedureInteraction(
    patientId: number,
    problemId: number,
    procedureSuggestion: ProcedureSuggestion,
  ) {
    this.store.dispatch(
      new CreateProcedureInteractionFromSuggestion({
        patientId,
        problemId,
        procedureSuggestion,
      }),
    );
  }

  giveFeedback(id: number) {
    this.store.dispatch(new GiveFeedback({ id: id }));
  }

  closeFeedback(id: number) {
    this.store.dispatch(new CloseFeedback({ id: id }));
  }

  sendFeedback(id: number, feedbackCategory: String, feedbackText: String) {
    this.store.dispatch(
      new SendFeedback({
        id: id,
        feedbackCategory: feedbackCategory,
        feedbackText: feedbackText,
      }),
    );
  }

  saveProcedureOrder(payload: UpdateProcedureSuggestionProcedureOrderPayload) {
    this.store.dispatch(new UpdateProcedureOrderFromSuggestion(payload));
  }

  deleteProcedureOrder(id: number, summaryId: number) {
    this.store.dispatch(
      new DeleteProcedureOrderFromSuggestion({ id: id, summaryId: summaryId }),
    );
  }

  saveProcedureInteraction(
    payload: SaveProcedureInteractionFromSuggestionPayload,
  ) {
    this.store.dispatch(new SaveProcedureInteractionFromSuggestion(payload));
  }

  deleteProcedureInteraction(
    payload: DeleteProcedureInteractionFromSuggestionPayload,
  ) {
    this.store.dispatch(new DeleteProcedureInteractionFromSuggestion(payload));
  }
}
