import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { environment } from '@environments/environment';
import { differenceInDays, parseISO } from 'date-fns';
import { HealthScribeFeedbackEvent } from './transcript-feedback-form/transcript-feedback-form.component';
import {
  SaveDetailedLaunchFeedbackMutationService,
  SaveDetailedLaunchFeedbackMutationVariables,
} from '../graphql/save-feedback.onelife.generated';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { AnalyticsService } from '@app/core/analytics';
import { DefaultAnalyticsProps } from '@app/core/analytics/default-analytics-props';
import { GetRecordingInsightsQuery } from '@app/features/summaries/components/summaries/get-recordings.onelife.generated';
import { TranscriptSectionCopyEvent } from './transcript-section/transcript-section.component';

@Component({
  selector: 'omg-healthscribe-transcript',
  templateUrl: './transcript.component.html',
  styleUrls: ['./transcript.component.scss'],
})
export class TranscriptComponent {
  @Input() recording: GetRecordingInsightsQuery['appointmentRecording'];

  @Output() contentCopied = new EventEmitter<TranscriptSectionCopyEvent>();
  @Output() closed = new EventEmitter<Event>();

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private saveDetailedLaunchFeedbackMutationService: SaveDetailedLaunchFeedbackMutationService,
    private analytics: AnalyticsService,
  ) {}

  get expirationDays(): string {
    const difference = this.recording?.createdAt
      ? 7 - differenceInDays(Date.now(), parseISO(this.recording.createdAt))
      : 7;
    if (difference > 1) {
      return `${difference} days`;
    }
    if (difference < 1) {
      return '0 days';
    }
    return '1 day';
  }

  openSettings(): void {
    window.open(this.settingsURL, '_healthscribe');
    this.analytics.track(AnalyticsEvent.HealthScribeSettingsClicked, {
      ...DefaultAnalyticsProps,
      component: 'Transcript',
    });
  }

  viewTranscript(): void {
    this.analytics.track(AnalyticsEvent.HealthScribeViewTranscriptClicked, {
      ...DefaultAnalyticsProps,
      component: 'Transcript',
    });
  }

  closeTranscript(e: Event): void {
    this.closed.emit(e);
    this.analytics.track(AnalyticsEvent.HealthScribeSummaryClosedClicked, {
      ...DefaultAnalyticsProps,
      component: 'Transcript',
    });
  }

  get transcriptURL(): string {
    return `${environment.adminApp.host}/scribe/record/${this.recording?.id}`;
  }

  get settingsURL(): string {
    return `${environment.adminApp.host}/scribe/record/${this.recording?.id}`;
  }

  submitFeedbackForm(event: HealthScribeFeedbackEvent): void {
    console.log('Feedback form submitted');
    if (!this.recording) {
      return;
    }
    const variables: SaveDetailedLaunchFeedbackMutationVariables = {
      input: {
        appointmentRecordingId: this.recording.id,
        detailedLaunchFeedback: event,
      },
    };
    this.saveDetailedLaunchFeedbackMutationService
      .mutate(variables)
      .pipe(
        map(
          result =>
            result.data?.saveAppointmentRecordingDetailedLaunchFeedback
              ?.success,
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(success => {
        if (success) {
          console.log('Successfully saved appointment recording feedback');
        } else {
          console.error('Failed to submit feedback for appointment recording');
        }
      });
  }

  feedbackFormClosed(event: any): void {
    console.log('HealthScribe feedback form closed');
  }

  feedbackFormOpened(event: any): void {
    console.log('HealthScribe feedback form opened');
  }

  sectionCopied(event: any) {
    this.contentCopied.emit(event);
    this.analytics.track(AnalyticsEvent.HealthScribePushToNoteClicked, {
      ...DefaultAnalyticsProps,
      component: 'Transcript',
      subcomponent: event.header,
    });
  }

  sectionCollapse(event: any) {
    const analyticsEvent = event.expandChange
      ? AnalyticsEvent.HealthScribeTranscriptSectionExpanded
      : AnalyticsEvent.HealthScribeTranscriptSectionCollapsed;
    this.analytics.track(analyticsEvent, {
      ...DefaultAnalyticsProps,
      component: 'Transcript',
      subcomponent: event.header,
    });
  }

  get transcriptSections(): { header: string; body: string }[] {
    if (!this.recording) return [];
    return Object.keys(this.recording)
      .filter(key => key in this.sectionName)
      .map(key => ({
        header: this.sectionName[key],
        body:
          this.recording?.[key]?.lines.map(l => l.summary).join('\r\n') ||
          'No data available.',
      }));
  }

  sectionName: { [key: string]: string } = {
    chiefComplaint: 'Chief Complaint',
    subjective: 'Subjective',
    hpi: 'History of Present Illness',
    ros: 'Review of Systems',
    pmh: 'Past Medical History',
    assessment: 'Assessment',
    plan: 'Plan',
    physicalExam: 'Physical Exam',
    assessmentAndPlan: 'Assessment + Plan',
    afterVisitSummary: 'After Visit Summary',
  };
}
