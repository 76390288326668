import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take, withLatestFrom } from 'rxjs/operators';

import { ApiService, SearchService } from '@app/core';
import { ConfigService } from '@app/core/config';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { QueryBuilder } from '@app/core/search/query-builder';
import { mapToSummaryAssessedProblemsUpdateRequest } from '@app/features/summaries/shared/summaries-api-mappers';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';

import { GetProblemsParams } from '../store/problems.actions';
import { ProblemState } from '../store/problems.reducer';
import {
  mapProblemCodeSearchResponseToEntity,
  mapProblemHistoryResponseToEntity,
  mapProblemResponseToEntity,
  mapProblemToSaveRequest,
  mapProblemToUpdateRequest,
  mapProblemTypeSearchResponseToEntity,
} from './problems-api-mappers';
import { ProblemHistoryResponse, ProblemResponse } from './problems-api.type';
import { Problem, ProblemForm } from './problems.type';

type ProblemSubRoute = 'patients' | 'problems';

type ProblemSuffix =
  | 'problems'
  | 'resolve'
  | 'reactivate'
  | 'reject'
  | 'problem_histories';

const adminRoute = '/v2/admin';

export const problemRoute = (
  subRoute: ProblemSubRoute,
  id: number,
  suffix?: ProblemSuffix,
) => {
  let resultRoute = `${adminRoute}/${subRoute}/${id}`;

  if (suffix) {
    resultRoute = `${resultRoute}/${suffix}`;
  }

  return resultRoute;
};

@Injectable()
export class ProblemsApiService {
  constructor(
    private api: ApiService,
    private summariesActions: SummariesActions,
    private searchService: SearchService,
    private config: ConfigService,
    private store: Store<ProblemState>,
    private summariesSelectors: SummariesSelectors,
    private ldService: LaunchDarklyService,
  ) {}

  query(patientId: number, params?: GetProblemsParams) {
    return this.api
      .get<
        ProblemResponse[]
      >(problemRoute('patients', patientId, 'problems'), params)
      .pipe(map(response => response.map(mapProblemResponseToEntity)));
  }

  save(patientId: number, problem: ProblemForm) {
    return this.api
      .save<ProblemResponse>(
        problemRoute('patients', patientId, 'problems'),
        mapProblemToSaveRequest(problem),
      )
      .pipe(map(mapProblemResponseToEntity));
  }

  update(patientId: number, problem: ProblemForm, isAutosave = false) {
    if (problem.id === undefined) {
      throw new Error(
        `ProblemForm.id missing when trying to update problem: ${problem}`,
      );
    }
    return this.api
      .update<ProblemResponse>(
        problemRoute('problems', problem.id),
        mapProblemToUpdateRequest(problem, isAutosave),
        { patientId },
      )
      .pipe(map(mapProblemResponseToEntity));
  }

  resolve(patientId: number, problemId: number) {
    return this.api
      .update<ProblemResponse>(
        problemRoute('problems', problemId, 'resolve'),
        null,
        { patientId },
      )
      .pipe(map(mapProblemResponseToEntity));
  }

  reactivate(patientId: number, problemId: number) {
    return this.api
      .update<ProblemResponse>(
        problemRoute('problems', problemId, 'reactivate'),
        null,
        { patientId },
      )
      .pipe(map(mapProblemResponseToEntity));
  }

  delete(patientId: number, problemId: number) {
    return this.api
      .delete<ProblemResponse>(problemRoute('problems', problemId), {
        patientId,
      })
      .pipe(map(mapProblemResponseToEntity));
  }

  queryProblemHistories(patientId: number, problemId: number) {
    return this.api
      .get<ProblemHistoryResponse>(
        problemRoute('problems', problemId, 'problem_histories'),
        { patientId },
      )
      .pipe(
        /* Attach the problemId to identify which problem the history belongs to. */
        map(response => mapProblemHistoryResponseToEntity(response, problemId)),
      );
  }

  linkSection(patientId: number, problems: Problem[]) {
    this.summariesActions.linkProblems({
      patientId,
      problems,
    });
  }

  queryProblemSearch(term: string) {
    const useEnhancedSearch = this.ldService.variation(
      FeatureFlagNames.problemSearchWithICD10Code,
      false,
    );

    let query: any;
    if (useEnhancedSearch) {
      query = {
        index: [this.config.searchIndex('problem_types')],
        size: 20,
        body: {
          sort: ['_score', 'clinical_description.keyword'],
          query: {
            bool: {
              should: [
                {
                  multi_match: {
                    query: term,
                    fields: [
                      'clinical_abbreviation^5',
                      'clinical_description^4',
                      'lay_description^2',
                      'tags',
                    ],
                    operator: 'and',
                  },
                },
                {
                  match_phrase_prefix: {
                    problem_codes: term,
                  },
                },
              ],
            },
          },
        },
      };
    } else {
      query = new QueryBuilder('function_score_v6').build(term, {
        size: '8',
        fields: [
          'clinical_abbreviation^5',
          'clinical_description^4',
          'lay_description^2',
          'tags',
        ],
        operator: 'and',
        sort: ['_score', 'clinical_description.keyword'],
        index: [this.config.searchIndex('problem_types')],
      });
    }

    return this.searchService.search(query).pipe(
      map(response => {
        const hits = response.hits || {};
        const items = hits.hits || [];
        return items.map(hit => hit._source);
      }),
      map(items =>
        items.map(item =>
          mapProblemTypeSearchResponseToEntity(item, term, useEnhancedSearch),
        ),
      ),
    );
  }

  queryProblemCodeSearch(term: string) {
    const query = {
      index: [this.config.searchIndex('problem_codes')],
      size: 20,
      body: {
        sort: ['_score'],
        query: {
          bool: {
            should: [
              {
                // use a simple_query_string query so that the user can search for terms like 'C2/C3 cervical' without
                // needing to escape the slash.
                // the field mapping for the 'detail_description.words' uses an edge ngram tokenizer so we get partial
                // description matches as the user types.
                simple_query_string: {
                  query: term,
                  fields: ['detail_description.words', 'extensions'],
                  // use 'and' operator so that only documents which contain all the search term tokens will be matched
                  default_operator: 'and',
                },
              },
              {
                // perform prefix matching on the 'code' field to show partial code matches as the user types.
                // we use match_phrase_prefix query (as opposed to term prefix query) because
                // it uses the custom field analyzer defined in the elasticsearch mapping which
                // normalizes the search term before doing the prefix matching so the user can
                // search for 'c26' and see codes like 'C26.101'.
                match_phrase_prefix: {
                  code: term,
                },
              },
            ],
          },
        },
      },
    };
    return this.searchService.search(query).pipe(
      map(response => {
        const items = response?.hits?.hits || [];
        return items
          .map(hit => hit._source)
          .map(item => mapProblemCodeSearchResponseToEntity(item, term));
      }),
    );
  }

  linkAssessed(
    patientId: number,
    problemId: number,
    historyId: number,
    eventKey: string,
  ) {
    this.store
      .pipe(
        withLatestFrom(
          this.summariesSelectors.hasActiveSummary,
          this.summariesSelectors.hasAssessedProblem(problemId),
        ),
        take(1),
      )
      .subscribe(([state, hasActiveSummary, hasAssessedProblem]) => {
        if (hasActiveSummary) {
          this.summariesActions.linkAssessedProblems({
            patientId,
            problemId,
            historyId,
            hasAssessedProblem: !!hasAssessedProblem,
            eventKey,
          });
        }
      });
  }

  unlinkAssessed(patientId: number, historyIds: number[]) {
    this.summariesActions.unlink({
      patientId,
      type: 'Problems',
      extraFields: {
        ...mapToSummaryAssessedProblemsUpdateRequest(historyIds),
      },
    });
  }
}
