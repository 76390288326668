/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface TranscriptSectionCopyEvent {
  header: string | null;
  body: string | null;
}

interface TranscriptSectionExpandChangeEvent {
  header: string | null;
  expandChange: boolean;
}

@Component({
  selector: 'omg-healthscribe-transcript-section',
  templateUrl: './transcript-section.component.html',
  styleUrls: ['./transcript-section.component.scss'],
})
export class TranscriptSectionComponent {
  @Input() header: string;
  @Input() body: string;

  @Output() copied = new EventEmitter<TranscriptSectionCopyEvent>();
  @Output() collapse = new EventEmitter<TranscriptSectionExpandChangeEvent>();

  copySection(e: Event): void {
    const event: TranscriptSectionCopyEvent = {
      header: this.header,
      body: this.body,
    };
    this.copied.emit(event);
    e.stopPropagation();
  }

  analyticsExpandedChange(e: boolean): void {
    const event: TranscriptSectionExpandChangeEvent = {
      header: this.header,
      expandChange: e,
    };
    if (event.header !== undefined) {
      this.collapse.emit(event);
    }
  }
}
