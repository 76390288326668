import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Comment } from '../shared/comments.type';
import {
  ChangeRxCommentsAction,
  ChangeRxCommentsActionTypes,
} from './change-rx-comments.actions';
import { deleteCommentReducer } from './comments.reducer.utils';

export const changeRxCommentsStatePath = 'changeRxComments';

export interface ChangeRxCommentsState extends EntityState<Comment> {
  error: any;
  pending: boolean;
}

function selectChangeRxCommentsId(comments: Comment): number {
  return comments.id;
}

const adapter: EntityAdapter<Comment> = createEntityAdapter<Comment>({
  selectId: selectChangeRxCommentsId,
});

export const changeRxCommentsInitialState: ChangeRxCommentsState = adapter.getInitialState(
  {
    error: null,
    pending: false,
  },
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export function changeRxCommentsReducer(
  state = changeRxCommentsInitialState,
  action: ChangeRxCommentsAction,
): ChangeRxCommentsState {
  switch (action.type) {
    case ChangeRxCommentsActionTypes.GetChangeRxComments: {
      return { ...state, pending: true };
    }

    case ChangeRxCommentsActionTypes.GetChangeRxCommentsSuccess: {
      state = { ...state, error: null, pending: false };
      return adapter.setAll(action.payload, state);
    }

    case ChangeRxCommentsActionTypes.GetChangeRxCommentsError: {
      return { ...state, error: action.payload, pending: false };
    }

    case ChangeRxCommentsActionTypes.CreateChangeRxComment: {
      return { ...state, error: null, pending: true };
    }

    case ChangeRxCommentsActionTypes.CreateChangeRxCommentSuccess: {
      return adapter.addOne(action.payload, {
        ...state,
        error: null,
        pending: false,
      });
    }

    case ChangeRxCommentsActionTypes.CreateChangeRxCommentError: {
      return { ...state, error: action.payload, pending: false };
    }

    default: {
      return <any>deleteCommentReducer(state, action, adapter);
    }
  }
}
