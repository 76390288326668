/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type StartRecordingMutationVariables = Types.Exact<{
  input: Types.StartAppointmentRecordingInput;
}>;


export type StartRecordingMutation = { __typename?: 'Mutation', startAppointmentRecording?: { __typename?: 'StartAppointmentRecordingPayload', success: boolean, appointmentRecording?: { __typename?: 'AppointmentRecording', id: string } | null } | null };

export const StartRecordingDocument = gql`
    mutation StartRecording($input: StartAppointmentRecordingInput!) {
  startAppointmentRecording(input: $input) {
    success
    appointmentRecording {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StartRecordingMutationService extends Mutation<StartRecordingMutation, StartRecordingMutationVariables> {
    document = StartRecordingDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }