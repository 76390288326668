/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type CreateTaskMutationVariables = Types.Exact<{
  task_template_id: Types.Scalars['ID']['input'];
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  keywords?: Types.InputMaybe<Types.Scalars['String']['input']>;
  patient_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type CreateTaskMutation = { __typename?: 'Mutation', createTask?: { __typename?: 'CreateTaskPayload', success: boolean, errors?: unknown | null, task?: { __typename?: 'Task', id: string } | null } | null };

export const CreateTaskDocument = gql`
    mutation CreateTask($task_template_id: ID!, $name: String, $keywords: String, $patient_id: ID) {
  createTask(
    input: {taskTemplateId: $task_template_id, name: $name, keywords: $keywords, patientId: $patient_id}
  ) {
    success
    errors
    task {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTaskMutationService extends Mutation<CreateTaskMutation, CreateTaskMutationVariables> {
    document = CreateTaskDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }