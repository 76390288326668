import { Component, Input, OnInit } from '@angular/core';

import { Commentable } from '@app/modules/comments/shared/comments.type';

import { ChangeRx, ChangeRxTypes } from '../../shared/change-rx.type';
import { ChangeRxActions } from '../../store/change-rx.actions';

@Component({
  selector: 'omg-change-item-subheader',
  templateUrl: './change-item-subheader.component.html',
  styleUrls: ['./change-item-subheader.component.scss'],
})
export class ChangeItemSubheaderComponent implements OnInit {
  @Input() changeRx: ChangeRx;
  showComments = false;
  commentable: Commentable;
  changeRxTypes = ChangeRxTypes;

  constructor(private changeRxActions: ChangeRxActions) { }

  ngOnInit() {
    this.setupSelectors();
    if (this.changeRx.totalComments > 0) {
      this.toggleComments();
    }
  }

  toggleComments() {
    this.showComments = !this.showComments;
  }

  updateCommentCount(action: 'add' | 'remove') {
    const adjustByCount = action === 'add' ? 1 : -1;
    this.changeRxActions.updateChangeRxCommentTotal(
      this.changeRx.id,
      this.changeRx.totalComments + adjustByCount,
    );
  }

  private setupSelectors() {
    const { id, totalComments } = this.changeRx;
    this.commentable = { id, totalComments, commentableType: 'change_rx' };
  }
}
