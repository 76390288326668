import { Component, OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';

import { ConfigService, PatientSelectors } from '@app/core';
import { PersonalTaskForm } from '@app/features/workspace/components/personal-task-modal/personal-task-form';
import { CreateTaskMutationService } from '@app/features/workspace/graphql/create-task.onelife.generated';
import { DialogRef } from '@app/shared/components/dialog';
import { ToastMessageService } from '@app/shared/components/toast';
import { filterTruthy } from '@app/utils';

const personalTaskTemplateId = '65';
@Component({
  selector: 'omg-new-personal-task-modal',
  templateUrl: './new-personal-task-modal.component.html',
  styleUrls: ['./new-personal-task-modal.component.scss'],
})
export class NewPersonalTaskModalComponent implements OnInit {
  form: PersonalTaskForm;
  constructor(
    private configService: ConfigService,
    public dialogRef: DialogRef<NewPersonalTaskModalComponent, boolean>,
    private patientSelectors: PatientSelectors,
    private toastMessageService: ToastMessageService,
    private createTaskMutationService: CreateTaskMutationService,
  ) {}
  ngOnInit(): void {
    this.form = new PersonalTaskForm();
  }
  close(): void {
    this.dialogRef.close(false);
  }

  createTask(): void {
    const taskUrl = `${this.configService.environment.adminApp.host}/schedule/tasks/mine`;
    this.patientSelectors.patientId
      .pipe(
        filterTruthy(),
        switchMap(patientId =>
          this.createTaskMutationService.mutate({
            task_template_id: personalTaskTemplateId,
            name: 'Personal',
            keywords: this.form.controls.get('description')?.value,
            patient_id: patientId.toString(),
          }),
        ),
      )
      .subscribe(() => {
        this.close();
        this.toastMessageService.add({
          severity: 'success',
          summary: 'Personal task created',
          detail: `You can view and manage your task in your <a href=${taskUrl} target="_blank">task queue</a>`,
        });
      });
  }
}
