import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import {
  VitalsByType,
  vitalTypes,
} from '@app/modules/vitals-data/shared/vitals-data.type';

@Component({
  selector: 'omg-summary-growth-chart',
  templateUrl: './summary-growth-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryGrowthChartComponent implements OnInit {
  @Input() vitals: VitalsByType;
  @Input() forInfant: boolean;

  weight: number;
  height: number;
  weightForLength: number;
  headCircumference: number;
  BMI: number;

  constructor() { }

  ngOnInit(): void {
    this.weight = this.vitals[vitalTypes.weightPercentile]?.value;
    this.height = this.vitals[vitalTypes.heightPercentile]?.value;
    this.headCircumference =
      this.vitals[vitalTypes.headCircumferencePercentile]?.value;
    this.BMI = this.vitals[vitalTypes.bmiPercentile]?.value;
    this.weightForLength =
      this.vitals[vitalTypes.weightForLengthPercentile]?.value;
  }
}
