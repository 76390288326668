import {
  Component,
  computed,
  effect,
  EventEmitter,
  Input,
  Output,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';

import { Problem } from '../../shared/problems.type';
import { ProblemSelectors } from '../../store/problems.selectors';
import { getProblemString } from '../../shared/problems-utils';

/**
 * A view component to display a current selection of problems. This table also provides an option to search
 * for new problems to added to our selection list.
 *
 * The parent using this component should handle updating the selection list (and any backend API calls) when a
 * selection is added or removed.
 */
@Component({
  selector: 'omg-problem-attacher-table',
  templateUrl: './problem-attacher-table.component.html',
  styleUrls: ['./problem-attacher-table.component.scss'],
})
export class ProblemAttacherTableComponent {
  /**
   * List of currently selected problem Ids.
   * If undefined is set, then we assume this selections are still loading, and we will show a loading skelton.
   */
  @Input() set currentSelectionIds(selectedIds: number[] | undefined | null) {
    // this can be remove in the future for input([]) once we move to angular 17
    if (selectedIds === undefined || selectedIds === null) {
      this.loadingSelections.set(true);
    } else {
      this.loadingSelections.set(false);
      this._currentSelectionIds.set(selectedIds);
    }
  }
  private _currentSelectionIds: WritableSignal<number[]> = signal([]);

  /**
   * If editable is true, then we can remove problems from our selection list, and search to add new ones to the list.
   * If false, then the table is read-only.
   */
  @Input() set editable(editable: boolean) {
    // this can be remove in the future for input(true) once we move to angular 17
    this._editable.set(editable);
  }
  _editable: WritableSignal<boolean> = signal(true);

  @Output() selectionRemoved: EventEmitter<Problem> =
    new EventEmitter<Problem>();
  @Output() selectionAdded: EventEmitter<Problem> = new EventEmitter<Problem>();

  loadingSelections: WritableSignal<boolean> = signal(false);
  selectedProblems: Signal<Problem[]>;
  form: FormGroup<any>;
  availableProblems: Signal<Problem[]>;
  get getProblemString() {
    return getProblemString;
  }

  constructor(
    private problemSelectors: ProblemSelectors,
    private formBuilder: UntypedFormBuilder,
  ) {
    const allActiveProblems: Signal<Problem[]> = toSignal(
      this.problemSelectors.activeProblems,
      {
        initialValue: [],
      },
    );

    this.selectedProblems = computed(() => {
      return allActiveProblems().filter(p =>
        this._currentSelectionIds()?.includes(p.id),
      );
    });

    this.availableProblems = computed(() => {
      return allActiveProblems().filter(
        p => !this._currentSelectionIds()?.includes(p.id),
      );
    });

    this.form = this.formBuilder.group({
      linkedProblems: new FormControl<Problem[]>([]),
    });

    // handle a new selection being made
    const formSelection = toSignal(
      this.form.get('linkedProblems')!.valueChanges,
      { initialValue: null },
    );
    effect(
      () => {
        const selection = formSelection();
        if (selection != null) {
          this.onAdd(selection);
        }
      },
      { allowSignalWrites: true },
    ); // call might fail if this isn't set. Looks like an angular bug that is resolved in newer version (17+)
  }

  onAdd(problem: Problem) {
    this.selectionAdded.emit(problem);
    this.form.reset(); // reset form so we don't show the selection in the select bar
  }

  onRemove(problem: Problem) {
    this.selectionRemoved.emit(problem);
  }
}
