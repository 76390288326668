
export interface Theme {
  name: string;
  class?: string;
  properties?: any;
}

export const metropolitanMDTheme: Theme = {
  name: 'metropolitanMD',
  class: 'metropolitan-md-theme',
  properties: {
    '--base-font-size': '14px',
  },
};

export const onemTheme: Theme = {
  name: 'onem',
  class: 'onem-theme',
  properties: {
    '--base-font-size': '16px',
    '--mdc-layout-grid-gutter-desktop': '16px',
    '--mdc-layout-grid-margin-desktop': '16px',
    '--mdc-layout-grid-column-width-desktop': '72px',
    '--mdc-typography-font-family':
      'Inter, Tahoma, Geneva, Verdana, sans-serif',
    '--mdc-typography-body2-font-size': '14px',
    '--mdc-typography-body1-font-size': '16px',
    '--mdc-typography-button-letter-spacing': '0',
    // text colors
    '--mdc-theme-text-primary': '#000000DE',
    '--mdc-theme-text-secondary': '#00000099',
    '--mdc-theme-text-disabled': '#00000061',
    // main colors
    '--mdc-theme-primary': '#068466', // emerald
    '--mdc-theme-primary-light': '#9BCEC2', // pacific
    '--mdc-theme-primary-dark': '#045B53', // jade
    '--mdc-theme-on-primary': '#FFFFFF',
    '--mdc-theme-on-primary-light': '#000000DE',
    '--mdc-theme-on-primary-dark': '#FFFFFF',
    '--mdc-theme-primary-background': '#06846629',
    '--mdc-theme-primary-border': '#06846680',
    '--mdc-theme-secondary': '#0358B2', // sapphire
    '--mdc-theme-secondary-light': '#9ABCE0', // twilight
    '--mdc-theme-secondary-dark': '#023367', // midnight
    '--mdc-theme-on-secondary': '#FFFFFF',
    '--mdc-theme-on-secondary-light': '#000000DE',
    '--mdc-theme-on-secondary-dark': '#FFFFFF',
    '--mdc-theme-secondary-background': '#0358B214',
    '--mdc-theme-secondary-border': '#0358B280',
    '--mdc-theme-tertiary': '#E7DED4', // clay
    '--mdc-theme-tertiary-light': '#F3F0EB', // silt
    '--mdc-theme-tertiary-dark': '#C0AF9B', // sand
    '--mdc-theme-on-tertiary': '#000000DE',
    '--mdc-theme-on-tertiary-light': '#000000DE',
    '--mdc-theme-on-tertiary-dark': '#000000DE',
    '--mdc-theme-tertiary-background': '#E7DED43D',
    '--mdc-theme-tertiary-border': '#E7DED480',
    // semantic colors
    '--mdc-theme-info': '#2B65BA',
    '--mdc-theme-info-light': '#749BD8',
    '--mdc-theme-info-dark': '#124394',
    '--mdc-theme-on-info': '#FFFFFF',
    '--mdc-theme-on-info-light': '#000000DE',
    '--mdc-theme-on-info-dark': '#FFFFFF',
    '--mdc-theme-info-background': '#2B65BA14',
    '--mdc-theme-info-border': '#2B65BA80',
    '--mdc-theme-success': '#A9CB74',
    '--mdc-theme-success-light': '#C6E09C',
    '--mdc-theme-success-dark': '#0D6804',
    '--mdc-theme-on-success': '#000000DE',
    '--mdc-theme-on-success-light': '#000000DE',
    '--mdc-theme-on-success-dark': '#FFFFFF',
    '--mdc-theme-success-background': '#A9CB743D',
    '--mdc-theme-success-border': '#A9CB7480',
    '--mdc-theme-warn': '#FF9800',
    '--mdc-theme-warn-light': '#FFC776',
    '--mdc-theme-warn-dark': '#EA7000',
    '--mdc-theme-on-warn': '#000000DE',
    '--mdc-theme-on-warn-light': '#000000DE',
    '--mdc-theme-on-warn-dark': '#000000DE',
    '--mdc-theme-warn-background': '#FF980033',
    '--mdc-theme-warn-border': '#FF980080',
    '--mdc-theme-error': '#BB2E3C',
    '--mdc-theme-error-light': '#FF918A',
    '--mdc-theme-error-dark': '#982430',
    '--mdc-theme-on-error': '#FFFFFF',
    '--mdc-theme-on-error-light': '#000000DE',
    '--mdc-theme-on-error-dark': '#FFFFFF',
    '--mdc-theme-error-background': '#BB2E3C33',
    '--mdc-theme-error-border': '#BB2E3C80',
    '--mdc-theme-priority-background': '#BB2E3C19',

    // expressive colors
    '--mdc-theme-expressive-sage': '#49AB94',
    '--mdc-theme-expressive-sky': '#39ACBC',
    '--mdc-theme-expressive-aubergine': '#7779C3',
    '--mdc-theme-expressive-quinoa': '#E8CAA6',
    '--mdc-theme-expressive-pumpkin': '#FF8C55',
    '--mdc-theme-expressive-lavender': '#9EA5D4',
    // greys
    '--mdc-theme-white': '#FFFFFF',
    '--mdc-theme-grey50': '#FAFAFA',
    '--mdc-theme-grey100': '#F5F5F5',
    '--mdc-theme-grey200': '#EEEEEE',
    '--mdc-theme-grey300': '#E0E0E0',
    '--mdc-theme-grey400': '#BDBDBD',
    '--mdc-theme-grey500': '#9E9E9E',
    '--mdc-theme-grey600': '#757575',
    '--mdc-theme-grey700': '#616161',
    '--mdc-theme-grey800': '#424242',
    '--mdc-theme-grey900': '#212121',
    // temporary variables
    '--card-title-background-color': '#045B53',
    '--nav-item-hover-color': '#ffffff30',
    '--warning-message-color': '#a67d12',
  },
};

export const jadeOnemTheme: Theme = {
  ...onemTheme,
  properties: {
    ...onemTheme.properties,
    '--card-title-background-color': '#045B53',
  },
};

export const availableThemes = [onemTheme, jadeOnemTheme];

export const isSupportedTheme = (theme: Theme) => {
  return availableThemes.includes(theme);
};
