import {
  MLFeedbackDTO,
  MLNoteSuggestionDTO,
  NoteAttachmentResponse,
  NoteDocumentResponse,
  NoteFaxResponse,
  NotePatientResponse,
  NoteResponse,
  NoteTypeResponse,
} from './note-api.type';
import { NoteSaveData } from './note-update.type';
import {
  MLFeedback,
  MLNoteSuggestion,
  Note,
  NoteAttachment,
  NoteDocument,
  NoteFax,
  NotePatient,
  NoteType,
} from './note.type';

/* istanbul ignore next */
export const mapToNoteSaveRequest = (noteTypeId: number): NoteSaveData => ({
  note_type_id: noteTypeId,
});

/* istanbul ignore next */
const mapNotePatientResponseToEntity = (
  patient: NotePatientResponse,
): NotePatient => ({
  id: patient.id,
  firstName: patient.first_name,
  lastName: patient.last_name,
  nickname: patient.nickname,
  formerLastName: patient.former_last_name,
  dob: patient.dob,
  ageInYears: patient.age_in_years,
  middleName: patient.middle_name,
  suffix: patient.suffix,
  preferredName: patient.preferred_name,
  initials: patient.initials,
  gender: patient.gender,
  pcp: patient.pcp,
});

/* istanbul ignore next */
const mapNoteNoteTypeResponseToEntity = (
  noteType: NoteTypeResponse,
): NoteType => {
  const tags = noteType.tags || [];
  return {
    id: noteType.id,
    name: noteType.name,
    tags: [...tags],
    forDocuments: noteType.for_documents || null,
  };
};

/* istanbul ignore next */
const mapNoteFaxResponseToEntity = (fax: NoteFaxResponse): NoteFax => {
  return {
    attempts: fax.attempts,
    cover: fax.cover,
    createdAt: fax.created_at,
    id: fax.id,
    recipientPhoneNumber: fax.recipient_phone_number,
    sentAt: fax.sent_at,
    state: fax.state,
    updatedAt: fax.updated_at,
  };
};

/* istanbul ignore next */
export const mapNoteAttachmentResponseToEntity = (
  attachment: NoteAttachmentResponse,
): NoteAttachment => {
  return {
    downloadPath: attachment.download_path,
    id: attachment.id,
    lastModifiedAt: attachment.last_modified_at,
    name: attachment.name,
    size: attachment.size,
    type: attachment.type,
    url: attachment.url,
    viewingPath: attachment.viewing_path,
  };
};

/* istanbul ignore next */
export const mapNoteDocumentResponseToEntity = (
  document: NoteDocumentResponse,
): NoteDocument => {
  return {
    awsBucket: document.aws_bucket,
    awsKey: document.aws_key,
    createdAt: document.created_at,
    ext: document.ext,
    forLab: document.for_lab,
    id: document.id,
    mimeType: document.mime_type,
    originalFilename: document.original_filename,
    pageCount: document.page_count,
    pdfBucket: document.pdf_bucket,
    pdfKey: document.pdf_key,
    status: document.status,
    updatedAt: document.updated_at,
    parentId: document.parent_id,
  };
};

/* istanbul ignore next */
export const mapNoteResponseToEntity = (note: NoteResponse): Note => {
  const patient = note.patient || {};
  const noteType = note.note_type || {};
  const faxes = note.faxes || [];
  const attachments = note.attachments || [];
  const documents = note.documents || [];

  return {
    id: note.id,
    subject: note.subject,
    createdAt: note.created_at,
    signedAt: note.signed_at,
    attachments: attachments.map(mapNoteAttachmentResponseToEntity),
    patient: mapNotePatientResponseToEntity(patient),
    body: note.body,
    noteType: mapNoteNoteTypeResponseToEntity(noteType),
    mlNoteSuggestion: note.ml_note_suggestion
      ? mapMlNoteSuggestionToEntity(note.ml_note_suggestion)
      : null,
    author: note.author,
    createdBy: note.created_by,
    createdByWithSuffix: note.created_by_with_suffix,
    signedBy: note.signed_by,
    signedByWithSuffix: note.signed_by_with_suffix,
    documents: documents.map(mapNoteDocumentResponseToEntity),
    faxes: faxes.map(mapNoteFaxResponseToEntity),
    totalComments: note.total_comments,
    todoId: null,
    redactedAt: note.redacted_at,
    redactedBy: note.redacted_by,
  };
};

export function mapMlNoteSuggestionToEntity(
  dto: MLNoteSuggestionDTO,
): MLNoteSuggestion {
  return {
    id: dto.id,
    originalSubject: dto.original_subject,
    suggestedSpecialty: dto.suggested_speciality,
    reasons: dto.reasons,
    mlFeedback: dto.ml_feedback ? mapMlFeedbackToEntity(dto.ml_feedback) : null,
  };
}

function mapMlFeedbackToEntity(dto: MLFeedbackDTO): MLFeedback {
  return {
    id: dto.id,
    feedbackType: dto.feedback_type,
    feedbackCategory: dto.feedback_category,
    feedbackText: dto.feedback_text,
    metadata: dto.metadata,
  };
}

export const mapNoteSaveError = response =>
  response?.error?.errors ? Object.values(response.error.errors) : null;
