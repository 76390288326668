// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FsaAction } from '@app/core';
import { OrderWithIndications } from '@app/modules/orders/shared/order.type';

import { Problem, ProblemForm, ProblemHistory } from '../shared/problems.type';
import { ProblemState } from './problems.reducer';

export enum ProblemActionTypes {
  GET_PROBLEMS = '[Problems] Get Problems',
  GET_PROBLEMS_SUCCESS = '[Problems] Get Problems Success',
  GET_PROBLEMS_ERROR = '[Problems] Get Problems Error',
  SAVE_PROBLEM = '[Problems] Save Problem',
  SAVE_PROBLEM_AND_CREATE_PROCEDURE_INTERACTION = '[Problems] Save Problem And Create Procedure Interaction',
  SAVE_PROBLEM_AND_CREATE_PROCEDURE_ORDER = '[Problems] Save Problem And Create Procedure Order',
  SAVE_PROBLEM_SUCCESS = '[Problems] Save Problem Success',
  SAVE_PROBLEM_ERROR = '[Problems] Save Problem Error',
  UPDATE_PROBLEM = '[Problems] Update Problem',
  UPDATE_PROBLEM_SUCCESS = '[Problems] Update Problem Success',
  UPDATE_PROBLEM_ERROR = '[Problems] Update Problem Error',
  RESOLVE_PROBLEM = '[Problems] Resolve Problem',
  RESOLVE_PROBLEM_SUCCESS = '[Problems] Resolve Problem Success',
  RESOLVE_PROBLEM_ERROR = '[Problems] Resolve Problem Error',
  REACTIVATE_PROBLEM = '[Problems] Reactivate Problem',
  REACTIVATE_PROBLEM_SUCCESS = '[Problems] Reactivate Problem Success',
  REACTIVATE_PROBLEM_ERROR = '[Problems] Reactivate Problem Error',
  REJECT_PROBLEM = '[Problems] Reject Problem',
  REJECT_PROBLEM_SUCCESS = '[Problems] Reject Problem Success',
  REJECT_PROBLEM_ERROR = '[Problems] Reject Problem Error',
  DELETE_PROBLEM = '[Problems] Delete Problem',
  DELETE_PROBLEM_SUCCESS = '[Problems] Delete Problem Success',
  DELETE_PROBLEM_ERROR = '[Problems] Delete Problem Error',
  GET_PROBLEM_HISTORY = '[Problems] Get Problem History',
  GET_PROBLEM_HISTORY_SUCCESS = '[Problems] Get Problem History Success',
  GET_PROBLEM_HISTORY_ERROR = '[Problems] Get Problem History Error',
}

export interface GetProblemsParams {
  custom?: boolean;
  resolved?: boolean;
}

interface GetProblemsPayload {
  patientId: number;
  params: GetProblemsParams;
}

interface SaveProblemPayload {
  patientId: number;
  problem: ProblemForm;
  order?: Partial<OrderWithIndications>;
}

interface UpdateProblemPayload {
  patientId: number;
  problem: ProblemForm;
  isAutosave: boolean;
}

interface ResolveProblemData {
  patientId: number;
  problemId: number;
}

interface ReactivateProblemData {
  patientId: number;
  problemId: number;
}

interface RejectProblemPayload {
  patientId: number;
  problemId: number;
}

interface DeleteProblemPayload {
  patientId: number;
  problemId: number;
}

interface GetProblemHistoryPayload {
  patientId: number;
  problemId: number;
}

type ProblemErrorPayload = any;

export class GetProblems implements FsaAction<GetProblemsPayload> {
  readonly type = ProblemActionTypes.GET_PROBLEMS;

  constructor(public payload: GetProblemsPayload) { }
}

export class GetProblemsSuccess implements FsaAction<Problem[]> {
  readonly type = ProblemActionTypes.GET_PROBLEMS_SUCCESS;
  error = false;

  constructor(public payload: Problem[]) { }
}

export class GetProblemsError implements FsaAction<ProblemErrorPayload> {
  readonly type = ProblemActionTypes.GET_PROBLEMS_ERROR;
  error = true;

  constructor(public payload: ProblemErrorPayload) { }
}

export class SaveProblem implements FsaAction<SaveProblemPayload> {
  readonly type = ProblemActionTypes.SAVE_PROBLEM;

  constructor(public payload: SaveProblemPayload) { }
}

export class SaveProblemAndCreateProcedureInteraction
  implements FsaAction<SaveProblemPayload> {
  readonly type =
    ProblemActionTypes.SAVE_PROBLEM_AND_CREATE_PROCEDURE_INTERACTION;

  constructor(public payload: SaveProblemPayload) { }
}
export class SaveProblemAndCreateProcedureOrder
  implements FsaAction<SaveProblemPayload> {
  readonly type = ProblemActionTypes.SAVE_PROBLEM_AND_CREATE_PROCEDURE_ORDER;

  constructor(public payload: SaveProblemPayload) { }
}

export class SaveProblemSuccess implements FsaAction<Problem> {
  readonly type = ProblemActionTypes.SAVE_PROBLEM_SUCCESS;
  error = false;

  constructor(public payload: Problem) { }
}

export class SaveProblemError implements FsaAction<ProblemErrorPayload> {
  readonly type = ProblemActionTypes.SAVE_PROBLEM_ERROR;
  error = true;

  constructor(public payload: ProblemErrorPayload) { }
}

export class UpdateProblem implements FsaAction<UpdateProblemPayload> {
  readonly type = ProblemActionTypes.UPDATE_PROBLEM;

  constructor(public payload: UpdateProblemPayload) { }
}

export class UpdateProblemSuccess implements FsaAction<Problem> {
  readonly type = ProblemActionTypes.UPDATE_PROBLEM_SUCCESS;
  error = false;

  constructor(public payload: Problem) { }
}

export class UpdateProblemError implements FsaAction<ProblemErrorPayload> {
  readonly type = ProblemActionTypes.UPDATE_PROBLEM_ERROR;
  error = true;

  constructor(
    public payload: ProblemErrorPayload,
    public meta: { id: number },
  ) { }
}

export class ResolveProblem implements FsaAction<ResolveProblemData> {
  readonly type = ProblemActionTypes.RESOLVE_PROBLEM;

  constructor(public payload: ResolveProblemData) { }
}

export class ResolveProblemSuccess implements FsaAction<Problem> {
  readonly type = ProblemActionTypes.RESOLVE_PROBLEM_SUCCESS;
  error = false;

  constructor(public payload: Problem) { }
}

export class ResolveProblemError implements FsaAction<ProblemErrorPayload> {
  readonly type = ProblemActionTypes.RESOLVE_PROBLEM_ERROR;
  error = true;

  constructor(
    public payload: ProblemErrorPayload,
    public meta: { id: number },
  ) { }
}

export class ReactivateProblem implements FsaAction<ReactivateProblemData> {
  readonly type = ProblemActionTypes.REACTIVATE_PROBLEM;

  constructor(public payload: ReactivateProblemData) { }
}

export class ReactivateProblemSuccess implements FsaAction<Problem> {
  readonly type = ProblemActionTypes.REACTIVATE_PROBLEM_SUCCESS;
  error = false;

  constructor(public payload: Problem) { }
}

export class ReactivateProblemError implements FsaAction<ProblemErrorPayload> {
  readonly type = ProblemActionTypes.REACTIVATE_PROBLEM_ERROR;
  error = true;

  constructor(
    public payload: ProblemErrorPayload,
    public meta: { id: number },
  ) { }
}

export class DeleteProblem implements FsaAction<DeleteProblemPayload> {
  readonly type = ProblemActionTypes.DELETE_PROBLEM;

  constructor(public payload: DeleteProblemPayload) { }
}

export class DeleteProblemSuccess implements FsaAction<Problem> {
  readonly type = ProblemActionTypes.DELETE_PROBLEM_SUCCESS;
  error = false;

  constructor(public payload: Problem) { }
}

export class DeleteProblemError implements FsaAction<ProblemErrorPayload> {
  readonly type = ProblemActionTypes.DELETE_PROBLEM_ERROR;
  error = true;

  constructor(
    public payload: ProblemErrorPayload,
    public meta: { id: number },
  ) { }
}

export class GetProblemHistory implements FsaAction<GetProblemHistoryPayload> {
  readonly type = ProblemActionTypes.GET_PROBLEM_HISTORY;

  constructor(public payload: GetProblemHistoryPayload) { }
}

export class GetProblemHistorySuccess implements FsaAction<ProblemHistory> {
  readonly type = ProblemActionTypes.GET_PROBLEM_HISTORY_SUCCESS;

  constructor(public payload: ProblemHistory) { }
}

export class GetProblemHistoryError implements FsaAction<ProblemErrorPayload> {
  readonly type = ProblemActionTypes.GET_PROBLEM_HISTORY_ERROR;
  error = true;

  constructor(public payload: ProblemErrorPayload) { }
}

export class RejectProblem implements FsaAction<RejectProblemPayload> {
  readonly type = ProblemActionTypes.REJECT_PROBLEM;

  constructor(public payload: RejectProblemPayload) { }
}

export class RejectProblemError implements FsaAction<ProblemErrorPayload> {
  readonly type = ProblemActionTypes.REJECT_PROBLEM_ERROR;
  error = true;

  constructor(public payload: ProblemErrorPayload) { }
}

export class RejectProblemSuccess implements FsaAction<RejectProblemPayload> {
  readonly type = ProblemActionTypes.REJECT_PROBLEM_SUCCESS;
  error = false;

  constructor(public payload: RejectProblemPayload) { }
}

@Injectable()
export class ProblemActions {
  constructor(private store: Store<ProblemState>) { }

  getProblems(patientId: number, params?: GetProblemsParams) {
    this.store.dispatch(new GetProblems({ patientId, params }));
  }

  saveProblem(patientId: number, problem: ProblemForm) {
    this.store.dispatch(new SaveProblem({ patientId, problem }));
  }

  saveProblemAndCreateProcedureInteraction(
    patientId: number,
    problem: ProblemForm,
  ) {
    this.store.dispatch(
      new SaveProblemAndCreateProcedureInteraction({ patientId, problem }),
    );
  }

  saveProblemAndCreateProcedureOrder(
    patientId: number,
    problem: ProblemForm,
    order: Partial<OrderWithIndications>,
  ) {
    this.store.dispatch(
      new SaveProblemAndCreateProcedureOrder({ patientId, problem, order }),
    );
  }

  updateProblem(patientId: number, problem: ProblemForm, isAutosave = false) {
    this.store.dispatch(new UpdateProblem({ patientId, problem, isAutosave }));
  }

  deleteProblem(patientId: number, problemId: number) {
    this.store.dispatch(new DeleteProblem({ patientId, problemId }));
  }

  resolveProblem(patientId: number, problemId: number) {
    this.store.dispatch(new ResolveProblem({ patientId, problemId }));
  }

  reactivateProblem(patientId: number, problemId: number) {
    this.store.dispatch(new ReactivateProblem({ patientId, problemId }));
  }

  rejectProblem(patientId: number, problemId: number): void {
    this.store.dispatch(new RejectProblem({ patientId, problemId }));
  }

  getProblemHistory(patientId: number, problemId: number) {
    this.store.dispatch(new GetProblemHistory({ patientId, problemId }));
  }
}

export type ProblemAction =
  | GetProblems
  | GetProblemsSuccess
  | GetProblemsError
  | SaveProblem
  | SaveProblemAndCreateProcedureInteraction
  | SaveProblemAndCreateProcedureOrder
  | SaveProblemSuccess
  | SaveProblemError
  | UpdateProblem
  | UpdateProblemSuccess
  | UpdateProblemError
  | ResolveProblem
  | ResolveProblemSuccess
  | ResolveProblemError
  | ReactivateProblem
  | ReactivateProblemSuccess
  | ReactivateProblemError
  | DeleteProblem
  | DeleteProblemSuccess
  | DeleteProblemError
  | GetProblemHistory
  | GetProblemHistorySuccess
  | GetProblemHistoryError
  | RejectProblem
  | RejectProblemError
  | RejectProblemSuccess;
