import { Injectable } from '@angular/core';

import { formatDosage } from '@app/modules/shared-rx/utils';

import { MedicationRegimen, MedicationRegimenForm } from './medications.type';
import {
  PatientMedication,
  PatientMedicationStatus,
} from './patient-medications.type';

export const mapMedicationRegimenToForm = (
  data?: MedicationRegimen,
): MedicationRegimenForm => {
  const template = data?.medicationPrescriptionTemplate;
  const templateItem = template && template.medicationPrescriptionItemTemplate;
  const frequencyInterval =
    templateItem && templateItem.medicationFrequencyInterval;
  const prn = template && template.medicationPrn;

  return {
    medicationRouteId: data?.medicationRouteId ?? null,
    medicationRegimenId: data?.id ?? null,
    isCustomRegimen: data?.isCustom ?? false,
    dispensableId: templateItem?.medicationDispensableId ?? null,
    prescriptionTemplateId: template?.id ?? null,
    prescriptionTemplateItemId: templateItem?.id ?? null,
    dose: (template && (formatDosage(template) as string)) ?? null,
    durationDays: templateItem?.durationDays ?? null,
    frequencyIntervalId: frequencyInterval?.id ?? null,
    instructionsText: templateItem?.instructionsText ?? null,
    useInstructionsText: !!templateItem?.instructionsText,
    usePrn: !!prn,
    prnId: prn?.id ?? null,
    prnDescription: prn?.desc ?? null,
    regimenTextDescription: data?.description ?? null,
  };
};

@Injectable({
  providedIn: 'root',
})
export class MedicationUtility {
  static isMedActive(patientMedication: PatientMedication): boolean {
    return patientMedication.status === PatientMedicationStatus.ACTIVE;
  }
}
