/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type StopRecordingMutationVariables = Types.Exact<{
  input: Types.StopAppointmentRecordingInput;
}>;


export type StopRecordingMutation = { __typename?: 'Mutation', stopAppointmentRecording?: { __typename?: 'StopAppointmentRecordingPayload', success: boolean } | null };

export const StopRecordingDocument = gql`
    mutation StopRecording($input: StopAppointmentRecordingInput!) {
  stopAppointmentRecording(input: $input) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StopRecordingMutationService extends Mutation<StopRecordingMutation, StopRecordingMutationVariables> {
    document = StopRecordingDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }