/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type TranscribeRecordingMutationVariables = Types.Exact<{
  input: Types.TranscribeAppointmentRecordingInput;
}>;


export type TranscribeRecordingMutation = { __typename?: 'Mutation', transcribeAppointmentRecording?: { __typename?: 'TranscribeAppointmentRecordingPayload', success: boolean } | null };

export const TranscribeRecordingDocument = gql`
    mutation TranscribeRecording($input: TranscribeAppointmentRecordingInput!) {
  transcribeAppointmentRecording(input: $input) {
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TranscribeRecordingMutationService extends Mutation<TranscribeRecordingMutation, TranscribeRecordingMutationVariables> {
    document = TranscribeRecordingDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }