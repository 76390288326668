import { Injectable } from '@angular/core';
import {
  GetPresignedUploadUrlQuery,
  GetPresignedUploadUrlQueryService,
} from '../graphql/get-presigned-upload-url.onelife.generated';
import {
  StartRecordingMutation,
  StartRecordingMutationService,
} from '../graphql/start-recording.onelife.generated';
import {
  StopRecordingMutation,
  StopRecordingMutationService,
} from '../graphql/stop-recording.onelife.generated';
import {
  TranscribeRecordingMutation,
  TranscribeRecordingMutationService,
} from '../graphql/transcribe-recording.onelife.generated';
import { ApolloQueryResult } from '@apollo/client';
import { lastValueFrom } from 'rxjs';
import { CreateRecordingMutationService } from '../graphql/create-recording.onelife.generated';
import { MutationResult } from 'apollo-angular';

export interface ViewableFieldsType {
  chiefComplaint: boolean;
  subjective: boolean;
  hpi: boolean;
  ros: boolean;
  pmh: boolean;
  assessment: boolean;
  plan: boolean;
  physicalExam: boolean;
  assessmentAndPlan: boolean;
  afterVisitSummary: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HealthscribeRecordingUploaderService {
  constructor(
    private getPresignedUploadUrlService: GetPresignedUploadUrlQueryService,
    private startRecordingService: StartRecordingMutationService,
    private stopRecordingService: StopRecordingMutationService,
    private transcribeRecordingService: TranscribeRecordingMutationService,
    private createRecordingService: CreateRecordingMutationService,
  ) {}

  getNextPresignedUrl(
    appointmentRecordingId: string,
  ): Promise<ApolloQueryResult<GetPresignedUploadUrlQuery>> {
    return lastValueFrom(
      this.getPresignedUploadUrlService.fetch(
        {
          id: appointmentRecordingId,
        },
        {
          fetchPolicy: 'network-only',
        },
      ),
    );
  }

  async createRecording(appointmentId: string): Promise<string | undefined> {
    const result = await lastValueFrom(
      this.createRecordingService.mutate({
        input: {
          appointmentId: appointmentId,
        },
      }),
    );
    return result.data?.createAppointmentRecording?.appointmentRecording?.id;
  }

  startRecording(
    appointmentRecordingId: string,
  ): Promise<MutationResult<StartRecordingMutation>> {
    return lastValueFrom(
      this.startRecordingService.mutate({
        input: { appointmentRecordingId: appointmentRecordingId },
      }),
    );
  }
  stopRecording(
    appointmentRecordingId: string,
    viewableFields: ViewableFieldsType,
  ): Promise<MutationResult<StopRecordingMutation>> {
    return lastValueFrom(
      this.stopRecordingService.mutate({
        input: {
          appointmentRecordingId: appointmentRecordingId,
          defaultFields: JSON.stringify(viewableFields),
        },
      }),
    );
  }
  transcribeRecording(
    appointmentRecordingId: string,
  ): Promise<MutationResult<TranscribeRecordingMutation>> {
    return lastValueFrom(
      this.transcribeRecordingService.mutate({
        input: { appointmentRecordingId: appointmentRecordingId },
      }),
    );
  }

  async onChunk(appointmentRecordingId: string, blob: Blob): Promise<void> {
    const result = await this.getNextPresignedUrl(appointmentRecordingId);
    const url = result.data?.appointmentRecording?.presignedUploadUrl as string;
    const file = createFileFromBlob(blob);
    if (!url) {
      throw new Error('no url');
    }
    await fetch(url, {
      method: 'PUT',
      body: file,
    });
  }
}
function createFileFromBlob(blob: Blob): File {
  return new File([blob], `blob.webm`, { type: 'audio/webm' });
}
