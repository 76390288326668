/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RECORDING_STATE } from '../healthscribe-recorder.service';

export enum RENDER_MODE {
  INTERACTIVE = 'interactive',
  READONLY = 'readonly',
}

@Component({
  selector: 'omg-recording-control',
  templateUrl: './recording-control.component.html',
  styleUrls: ['./recording-control.component.scss'],
})
export class RecordingControlComponent {
  static readonly RENDER_MODE = RENDER_MODE;
  static readonly RECORDING_STATE = RECORDING_STATE;

  @Input() mode: RENDER_MODE;
  @Input() status: RECORDING_STATE;
  @Input() duration: number;

  @Output() pause = new EventEmitter<Event>();
  @Output() record = new EventEmitter<Event>();
  @Output() end = new EventEmitter<Event>();

  get isPaused(): boolean {
    return [
      RECORDING_STATE.PAUSED,
      RECORDING_STATE.NONE,
      RECORDING_STATE.ENDED,
      RECORDING_STATE.ERROR,
    ].includes(this.status);
  }
  get isRecording(): boolean {
    return [RECORDING_STATE.IN_PROGRESS, RECORDING_STATE.STARTING].includes(
      this.status,
    );
  }
}
