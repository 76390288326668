// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, patientRoute } from '@app/core';
import { ConfigService } from '@app/core/config';
import { QueryBuilder } from '@app/core/search/query-builder';
import { SearchService } from '@app/core/search/search.service';

import {
  mapFormToSaveMedicationRegimenPayload,
  mapMedicationAllergyResponseToEntities,
  mapMedicationDrugInteractionResponseToEntities,
  mapMedicationRegimenToEntity,
  mapMedicationRouteResponseToEntity,
  mapMedicationRouteSearchResult,
} from './medications-api-mappers';
import {
  MedicationAllergyResponse,
  MedicationDrugInteractionResponse,
  MedicationRegimenResponse,
  MedicationRouteResponse,
} from './medications-api.type';
import {
  MedicationRegimen,
  MedicationRegimenForm,
  MedicationRoute,
  MedicationRouteSummary,
} from './medications.type';

export const medicationRoutePath = (
  patientId: number,
  routeId: number,
): string => {
  const medicationRoutesPath = '/medication_routes';
  return `${patientRoute(patientId, medicationRoutesPath)}/${routeId}`;
};

export const medicationRegimensRoute = (subRoute: string = ''): string => {
  const medicationRegimensPath = '/v2/admin/medication_regimens';
  return `${medicationRegimensPath}${subRoute}`;
};

@Injectable({
  providedIn: 'root',
})
export class MedicationsApiService {
  constructor(
    private searchService: SearchService,
    private config: ConfigService,
    private api: ApiService,
  ) { }

  getRoute(patientId: number, routeId: number): Observable<MedicationRoute> {
    return this.api
      .get<MedicationRouteResponse>(medicationRoutePath(patientId, routeId))
      .pipe(map(mapMedicationRouteResponseToEntity));
  }

  search(text: string): Observable<MedicationRouteSummary[]> {
    const query = new QueryBuilder('function_score_v6').build(text, {
      size: '8',
      fields: ['tags^4', 'name^3', 'name.words^2', 'generic_synonyms'],
      sort: ['_score', { is_generic: { order: 'desc' } }, 'name.keyword'],
      index: [this.config.searchIndex('medication_routes')],
    });

    return this.searchService.search(query).pipe(
      map(response => {
        const hits = response.hits || {};
        const items = hits.hits || [];

        return items.map(hit =>
          mapMedicationRouteSearchResult(hit._source, text),
        );
      }),
    );
  }

  saveMedicationRegimen(
    data: MedicationRegimenForm,
    renewal?: boolean,
  ): Observable<MedicationRegimen> {
    return this.api
      .save<MedicationRegimenResponse>(
        medicationRegimensRoute(),
        mapFormToSaveMedicationRegimenPayload(data, !!renewal),
      )
      .pipe(map(mapMedicationRegimenToEntity));
  }

  checkAllergy(patientId: number, routeId: number) {
    {
      return this.api
        .get<MedicationAllergyResponse[]>(
          `${medicationRoutePath(patientId, routeId)}/check_allergy`,
        )
        .pipe(map(mapMedicationAllergyResponseToEntities));
    }
  }

  checkDrugInteraction(patientId: number, routeId: number) {
    return this.api
      .get<MedicationDrugInteractionResponse[]>(
        `${medicationRoutePath(patientId, routeId)}/check_drug_interaction`,
      )
      .pipe(map(mapMedicationDrugInteractionResponseToEntities));
  }
}
