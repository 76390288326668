import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'critical'
  | 'flat'
  | 'flat-icon'
  | 'link'
  | 'invisible'
  | 'critical-link'
  | 'tiny'
  | 'none';

/**
 * This is a component, but used as a directive. This allows for the usage of
 * scoped styles rather than global CSS. If there is a future need to share
 * button styles (as part of a design system for instance), then it may make
 * sense to convert this into a directive and use global CSS classes instead
 * (which could be shared across different applications).
 */
@Component({
  // eslint-disable-next-line  @angular-eslint/component-selector
  selector: 'button[omgButton]',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() variant: ButtonVariant = 'primary';

  @Input()
  @HostBinding('disabled')
  disabled = false;

  @HostBinding('class.om-button') buttonClass = true;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.render();
  }

  focus() {
    this.el.nativeElement.focus();
  }

  private render() {
    if (this.variant !== 'none') {
      this.renderer.addClass(this.el.nativeElement, `-${this.variant}`);
    }
  }
}
