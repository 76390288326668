import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { CollapseDirective } from '@app/shared';

export type ChevronVariant = 'left' | 'right';
export type MessageVariant = 'without-padding';

@Component({
  selector: 'omg-collapsible-header',
  templateUrl: './collapsible-header.component.html',
  styleUrls: ['./collapsible-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleHeaderComponent {
  @Input() chevron: ChevronVariant;
  @Input() disabled: boolean;
  @Input() expanded: boolean;
  @Input() messageVariant: MessageVariant;

  @Output() expandedChange = new EventEmitter<boolean>();
  @Output() analyticsExpandedChange = new EventEmitter<boolean>();

  @ViewChild('collapseRef', { static: true })
  private collapseDirective: CollapseDirective;

  constructor() {}

  expand(): void {
    this.collapseDirective.expand();
  }

  collapse(): void {
    this.collapseDirective.collapse();
  }

  get isExpanded(): boolean {
    return this.collapseDirective.expanded;
  }

  get chevronClass(): string {
    return `-${this.chevron}`;
  }
}
