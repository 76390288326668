// @ts-strict-ignore
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
  ApiService,
  patientRoute,
  PatientSelectors,
  SearchService,
} from '@app/core';
import { ConfigService } from '@app/core/config';
import { QueryBuilder } from '@app/core/search/query-builder';
import { mapTaskAssigneeSearchResultToEntity } from '@app/modules/todo/shared/todo-api-mappers';
import { TodoApiService } from '@app/modules/todo/shared/todo-api.service';
import { TodoActions } from '@app/modules/todo/store/todo.actions';
import { httpStatus } from '@app/utils';
import { cloneDeep, omit } from '@app/utils/shared/lodash-fp';

import { InternalUserAssignee } from './internal-user-preferences.type';
import {
  mapMessageResponseToEntity,
  mapMessageToSaveData,
  mapPostResponseToEntity,
  mapPostToSaveData,
} from './messaging-api-mappers';
import { Message, MessageResponse, Post, PostResponse } from './messaging.type';

const timelinePostEndpoint = (patientId: number): string =>
  patientRoute(patientId, '/patient_timeline/posts');

export const enum MessagingError {
  PostNotFound = 'Post Not Found',
  PostInvalid = 'Post Invalid',
  MessageNotFound = 'Message Not Found',
  MessageInvalid = 'Message Invalid',
}

export interface MessagingErrorResponse {
  kind: MessagingError;
  errors: any;
}

@Injectable()
export class MessagingService {
  constructor(
    private apiService: ApiService,
    private patientSelectors: PatientSelectors,
    private todoActions: TodoActions,
    private todoService: TodoApiService,
    private searchService: SearchService,
    private config: ConfigService,
  ) {}

  getPost(postId: number): Observable<Post> {
    return this.patientSelectors.patientId.pipe(
      switchMap(patientId =>
        this.apiService
          .get<PostResponse>(`${timelinePostEndpoint(patientId)}/${postId}`)
          .pipe(map(mapPostResponseToEntity)),
      ),
    );
  }

  createPost({
    topic = null,
    labId,
    noteId,
    content = '',
  }: {
    topic?: string;
    labId?: number;
    noteId?: number;
    content?: string;
  } = {}): Observable<Post> {
    const post = {
      content_type: 'text',
      draft: true,
      lab_data_id: labId,
      note_id: noteId,
      content_attributes: content ? { topic, html: content } : { topic },
    };

    return this.patientSelectors.patientId.pipe(
      switchMap(patientId =>
        this.apiService
          .save<PostResponse>(`${timelinePostEndpoint(patientId)}`, post)
          .pipe(map(mapPostResponseToEntity)),
      ),
    );
  }

  clonePost(post: Post): Observable<Post> {
    const newPost = <Post>omit(['id', 'contentAttributes.id'], cloneDeep(post));
    newPost.s3Pointers.forEach(pointer => {
      delete pointer.id;
    });
    return this.savePost(newPost);
  }

  private updatePostFailure(response: HttpErrorResponse): Observable<any> {
    const err: MessagingErrorResponse = {
      kind: undefined,
      errors: response.error && response.error.errors,
    };

    switch (response.status) {
      case httpStatus.NOT_FOUND:
        err.kind = MessagingError.PostNotFound;
        return throwError(err);
      case httpStatus.UNPROCESSABLE_ENTITY:
        err.kind = MessagingError.PostInvalid;
        return throwError(err);
      default:
        return throwError(response);
    }
  }

  updatePost(post: Post): Observable<Post> {
    return this.patientSelectors.patientId.pipe(
      switchMap(patientId =>
        this.apiService
          .update<PostResponse>(
            `${timelinePostEndpoint(patientId)}/${post.id}`,
            mapPostToSaveData(post),
          )
          .pipe(
            catchError(err => this.updatePostFailure(err)),
            map(mapPostResponseToEntity),
          ),
      ),
    );
  }

  deletePost(postId: number): Observable<any> {
    return this.patientSelectors.patientId.pipe(
      switchMap(patientId =>
        this.apiService.delete(`${timelinePostEndpoint(patientId)}/${postId}`),
      ),
    );
  }

  movePost(post: Post): Observable<any> {
    return this.patientSelectors.patientId.pipe(
      switchMap(patientId =>
        this.apiService.update(
          `${timelinePostEndpoint(patientId)}/${post.id}/move`,
          mapPostToSaveData(post),
        ),
      ),
    );
  }

  savePost(post: Post): Observable<Post> {
    return this.patientSelectors.patientId.pipe(
      switchMap(patientId =>
        this.apiService
          .save<PostResponse>(
            `${timelinePostEndpoint(patientId)}`,
            mapPostToSaveData(post),
          )
          .pipe(map(mapPostResponseToEntity)),
      ),
    );
  }

  getPostWithTodo(postId: number): Observable<Post> {
    return combineLatest([
      this.getPost(postId),
      this.todoService.getPatientTimelinePostTodo(postId),
    ]).pipe(
      tap(([post, todo]) => this.todoActions.loadTodoSuccess(todo)),
      map(([post, todo]) => ({ ...post, todoId: todo.id })),
    );
  }

  searchTaskAssignees(text: string): Observable<InternalUserAssignee[]> {
    const query = new QueryBuilder('multi_match_with_fields_v6_strategy').build(
      text,
      {
        size: '8',
        fields: ['name'],
        operator: 'and',
        sort: ['_score', 'name.keyword'],
        index: [this.config.searchIndex('task_assignees')],
      },
    );
    query.body.query.bool.should = [
      { term: { role: { value: 'Group', boost: 10 } } },
    ];

    return this.searchService
      .search(query)
      .pipe(map(this.mapTaskAssigneesResultToEntity));
  }

  deletePostMessage(message: Message | Post, postId: number): Observable<any> {
    return this.patientSelectors.patientId.pipe(
      switchMap(patientId =>
        this.apiService.delete(
          `${timelinePostEndpoint(patientId)}/${postId}/comments/${message.id}`,
        ),
      ),
    );
  }

  private updateMessageFailure(response: HttpErrorResponse): Observable<any> {
    const err: MessagingErrorResponse = {
      kind: undefined,
      errors: response.error && response.error.errors,
    };

    switch (response.status) {
      case httpStatus.NOT_FOUND:
        err.kind = MessagingError.MessageNotFound;
        return throwError(err);
      case httpStatus.UNPROCESSABLE_ENTITY:
        err.kind = MessagingError.MessageInvalid;
        return throwError(err);
      default:
        return throwError(response);
    }
  }

  updatePostMessage(message: Message, postId: number): Observable<Message> {
    return this.patientSelectors.patientId.pipe(
      switchMap(patientId =>
        this.apiService
          .update<MessageResponse>(
            `${timelinePostEndpoint(patientId)}/${postId}/comments/${
              message.id
            }`,
            mapMessageToSaveData(message),
          )
          .pipe(
            catchError(err => this.updateMessageFailure(err)),
            map(mapMessageResponseToEntity),
          ),
      ),
    );
  }

  createPostMessage(message: Message, postId: number): Observable<Message> {
    return this.patientSelectors.patientId.pipe(
      switchMap(patientId =>
        this.apiService
          .save<MessageResponse>(
            `${timelinePostEndpoint(patientId)}/${postId}/comments`,
            mapMessageToSaveData(message),
          )
          .pipe(map(mapMessageResponseToEntity)),
      ),
    );
  }

  private mapTaskAssigneesResultToEntity(response): InternalUserAssignee[] {
    const hits = response.hits || {};
    const items = hits.hits || [];
    return items.map(mapTaskAssigneeSearchResultToEntity);
  }
}
