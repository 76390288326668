<div *ngIf="documentSummarizationVisible"
     class="document-summary">

  <ng-container *ngrxLet="summary$ as summary; suspenseTpl: loading">
    <omg-collapse [expanded]=true
                  [disabled]="!summary.content.detailedSummary"
                  (analyticsExpandedChange)="onViewerCollapseToggle($event)">
      <omg-collapse-toggle>
        <div om-layout
             om-layout-align="start center">
          <ng-container *ngTemplateOutlet="heading"></ng-container>
          <span *ngIf="summary.content.detailedSummary">
            <omg-collapsed disableAnimation="true">
              <i class="fa fa-chevron-down"></i>
            </omg-collapsed>
            <omg-expanded disableAnimation="true">
              <i class="fa fa-chevron-up"></i>
            </omg-expanded>
          </span>
        </div>
      </omg-collapse-toggle>

      <omg-expanded>
        <div *ngIf="summary.content.detailedSummary; else unavailable">
          <div class="document-summary-text"
               [omgTrack]="{ event: 'copy', action: 'Document Summary Copied' }">
            {{summary.content.detailedSummary}}
          </div>
          <omg-document-summary-timestamp [summary]="summary" />
        </div>
      </omg-expanded>

      <omg-collapsed>
        <div *ngIf="summary.content.detailedSummary; else unavailable"
             class="document-summary-text-collapsed"
             [omgTrack]="{ event: 'copy', action: 'Document Summary Copied' }">
          {{summary.content.detailedSummary}}
        </div>
      </omg-collapsed>
    </omg-collapse>

    <div *ngIf="documentSummaryFeedbackVisible && summary.content.detailedSummary">
      <omg-document-summary-feedback [summaryId]="summary.id"
                                     (feedbackSubmitted)="onFeedbackSubmitted($event)" />
    </div>
  </ng-container>

  <ng-template #heading>
    <i class="title-wand om-icon icon-magic"></i>
    <span class="document-summary-title">
      AI Document Summary
    </span>
  </ng-template>

  <ng-template #loading>
    <div om-layout
         om-layout-align="start center">
      <ng-container *ngTemplateOutlet="heading"></ng-container>
    </div>
    <omg-document-summary-loading />
  </ng-template>

  <ng-template #unavailable>
    <omg-document-summary-unavailable />
  </ng-template>

</div>
