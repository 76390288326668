// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { selectProfileId } from '@app/core/profile/store/profile.selectors';
import {
  findAPharmacyOption,
  PatientPharmacy,
  sendToPrinterOption,
} from '@app/modules/pharmacy-picker/shared/patient-pharmacy.type';
import {
  selectAllPatientPharmacies,
  selectPatientPharmacyOptions,
} from '@app/modules/pharmacy-picker/store/patient-pharmacy.selectors';
import { PrescriberCredentials } from '@app/modules/shared-rx/prescriber-credential.type';
import {
  containsControlledSubstances as cartContainsControlledRx,
  sortValidPrescribers,
} from '@app/modules/shared-rx/utils';
import { CorrelationId, head } from '@app/utils';

import * as utils from '../shared/rx-cart-utils';
import { selectAllCartItems } from './pending-new-rx.selectors';
import { selectPrescriberCredentials } from './prescriber-credentials.selectors';
import { selectRxCartState } from './rx-cart-store-shared';
import {
  metadataAdapter,
  NewRxCartState,
  RxCartState,
} from './rx-cart.reducer';
import { CartStatus, PendingNewRx, RxCart, RxCartInfo } from '..';

export const selectCartState = createSelector(
  selectRxCartState,
  (state: NewRxCartState) => state.rxCart,
);

export const selectCart = createSelector(
  selectCartState,
  (state: RxCartState) => state.entity,
);

export const selectCartWithItems = createSelector(
  selectAllCartItems,
  selectCart,
  (cartItems: PendingNewRx[], cart: RxCart): RxCart => ({
    ...cart,
    cartItems,
  }),
);

const selectTotalCartItems = createSelector(
  selectCart,
  (state: RxCart) => (state && state.cartItems && state.cartItems.length) || 0,
);

const selectCartId = createSelector(
  selectCartWithItems,
  (cart: RxCart) => cart.id,
);

const selectCartPharmacyId = createSelector(
  selectCart,
  (cart: RxCart) => cart && cart.pharmacyId,
);

export const selectSignedOnBehalfOfId = createSelector(
  selectCart,
  cart => cart && cart.signedOnBehalfOfId,
);

export const selectSelectedPrescribingCredentialId = createSelector(
  selectCart,
  cart => cart && cart.selectedPrescribingCredentialId,
);

const selectSignedOnBehalfOfDetails = createSelector(
  selectPrescriberCredentials,
  selectCart,
  (
    prescriberCredentials: PrescriberCredentials,
    cart: RxCart,
    { credentialId },
  ) =>
    utils.buildSignedOnBehalfOfDetails(
      cart,
      prescriberCredentials,
      credentialId,
    ),
);

export const selectCartWithPatientPharmacy = createSelector(
  selectCart,
  selectPatientPharmacyOptions,
  (cart: RxCart, patientPharmacies: PatientPharmacy[]): RxCart => ({
    ...cart,
    patientPharmacy:
      (patientPharmacies || []).find(
        p => p.pharmacy && p.pharmacy.id === ((cart && cart.pharmacyId) || -1),
      ) || null,
  }),
);

export const selectIsComplete = createSelector(
  selectCart,
  (cart: RxCart) => cart && !!cart.cartCompleteAt,
);

const selectIsEmpty = createSelector(
  selectCartWithItems,
  (cart: RxCart) => cart.cartItems.length === 0,
);

export const selectCartInfo = createSelector(
  selectCartWithItems,
  selectCartWithPatientPharmacy,
  selectIsComplete,
  selectIsEmpty,
  (
    cart: RxCart,
    cartWithPharmacy: RxCart,
    isComplete: boolean,
    isEmpty: boolean,
  ): RxCartInfo => ({
    ...cart,
    patientPharmacy: cartWithPharmacy && cartWithPharmacy.patientPharmacy,
    isComplete,
    isEmpty,
  }),
);

export const selectInCart = createSelector(
  selectCartWithItems,
  (cart: RxCart, { id }) =>
    cart.cartItems.findIndex(item => item.medicationRegimenId === id) > -1,
);

export const selectIsLoading = createSelector(
  selectCartState,
  (state: RxCartState) => state.loading,
);

export const selectErrors = createSelector(
  selectCartState,
  (state: RxCartState) => state.error,
);

export const selectContainsControlledSubstances = createSelector(
  selectCartWithItems,
  (cart: RxCart) => cartContainsControlledRx(cart.cartItems),
);

const selectRequireTwoFactorCheckout = createSelector(
  selectCartWithItems,
  (cart: RxCart) => utils.requireTwoFactorCheckout(cart.cartItems),
);

const selectAllHandwrittenRequestsConfirmed = createSelector(
  selectCartWithItems,
  (cart: RxCart) => utils.allHandwrittenRequestsConfirmed(cart.cartItems),
);

const selectValidPrescriber = createSelector(
  selectCartWithItems,
  (cart: RxCart, { id }) =>
    utils.findValidPrescriber(cart.validPrescribers, id),
);

export const selectHandWrittenPrescriptions = createSelector(
  selectCartWithItems,
  (cart: RxCart) => utils.getHandWrittenPrescriptions(cart.cartItems),
);

export const selectTransmittedPrescriptions = createSelector(
  selectCartWithItems,
  (cart: RxCart) => utils.getTransmittedPrescriptions(cart.cartItems),
);

const selectIsPrescribedBySelf = createSelector(
  selectCartWithItems,
  selectProfileId,
  (cart: RxCart, profileId: number) =>
    !!cart.signedOnBehalfOfId && cart.signedOnBehalfOfId === profileId,
);

const selectCheckoutMessages = createSelector(selectCartWithItems, () =>
  utils.buildCheckoutMessages(),
);

const selectIsCartComplete = createSelector(
  selectCartWithItems,
  (cart: RxCart) => utils.isComplete(cart),
);

const selectIsCartEmpty = createSelector(
  selectCartWithItems,
  (cart: RxCart) => utils.isEmpty(cart),
);

export const selectIsPrint = createSelector(
  selectCart,
  (cart: RxCart) => cart && cart.pharmacyId === sendToPrinterOption.id,
);

const selectIsFind = createSelector(
  selectCart,
  (cart: RxCart) => cart && cart.pharmacyId === findAPharmacyOption.id,
);

const selectHasPharmacy = createSelector(
  selectCart,
  (cart: RxCart) => cart && !!cart.pharmacyId,
);

export const selectIsValidPharmacy = createSelector(
  selectIsPrint,
  selectIsFind,
  selectHasPharmacy,
  (isPrint: boolean, isFind: boolean, hasPharmacy: boolean) =>
    !isPrint && !isFind && hasPharmacy,
);

export const selectIsEpcsPharmacy = createSelector(
  selectCart,
  selectAllPatientPharmacies,
  (cart: RxCart, patientPharmacies: PatientPharmacy[]) => {
    const pharmacyId = cart && cart.pharmacyId;
    const currentPharmacy = head(
      (patientPharmacies || []).filter(
        patientPharmacy =>
          patientPharmacy.pharmacy &&
          patientPharmacy.pharmacy.id === pharmacyId,
      ),
    );

    return !!(
      currentPharmacy &&
      currentPharmacy.pharmacy &&
      currentPharmacy.pharmacy.isSurescripts &&
      currentPharmacy.pharmacy.services &&
      currentPharmacy.pharmacy.services.controlledSubstance
    );
  },
);

const selectIsNonEpcsPharmacy = createSelector(
  selectIsEpcsPharmacy,
  selectIsValidPharmacy,
  (isEpcsPharmacy: boolean, isValid: boolean) => !isEpcsPharmacy && isValid,
);

export const selectIsCheckoutDisabled = createSelector(
  selectCartWithItems,
  selectContainsControlledSubstances,
  selectIsPrint,
  selectIsFind,
  selectHasPharmacy,
  (
    cart: RxCart,
    containsControlledSubstances: boolean,
    isPrint: boolean,
    isFind: boolean,
    hasPharmacy: boolean,
  ) =>
    utils.isCheckoutDisabled(
      cart,
      containsControlledSubstances,
      isPrint,
      isFind,
      hasPharmacy,
    ),
);

export const selectShowNonEpcsPharmacyError = createSelector(
  selectIsNonEpcsPharmacy,
  selectContainsControlledSubstances,
  (isNonEpcsPharmacy: boolean, hasControlledSubstance: boolean) =>
    isNonEpcsPharmacy && hasControlledSubstance,
);

export const selectSortedValidPrescribers = createSelector(
  selectCart,
  (cart: RxCart) => sortValidPrescribers(cart && cart.validPrescribers),
);

const selectCartStatusParts = createSelector(
  selectIsPrescribedBySelf,
  selectIsCheckoutDisabled,
  selectIsPrint,
  selectIsCartComplete,
  selectAllHandwrittenRequestsConfirmed,
  selectIsCartEmpty,
  selectRequireTwoFactorCheckout,
  (
    isPrescribedBySelf: boolean,
    isCheckoutDisabled: boolean,
    isPrint: boolean,
    isCartComplete: boolean,
    allHandwrittenItemsConfirmed: boolean,
    isCartEmpty: boolean,
    requireTwoFactorCheckout: boolean,
  ) => ({
    isPrescribedBySelf,
    isCheckoutDisabled,
    isPrint,
    isCartComplete,
    allHandwrittenItemsConfirmed,
    isCartEmpty,
    requireTwoFactorCheckout,
  }),
);

const selectCartStatus = createSelector(
  selectCartStatusParts,
  selectCheckoutMessages,
  (cartStatusParts, checkoutMessages) => ({
    ...cartStatusParts,
    checkoutMessages,
  }),
);

const metadataSelectors = metadataAdapter.getSelectors(selectCartState);

/* istanbul ignore next */
@Injectable({
  providedIn: 'root',
})
export class RxCartSelectors {
  constructor(private store: Store<RxCartState>) {}

  get cart() {
    return this.store.pipe(select(selectCart));
  }

  get fullCart() {
    return this.store.pipe(select(selectCartInfo));
  }

  get cartWithItems() {
    return this.store.pipe(select(selectCartWithItems));
  }

  get cartId() {
    return this.store.pipe(select(selectCartId));
  }

  get signedOnBehalfOfId() {
    return this.store.pipe(select(selectSignedOnBehalfOfId));
  }

  get selectedPrescribingCredentialId() {
    return this.store.pipe(select(selectSelectedPrescribingCredentialId));
  }

  cartSignedOnBehalfDetails(credentialId: number) {
    return this.store.pipe(
      select(selectSignedOnBehalfOfDetails, { credentialId }),
    );
  }

  get cartWithPatientPharmacy() {
    return this.store.pipe(select(selectCartWithPatientPharmacy));
  }

  get isComplete() {
    return this.store.pipe(select(selectIsComplete));
  }

  get isEmpty() {
    return this.store.pipe(select(selectIsCartEmpty));
  }

  inCart(regimenId: number) {
    return this.store.pipe(select(selectInCart, { id: regimenId }));
  }

  get isLoading() {
    return this.store.pipe(select(selectIsLoading));
  }

  get errors() {
    return this.store.pipe(select(selectErrors));
  }

  get hasControlledSubstances() {
    return this.store.pipe(select(selectContainsControlledSubstances));
  }

  get requireTwoFactorCheckout() {
    return this.store.pipe(select(selectRequireTwoFactorCheckout));
  }

  get allHandwrittenRequestsConfirmed() {
    return this.store.pipe(select(selectAllHandwrittenRequestsConfirmed));
  }

  validPrescriber(id: number) {
    return this.store.pipe(select(selectValidPrescriber, { id }));
  }

  get handWrittenPrescriptions() {
    return this.store.pipe(select(selectHandWrittenPrescriptions));
  }

  get transmittedPrescriptions() {
    return this.store.pipe(select(selectTransmittedPrescriptions));
  }

  get isPrescribedBySelf() {
    return this.store.pipe(select(selectIsPrescribedBySelf));
  }

  showNonEpcsPharmacyError() {
    return this.store.pipe(select(selectShowNonEpcsPharmacyError));
  }

  get cartStatus(): Observable<CartStatus> {
    return this.store.pipe(select(selectCartStatus));
  }

  get cartItemCount() {
    return this.store.pipe(select(selectTotalCartItems));
  }

  get cartPharmacyId() {
    return this.store.pipe(select(selectCartPharmacyId));
  }

  pendingOperation(key: CorrelationId) {
    return this.store.pipe(select(metadataSelectors.selectItemByKey, { key }));
  }
}
