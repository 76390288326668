import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import {
  EntityMetadataMap,
  getEntityMetadataInitialState,
  resetEntityMetadata,
  updateEntityMetadata,
} from '@app/utils/store';

import {
  MedicationAllergy,
  MedicationDrugInteraction,
  MedicationRoute,
} from '../shared/medications.type';
import { MedicationAction, MedicationActionTypes } from './medication.actions';

export interface MedicationState extends EntityState<MedicationRoute> {
  loading: boolean;
  error: any;
  metadata: EntityMetadataMap<MedicationRoute>;
  drugAllergyInteractions: MedicationAllergy[];
  drugAllergyInteractionsLoading: boolean;
  drugDrugInteractions: MedicationDrugInteraction[];
  drugDrugInteractionsLoading: boolean;
}


const adapter: EntityAdapter<MedicationRoute> =
  createEntityAdapter<MedicationRoute>();

export const initialState: MedicationState = adapter.getInitialState({
  loading: false,
  error: null,
  drugAllergyInteractions: [],
  drugAllergyInteractionsLoading: false,
  drugDrugInteractions: [],
  drugDrugInteractionsLoading: false,
  ...getEntityMetadataInitialState({}),
});

export function reducer(
  state = initialState,
  action: MedicationAction,
): MedicationState {
  switch (action.type) {
    case MedicationActionTypes.LoadMedication: {
      return {
        ...state,
        loading: true,
        drugAllergyInteractions: [],
        drugAllergyInteractionsLoading: false,
        drugDrugInteractions: [],
        drugDrugInteractionsLoading: false,
        ...updateEntityMetadata(action.payload, { pending: true }, state),
      };
    }

    case MedicationActionTypes.LoadMedicationSuccess: {
      return {
        ...adapter.upsertOne(action.payload, state),
        loading: false,
        error: null,
        ...resetEntityMetadata(action.payload.id, state),
      };
    }

    case MedicationActionTypes.LoadMedicationError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case MedicationActionTypes.LoadDrugAllergyInteractions: {
      return {
        ...state,
        drugAllergyInteractionsLoading: true,
      };
    }

    case MedicationActionTypes.LoadDrugAllergyInteractionsSuccess: {
      return {
        ...state,
        drugAllergyInteractions: action.payload,
        drugAllergyInteractionsLoading: false,
        error: null,
      };
    }

    case MedicationActionTypes.LoadDrugDrugInteractions: {
      return {
        ...state,
        drugDrugInteractionsLoading: true,
      };
    }

    case MedicationActionTypes.LoadDrugDrugInteractionsSuccess: {
      return {
        ...state,
        drugDrugInteractions: action.payload,
        drugDrugInteractionsLoading: false,
        error: null,
      };
    }

    case MedicationActionTypes.ClearMedications: {
      return {
        ...adapter.removeAll(state),
        loading: false,
        error: null,
      };
    }

    default: {
      return state;
    }
  }
}

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

// select the array of ids
export const selectMedicationIds = selectIds;

// select the dictionary of entities
export const selectMedicationEntities = selectEntities;

// select the array of items
export const selectAllMedications = selectAll;

// select the total count
export const selectMedicationTotal = selectTotal;
