import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HotkeyModule } from 'angular2-hotkeys';
import { MarkdownModule, MARKED_OPTIONS } from 'ngx-markdown';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { CoreModule } from '@app/core/core.module';
import { AppShellModule } from '@app/features/app-shell/app-shell.module';
import { SharedModule } from '@app/shared';
import { ToastModule } from '@app/shared/components/toast/toast.module';

import { AppPreloader } from './app-preloader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DetailedErrorService } from './core';
import { LaunchDarklyService } from './core/launch-darkly/launchdarkly.service';
import { ParameterizedRouteReuseStrategy } from './core/routing/parameterized-route-reuse.strategy';
import { ThemeService } from './core/theme/theme.service';
import { GraphQLModule } from './graphql.module';
import { CommandPaletteModule } from './modules/command-palette/command-palette.module';
import { CommandPaletteService } from './modules/command-palette/command-palette.service';
import { PendoModule } from './modules/pendo/pendo.module';
import { ZendeskService } from './modules/zendesk/zendesk.service';
import { FocusService } from './shared/directives/focus/focus.service';
import { markedOptionsFactory } from './utils/shared/markdown';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    ToastModule.forRoot(),
    HotkeyModule.forRoot(),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory,
      },
    }),
    AppShellModule,
    AppRoutingModule,
    PendoModule,
    GraphQLModule,
    CommandPaletteModule.forRoot(),
    HttpClientModule,
  ],
  declarations: [AppComponent],
  exports: [SharedModule],
  providers: [
    AppPreloader,
    ThemeService,
    FocusService,
    LaunchDarklyService,
    ZendeskService,
    DetailedErrorService,
    {
      provide: RouteReuseStrategy,
      useClass: ParameterizedRouteReuseStrategy,
    },
    AnalyticsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    launchDarklyService: LaunchDarklyService,
    private commandPaletteService: CommandPaletteService,
  ) {
    launchDarklyService.init();
    this.commandPaletteService.init();
  }
}
