import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { StateSelector } from '@app/core/store/shared/state';
import {
  VisitProcedure,
  VisitProcedureError,
} from '@app/features/service-ticket/shared/visit-procedure.type';
import { VisitProcedureState } from '@app/features/service-ticket/store/visit-procedure.reducer';

export class VisitProcedureSelectors extends StateSelector<VisitProcedure> {
  protected selectState = createFeatureSelector<VisitProcedureState>(
    this.stateConfig.statePath,
  );

  protected selectSigningError = createSelector(
    this.selectState,
    state => state.signingError,
  );

  get signingError(): Observable<VisitProcedureError> {
    return this.store.pipe(select(this.selectSigningError));
  }
}
