import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { CancelPrescriber, Prescriber } from '../../prescriber-credential.type';

@Component({
  selector: 'omg-signed-on-behalf-of',
  templateUrl: './signed-on-behalf-of.component.html',
  styleUrls: ['./signed-on-behalf-of.component.css'],
})
export class SignedOnBehalfOfComponent {
  @Input() signedOnBehalfOfId: UntypedFormControl;
  @Input() validPrescribers: Prescriber[] | CancelPrescriber[];

  constructor() { }
}
