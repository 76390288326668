import {
  ChangeDetectionStrategy,
  Component,
  Host,
  HostBinding,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
} from '@angular/core';

import { buildExpansionAnimation } from '../../animations';
import { CollapseProviderDirective } from './collapse-provider.directive';

@Component({
  selector: 'omg-collapsed, [omgCollapsed]',
  template: `
    <div
      *ngIf="!(collapseProvider?.expandedChange | ngrxPush)"
      [@.disabled]="disableAnimation"
      [@collapse]="!collapseProvider?.expanded"
    >
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: [],
  animations: [buildExpansionAnimation('collapse')],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsedComponent implements OnChanges {
  @Input() collapseProvider: CollapseProviderDirective;
  @Input() disableAnimation = false;

  @HostBinding('class.om-collapsed') collapsedClass = true;

  constructor(
    @Optional()
    @Host()
    public collapseProviderDirective: CollapseProviderDirective,
  ) {
    this.setProvider();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { collapseProvider } = changes;
    if (collapseProvider) {
      this.setProvider();
    }
  }

  private setProvider() {
    this.collapseProvider =
      this.collapseProvider || this.collapseProviderDirective;
  }
}
