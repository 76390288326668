<div>
  <div *ngIf="variant === 'instructional'"
       om-layout
       om-layout-align="space-between center"
       class="instructional banner-container">
    <div>Record and summarize visit</div>
    <div om-layout
         om-layout-align="end center">
      <button omgButton
              type="button"
              variant="primary"
              data-testid="start-recording-button"
              (click)="startRecording.emit($event)">
        Start Recording
      </button>
      <omg-icon-button icon="close"
                       library="om-icon"
                       (keypress)="dismiss.emit($event)"
                       (click)="dismiss.emit($event)"
                       data-testid="dismiss-button"></omg-icon-button>
    </div>
  </div>
  <div *ngIf="variant === 'success'"
       om-layout
       om-layout-align="space-between center"
       class="success banner-container">
    <div>
      <i class="om-icon icon-magic"></i>
      <strong> Summary Ready</strong>
      <i> Expires in 7 Days</i>
    </div>
    <button omgButton
            type="button"
            variant="flat"
            data-testid="view-summary-button"
            (click)="viewSummary.emit($event)">
      View Summary
    </button>
  </div>
</div>
