import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { filter, map, Observable, shareReplay, switchMap } from 'rxjs';

import { CartDetailsProvider } from '@app/modules/shared-rx/services/cart-details-provider.interface';
import { CartState } from '@app/modules/shared-rx/services/pdmp.service';

import { ChangeRx } from '../shared/change-rx.type';
import { ChangeRxSelectors } from './change-rx.selectors';

/**
 * Service which has business logic for the current ChangeRx.
 */
@Injectable()
export class ChangeRxService implements CartDetailsProvider {
  constructor(
    private changeRxSelectors: ChangeRxSelectors,
    private route: ActivatedRoute,
  ) { }

  get changeRx$(): Observable<ChangeRx> {
    return this.route.paramMap.pipe(
      filter((params: ParamMap) => !!params.get('id')),
      map((params: ParamMap) => +params.get('id')!),
      switchMap((id: number) => this.changeRxSelectors.getById(id)),
      shareReplay(1), // since this will be subscribed multiple times
    );
  }

  get rxCartState$(): Observable<CartState> {
    return this.changeRx$.pipe(
      filter(changeRx => !!changeRx && !!changeRx.rxChangeRequest),
      map(changeRx => ({
        cartId: changeRx!.rxChangeRequest!.rxCart.id,
        cartItems: [changeRx!.rxChangeRequest!],
      })),
    );
  }
}
