import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';

import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import { SummariesSelectors } from '@app/features/summaries/store/summaries.selectors';
import { TodoActions } from '@app/modules/todo/store/todo.actions';
import { TodoSelectors } from '@app/modules/todo/store/todo.selectors';
import {
  StateValidator,
  StateValidatorOptions,
} from '@app/shared/services/state-validator.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SummariesGuard {
  constructor(
    private summariesActions: SummariesActions,
    private summariesSelectors: SummariesSelectors,
    private todoActions: TodoActions,
    private todoSelectors: TodoSelectors,
    private stateValidator: StateValidator,
  ) {}

  todoStateLoadError = {
    errorState: this.todoSelectors.error,
  };

  summaryStateLoadError = {
    errorState: this.summariesSelectors.error,
  };

  loadSummaryAndTodo(patientId: number, summaryId: number) {
    this.summariesActions.loadSummary({ patientId, summaryId });
    this.todoActions.loadTodoBySummaryId(summaryId);
  }

  getStateValidatorOptions(
    patientId: number,
    summaryId: number,
  ): StateValidatorOptions {
    const summaryById = this.summariesSelectors
      .getSummaryById(summaryId)
      .pipe(filter(summary => !!(summary && summary.id)));

    const summaryStateLoading = this.summariesSelectors.loading.pipe(
      filter(loading => !loading),
    );

    const todoStateLoading = this.todoSelectors.loading.pipe(
      filter(loading => !loading),
    );

    return {
      loadFn: () => this.loadSummaryAndTodo(patientId, summaryId),
      selectors: [summaryById, todoStateLoading, summaryStateLoading],
      possibleErrorStates: [
        this.summaryStateLoadError,
        this.todoStateLoadError,
      ],
      defaultErrorMessage: {
        summary: 'Load Summary Error',
        detail: 'Unfortunately the note you requested no longer exists.',
      },
    };
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const summaryIdFromRoute = route.paramMap.get('id');
    if (summaryIdFromRoute) {
      const summaryId = parseInt(summaryIdFromRoute, 10);
      const patientId = this.stateValidator.getPatientId(route);
      const options = this.getStateValidatorOptions(patientId, summaryId);
      return this.stateValidator.canActivate(
        options,
        `/patients/${patientId}/chart`,
      );
    }
    return of(false);
  }
}
