<div om-layout
     om-layout-fill
     om-layout-align="space-around center"
     class="container"
     [ngClass]="{ 'interactive': mode === 'interactive', 'readonly': mode === 'readonly'}">
  <div *ngIf="mode === 'interactive'">
    <omg-icon-button (click)="record.emit()"
                     *ngIf="isPaused"
                     icon="play"
                     library="fa" />
    <omg-icon-button (click)="pause.emit()"
                     *ngIf="isRecording"
                     icon="pause"
                     library="fa" />
  </div>
  <div class="text">
    <span *ngIf="isPaused"><span class="recording-icon om-icon icon-recording -gray"></span>Paused</span>
    <span *ngIf="isRecording"><span class="recording-icon om-icon icon-recording -red"></span>Recording</span>
    <span *ngIf="duration"
          class="duration">
      {{ duration | date:'mm:ss':'UTC'}}
    </span>
  </div>
  <button class="end-button"
          omgButton
          (click)="end.emit()"
          *ngIf="mode === 'interactive'"
          variant="flat">
    End and Summarize
  </button>
</div>
