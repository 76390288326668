import * as _ from 'lodash';

// @ts-strict-ignore
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Observable, Subject, take, takeUntil } from 'rxjs';

import { ProblemAttachmentsService } from '@app/modules/problems/shared/problem-attachments.service';
import { Problem } from '@app/modules/problems/shared/problems.type';
import { ProblemSelectors } from '@app/modules/problems/store/problems.selectors';

import {
  Problem as GraphQLProblem,
  ProblemAttachmentConnection,
  ProblemAttachmentAttachmentEnum as ProblemAttachmentType,
} from '../../../../../graphql/onelife.type';
import { getProblemString } from '@app/modules/problems/shared/problems-utils';

export type AutoCompletableProblem = ProblemLike & {
  label: string;
};
/**
 * ProblemLike exists to reconcile the difference between legacy REST/ngrx Problems and GraphQL problems.
 * We really only need two properties in most cases - id and clinical description - these properties are available on both
 * For further operations, we need access to the edges on a problem, which are only available through graphql
 * Operations that cause us to add more Attachments cause the GraphQL representation to become available, so
 * edge properties are available for subsequent operations
 *
 * Sample workflow:
 * Attachment has no problems associated -> Populates dropdown via Problem selectors -> User adds problem/attachment association
 * -> GraphQL fetches its representation -> We can now use the edge information to delete the association if desired
 */
export type ProblemLike = (GraphQLProblem | Problem) & {
  attachments?: ProblemAttachmentConnection;
};

const autocompleteFriendlyProblemMapper = (
  problem: ProblemLike,
): AutoCompletableProblem => {
  return {
    ...problem,
    label: problem.problemType.clinicalDescription,
  };
};

const memoizedMapperFunc = _.memoize((problems: ProblemLike[]) => {
  return problems.map(autocompleteFriendlyProblemMapper);
});

@Component({
  selector: 'omg-problem-selector',
  templateUrl: './problem-selector.component.html',
  styleUrls: ['./problem-selector.component.scss'],
})
export class ProblemSelectorComponent implements OnInit, OnDestroy {
  /**
   * documentId: the ID of the document in question. Used to fetch all associations.
   */
  @Input() documentId: number;
  /**
   * selectionChanged: EventEmitter that passes along the current state of selected Problems
   * This EventEmitter should be used by the parent component to perform state updates
   */
  @Output() selectionChanged: EventEmitter<ProblemLike[]> = new EventEmitter<
    ProblemLike[]
  >();

  /**
   * trackSelectionRemoved: This EventEmitter is informational only and is triggered when a Problem is unselected
   */
  @Output() trackSelectionRemoved: EventEmitter<void> =
    new EventEmitter<void>();
  /**
   * trackSelectionAdded: This EventEmitter is informational only and is triggered when a Problem is selected
   */
  @Output() trackSelectionAdded: EventEmitter<void> = new EventEmitter<void>();
  /**
   * trackTypeaheadOpened: This EventEmitter is informational only and is triggered when the typeahead is opened.
   */
  @Output() trackTypeaheadOpened: EventEmitter<void> = new EventEmitter<void>();
  /**
   * trackTypeaheadClosed: This EventEmitter is informational only and is triggered when the typeahead is closed.
   */
  @Output() trackTypeaheadClosed: EventEmitter<void> = new EventEmitter<void>();

  form: UntypedFormGroup;
  private unsubscribe$ = new Subject<void>();

  availableProblems = toSignal(this.problemSelectors.activeProblems, {
    initialValue: [],
  });

  get getProblemString() {
    return getProblemString;
  }

  // internalSelectedProblems stores the value of selectedProblems _before_ any updates take place
  // without this, we are unable to detect if a selection was added or removed, only that the selections have changed.
  internalSelectedProblems: AutoCompletableProblem[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private problemSelectors: ProblemSelectors,
    private problemAttachmentService: ProblemAttachmentsService,
  ) {
    this.form = this.formBuilder.group({
      linkedProblems: new UntypedFormControl([]),
    });
  }

  ngOnInit() {
    this.selectedProblems$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(problems => {
        this.internalSelectedProblems = memoizedMapperFunc(problems);
        this.form.setValue({
          linkedProblems: memoizedMapperFunc(problems),
        });
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get allProblems() {
    return memoizedMapperFunc(this.availableProblems());
  }

  onAdd(problem: AutoCompletableProblem) {
    this.problemAttachmentService
      .createAttachment(
        problem.id,
        this.documentId,
        ProblemAttachmentType.Document,
      )
      .pipe(take(1))
      .subscribe(result => {
        if (!result.errors) {
          this.trackSelectionAdded.emit();
        }
      });
  }
  onRemove(problem: AutoCompletableProblem) {
    const gqlProblem = this.internalSelectedProblems.find(
      p => p.id.toString() === problem.id.toString(),
    );
    const edge = gqlProblem.attachments.edges.find(e => {
      return e.node.id === this.documentId.toString();
    });
    if (edge) {
      this.problemAttachmentService
        .deleteAttachment(edge.id)
        .pipe(take(1))
        .subscribe(result => {
          if (!result.errors) {
            this.trackSelectionRemoved.emit();
          }
        });
    }
  }

  onChange(problems: AutoCompletableProblem[] = []) {
    this.selectionChanged.emit(
      problems.map(p => {
        const { label, ...problem } = p;
        return problem;
      }),
    );
  }

  onOpen(): void {
    this.trackTypeaheadOpened.emit();
  }

  onClose(): void {
    this.trackTypeaheadClosed.emit();
  }

  get selectedProblems$(): Observable<ProblemLike[]> {
    return this.problemAttachmentService.getProblemsByAttachment(
      this.documentId,
      ProblemAttachmentType.Document,
    );
  }
}
