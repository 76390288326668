import {
  Component,
  computed,
  HostBinding,
  inject,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
  signal,
  OnInit,
  DestroyRef,
} from '@angular/core';
import { Summary } from '../../shared/summaries.type';
import { PatientChartSelectors } from '@app/features/patient-chart/store/patient-chart.selectors';
import { GetRecordingInsightsQuery } from '../summaries/get-recordings.onelife.generated';
import { PatientChartActions } from '@app/features/patient-chart/store/patient-chart.actions';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TranscriptSectionCopyEvent } from '@app/features/healthscribe/transcript/transcript-section/transcript-section.component';

export type SidebarMode = 'service-ticket' | 'healthscribe-transcript' | 'none';

@Component({
  selector: 'omg-summary-sidebar',
  templateUrl: './summary-sidebar.component.html',
  styleUrls: ['./summary-sidebar.component.scss'],
})
export class SummarySidebarComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  visibilityClass = computed(() =>
    this.workspaceExpanded() ? 'border-left' : 'hidden',
  );

  ngOnInit(): void {
    // if another action triggers the sidebar open, default to showing the service ticket
    this.patientChartSelectors.workspaceExpanded
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(expanded => {
        if (expanded && this.sidebarMode() === 'none') {
          this.toggle('service-ticket');
        }
      });
  }

  @HostBinding('class') get class(): string {
    return this.visibilityClass();
  }

  summary: InputSignal<Summary> = input.required<Summary>();
  appointmentRecording: InputSignal<
    GetRecordingInsightsQuery['appointmentRecording']
  > = input<GetRecordingInsightsQuery['appointmentRecording']>();

  onAddToNote: OutputEmitterRef<TranscriptSectionCopyEvent> =
    output<TranscriptSectionCopyEvent>();

  patientChartActions = inject(PatientChartActions);
  patientChartSelectors = inject(PatientChartSelectors);
  workspaceExpanded = toSignal(this.patientChartSelectors.workspaceExpanded);
  sidebarMode = signal<SidebarMode>('none');

  toggle(mode: SidebarMode): void {
    if (mode === this.sidebarMode()) {
      mode = 'none';
    }
    this.sidebarMode.set(mode);
    this.patientChartActions.expandWorkspace(mode !== 'none');
  }
}
