import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { GetRecordingInsightsQuery } from '@app/features/summaries/components/summaries/get-recordings.onelife.generated';
import { summaryIsUnsignedStartedAppointment } from '@app/features/summaries/shared/summaries-utils';
import { Summary } from '@app/features/summaries/shared/summaries.type';

@Component({
  selector: 'omg-healthscribe-banner-container',
  templateUrl: './healthscribe-banner-container.component.html',
})
export class HealthscribeBannerContainerComponent {
  @Input() summary: Summary;
  @Input()
  appointmentRecording: GetRecordingInsightsQuery['appointmentRecording'];

  @Output() viewSummary = new EventEmitter<Event>();

  private healthscribeFlag: boolean | undefined;
  private healthscribeInChartFlag: boolean | undefined;
  healthscribeRecordingInProgress: boolean = false;
  healthscribeDismissed: boolean = false;
  constructor(private launchDarkly: LaunchDarklyService) {}

  showHealthscribeBanner(summary: Summary): 'none' | 'v1' | 'v2' {
    this.healthscribeFlag ??= this.launchDarkly.variation<boolean>(
      FeatureFlagNames.healthscribeBannerVersionOne,
      false,
    );
    this.healthscribeInChartFlag ??= this.launchDarkly.variation<boolean>(
      FeatureFlagNames.healthscribeInChart,
      false,
    );

    // Only show if the summary hasn't been signed yet and the appointment is in a started-adjacent state
    const shouldShow = summaryIsUnsignedStartedAppointment(summary);

    if (this.healthscribeFlag && shouldShow) {
      return 'v1';
    } else if (
      this.healthscribeInChartFlag &&
      !this.healthscribeDismissed /* && shouldShow */
    ) {
      return 'v2';
    }
    return 'none';
  }

  dismissHealthScribe(): void {
    this.healthscribeDismissed = true;
  }

  startHealthScribeRecording(): void {
    this.healthscribeRecordingInProgress = true;
  }
}
