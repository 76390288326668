import { Component, Input } from '@angular/core';

import { MismatchedPatientDetail } from './../../shared/renewals.type';

@Component({
  selector: 'omg-mismatched-patient-detail-item',
  templateUrl: './mismatched-patient-detail-item.component.html',
})
export class MismatchedPatientDetailItemComponent {
  @Input() detail: MismatchedPatientDetail;

  constructor() { }
}
