import { BACKSPACE, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Calendar } from 'primeng/calendar';

import { InjectSlashPipe } from '@app/shared/pipes/inject-slash.pipe';
import {
  getShortCutValue,
  isValidShortCut,
} from '@app/utils/dates/date-shortcuts';

type DateFormatType = 'fullYear' | 'shortYear';
type HourFormatType = '12' | '24';
const dateFormats = {
  fullYear: { format: 'mm/dd/yy', placeholder: 'mm/dd/yyyy' },
  shortYear: { format: 'mm/dd/y', placeholder: 'mm/dd/yy' },
};
const hourFormats = {
  '24': { placeholder: 'HH:mm' },
  '12': { placeholder: 'hh:mm' },
};

@Component({
  selector: 'omg-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent),
    },
  ],
})
export class DatePickerComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() yearNavigator = true;
  @Input() monthNavigator = true;
  @Input() dateFormat: DateFormatType = 'fullYear';
  @Input() showTime = false;
  @Input() hourFormat: HourFormatType = '12';
  @Input() yearRange: string;
  @Input() readonlyInput = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() appendTo = 'body';
  @Input() tabindex: number;
  @Input() name = '';
  @Input() showButtonBar = true;
  @Input() label: string;
  @Input() keepInvalid = false;

  @Output() focusDatePicker = new EventEmitter<Event>();
  @Output() blurDatePicker = new EventEmitter<Event>();
  @Output() modifiedDateValue = new EventEmitter<Date>();
  @Output() selectDate = new EventEmitter<Date>();
  @Output() clearClick = new EventEmitter<Event>();

  @ViewChild(Calendar, { static: true }) private ref: Calendar;

  placeholder: string;
  dateFormatString: string;
  cssClass = 'om-date-picker';

  get inputStyleClass(): string {
    if (this.required && !this.ref.inputFieldValue) {
      return `${this.cssClass} ng-invalid`;
    }
    return this.cssClass;
  }

  constructor(
    private ngZone: NgZone,
    private injectSlash: InjectSlashPipe,
  ) {}

  ngOnInit() {
    this.setDateFormat();
    this.setYearRange();

    /* istanbul ignore next */
    this.ngZone.runOutsideAngular(() =>
      window.addEventListener('scroll', this.closeDatePicker.bind(this), true),
    );
  }

  ngOnDestroy(): void {
    /* istanbul ignore next */
    this.ngZone.runOutsideAngular(() =>
      window.removeEventListener(
        'scroll',
        this.closeDatePicker.bind(this),
        true,
      ),
    );
  }

  /* istanbul ignore next */
  writeValue(obj: any) {
    this.ref.writeValue(obj);
  }

  focus() {
    this.ref.inputfieldViewChild?.nativeElement.focus();
  }

  onBlur(event: Event) {
    this.blurDatePicker.emit(event);
  }

  @HostListener('keyup', ['$event.which'])
  onKeyup(key) {
    const inputField = this.ref.inputfieldViewChild!.nativeElement;
    const value = inputField.value;

    if (key === BACKSPACE) {
      return;
    }

    if (key === ENTER && isValidShortCut(value)) {
      const modifiedDateValue = getShortCutValue(value);
      if (modifiedDateValue) {
        this.ref.updateModel(modifiedDateValue);
        this.ref.updateInputfield();
        this.modifiedDateValue.emit(modifiedDateValue);
      }
    } else {
      inputField.value = this.injectSlash.transform(value);
    }
  }

  /* istanbul ignore next */
  registerOnChange(fn: any) {
    this.ref.registerOnChange(fn);
  }

  /* istanbul ignore next */
  registerOnTouched(fn: any) {
    this.ref.registerOnTouched(fn);
  }

  /* istanbul ignore next */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.ref.disabled = isDisabled;
  }

  private setDateFormat() {
    this.dateFormatString = dateFormats[this.dateFormat].format;
    this.setPlaceholder();
  }

  private setPlaceholder() {
    this.placeholder = dateFormats[this.dateFormat].placeholder;
    if (this.showTime) {
      this.placeholder = `${this.placeholder} ${
        hourFormats[this.hourFormat].placeholder
      }`;
    }
  }

  private setYearRange() {
    const minDate = this.minDate ? this.minDate.getFullYear() : '2000';
    const maxDate = this.maxDate ? this.maxDate.getFullYear() : '2030';

    this.yearRange = `${minDate}:${maxDate}`;
  }

  /* istanbul ignore next */
  private closeDatePicker() {
    if (this.ref && this.ref.overlayVisible) {
      this.ref.alignOverlay();
    }
  }
}
