/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type CreateHelpRequestMutationVariables = Types.Exact<{
  helpflowType: Types.Scalars['String']['input'];
  attributes?: Types.InputMaybe<Types.HelpRequestAttributes>;
}>;


export type CreateHelpRequestMutation = { __typename?: 'Mutation', createHelpRequest?: { __typename?: 'CreateHelpRequestPayload', success: boolean, errors?: unknown | null, helpRequest?: { __typename?: 'HelpRequest', id: string, createdAt: string, description: string, slackChannel: string } | null } | null };

export const CreateHelpRequestDocument = gql`
    mutation CreateHelpRequest($helpflowType: String!, $attributes: HelpRequestAttributes) {
  createHelpRequest(input: {helpflowType: $helpflowType, attributes: $attributes}) {
    success
    helpRequest {
      id
      createdAt
      description
      slackChannel
    }
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateHelpRequestMutationService extends Mutation<CreateHelpRequestMutation, CreateHelpRequestMutationVariables> {
    document = CreateHelpRequestDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }