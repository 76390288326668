import { inject, Injectable } from '@angular/core';
import { IBlobEvent, IMediaRecorder } from 'extendable-media-recorder';
import { Observable, Subject } from 'rxjs';
import { MediaRecorderApiService } from './media-recorder-api.service';

const CHUNK_LENGTH_IN_MS = 120_000; // 2 min chunks

export type StatusMessages =
  | 'media_aborted'
  | 'permission_denied'
  | 'no_specified_media_found'
  | 'media_in_use'
  | 'invalid_media_constraints'
  | 'no_constraints'
  | 'recorder_error'
  | 'idle'
  | 'acquiring_media'
  | 'delayed_start'
  | 'recording'
  | 'stopping'
  | 'stopped'
  | 'paused';

@Injectable()
export class AudioRecorderService {
  #mediaRecorderApi = inject(MediaRecorderApiService);

  mediaStream: MediaStream | undefined;
  mediaRecorder: IMediaRecorder | undefined;
  status: StatusMessages = 'idle';

  /**
   * Observable that emits whenever a chunk is emitted from the recorder.
   * This observable is multicast.
   */
  get chunks$(): Observable<Blob> {
    return this.#onNewChunk;
  }

  #onNewChunk = new Subject<Blob>();

  browserSupportsMicrophone(): boolean {
    return this.#mediaRecorderApi.isSupported();
  }

  // Creates a new media stream if it is undefined or if the media stream is completed.
  // Then, it creates a new ExtendableMediaRecorder and sets up the proper listeners.
  // Last, it tells the media recorder to start listening.
  async startRecording(): Promise<void> {
    if (!this.mediaStream) {
      const createdMediaStream = await this.getMediaStream();
      if (!createdMediaStream) throw 'Unable to create MediaStream';
    } else {
      const isStreamEnded = this.mediaStream
        .getTracks()
        .some(track => track.readyState === 'ended');
      if (isStreamEnded) {
        const createdMediaStream = await this.getMediaStream();
        if (!createdMediaStream) throw 'Unable to create MediaStream';
      }
    }

    // User blocked the permissions (getMediaStream errored out)
    if (!this.mediaStream?.active) {
      return;
    }

    this.mediaRecorder = this.#mediaRecorderApi.newMediaRecorder(
      this.mediaStream,
    ); // TODO: options
    this.mediaRecorder.ondataavailable = (event): void =>
      this.onRecordingActive(event);
    this.mediaRecorder.onerror = (): void => {
      this.status = 'recorder_error';
    };
    this.mediaRecorder.start(CHUNK_LENGTH_IN_MS);
    this.status = 'recording';
  }

  // If there is a media recording, pauses the media recording.
  async pauseRecording(): Promise<void> {
    if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      this.status = 'paused';
      this.mediaRecorder.pause();
    }
  }

  // If there is a paused media recording, starts the media recording.
  async resumeRecording(): Promise<void> {
    if (this.mediaRecorder && this.mediaRecorder.state === 'paused') {
      this.status = 'recording';
      this.mediaRecorder.resume();
    }
  }

  // Stops the media recording.
  // Then gets the media stream's audio tracks. For each audio track, calls track.stop.
  async stopRecording(): Promise<void> {
    if (
      this.mediaRecorder &&
      this.mediaStream &&
      this.mediaRecorder.state !== 'inactive'
    ) {
      this.status = 'stopping';
      this.mediaRecorder.stop();
      this.mediaStream.getAudioTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
    }
  }

  private async getMediaStream(): Promise<boolean> {
    this.status = 'acquiring_media';
    try {
      this.mediaStream = await this.#mediaRecorderApi.getMediaStream({
        audio: true,
        video: false,
      });
      this.status = 'idle';
      return true;
    } catch (e: unknown) {
      this.status = 'idle';
      return false;
    }
  }

  private onRecordingActive({ data }: IBlobEvent): void {
    const blob = new Blob([data], { type: 'audio/webm' });
    this.#onNewChunk.next(blob);
  }
}
