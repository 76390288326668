/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type CreateProblemAttachmentMutationVariables = Types.Exact<{
  attachableId: Types.Scalars['ID']['input'];
  attachableType: Types.ProblemAttachmentAttachmentEnum;
  problemId: Types.Scalars['ID']['input'];
}>;


export type CreateProblemAttachmentMutation = { __typename?: 'Mutation', createProblemAttachment?: { __typename?: 'CreateProblemAttachmentPayload', success: boolean, mutationErrors?: Array<{ __typename?: 'MutationError', messages: Array<string>, path: Array<string> }> | null } | null };

export type DeleteProblemAttachmentMutationVariables = Types.Exact<{
  attachmentId: Types.Scalars['ID']['input'];
}>;


export type DeleteProblemAttachmentMutation = { __typename?: 'Mutation', deleteProblemAttachment?: { __typename?: 'DeleteProblemAttachmentPayload', success: boolean, mutationErrors?: Array<{ __typename?: 'MutationError', messages: Array<string>, path: Array<string> }> | null } | null };

export type GetProblemAttachmentsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetProblemAttachmentsQuery = { __typename?: 'Query', problem: { __typename?: 'Problem', id: string, status: Types.ProblemStatus, attachments: { __typename?: 'ProblemAttachmentConnection', edges?: Array<{ __typename?: 'ProblemAttachmentEdge', id: string, createdAt: string, createdBy: { __typename?: 'InternalUser', id: string, displayName: string }, node?: { __typename?: 'Document', originalFilename: string, id: string, note?: { __typename?: 'Note', id: string, subject?: string | null, createdAt: string, signedAt?: string | null, signedBy?: { __typename?: 'InternalUser', displayName: string } | null, internalUser?: { __typename?: 'InternalUser', displayName: string } | null, noteType?: { __typename?: 'NoteType', name?: string | null, tagList?: Array<string> | null } | null } | null } | { __typename?: 'PatientMedication', name: string, status: Types.PatientMedicationStatusEnum, id: string, regimen?: string | null } | null } | null> | null } } };

export type GetProblemsByAttachmentQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  type?: Types.InputMaybe<Types.ProblemAttachmentAttachmentEnum>;
}>;


export type GetProblemsByAttachmentQuery = { __typename?: 'Query', problems: Array<{ __typename?: 'Problem', id: string, status: Types.ProblemStatus, problemType: { __typename?: 'ProblemType', clinicalDescription: string }, attachments: { __typename?: 'ProblemAttachmentConnection', edges?: Array<{ __typename?: 'ProblemAttachmentEdge', id: string, node?: { __typename?: 'Document', id: string } | { __typename?: 'PatientMedication', id: string } | null } | null> | null } }> };

export const CreateProblemAttachmentDocument = gql`
    mutation CreateProblemAttachment($attachableId: ID!, $attachableType: ProblemAttachmentAttachmentEnum!, $problemId: ID!) {
  createProblemAttachment(
    input: {attachableId: $attachableId, attachableType: $attachableType, problemId: $problemId}
  ) {
    success
    mutationErrors {
      messages
      path
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProblemAttachmentMutationService extends Mutation<CreateProblemAttachmentMutation, CreateProblemAttachmentMutationVariables> {
    document = CreateProblemAttachmentDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }
export const DeleteProblemAttachmentDocument = gql`
    mutation DeleteProblemAttachment($attachmentId: ID!) {
  deleteProblemAttachment(input: {problemAttachmentId: $attachmentId}) {
    success
    mutationErrors {
      messages
      path
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProblemAttachmentMutationService extends Mutation<DeleteProblemAttachmentMutation, DeleteProblemAttachmentMutationVariables> {
    document = DeleteProblemAttachmentDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }
export const GetProblemAttachmentsDocument = gql`
    query GetProblemAttachments($id: ID!) {
  problem(id: $id) {
    id
    status
    attachments {
      edges {
        id
        createdAt
        createdBy {
          id
          displayName
        }
        node {
          ... on Document {
            originalFilename
            note {
              id
              subject
              createdAt
              signedAt
              signedBy {
                displayName
              }
              internalUser {
                displayName
              }
              noteType {
                name
                tagList
              }
            }
          }
          ... on PatientMedication {
            name
            regimen: regimenDescription
            status
          }
          id
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProblemAttachmentsQueryService extends Query<GetProblemAttachmentsQuery, GetProblemAttachmentsQueryVariables> {
    document = GetProblemAttachmentsDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }
export const GetProblemsByAttachmentDocument = gql`
    query GetProblemsByAttachment($id: ID!, $type: ProblemAttachmentAttachmentEnum) {
  problems(attachmentId: $id, attachmentType: $type) {
    id
    status
    problemType {
      clinicalDescription
    }
    attachments {
      edges {
        id
        node {
          id
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProblemsByAttachmentQueryService extends Query<GetProblemsByAttachmentQuery, GetProblemsByAttachmentQueryVariables> {
    document = GetProblemsByAttachmentDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }