import {
  animate,
  animation,
  AnimationReferenceMetadata,
  state,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';

const enterAnimation: AnimationReferenceMetadata = animation([
  style({ height: 0, opacity: 0 }),
  animate('0.3s ease-out'),
]);

const leaveAnimation: AnimationReferenceMetadata = animation([
  animate('0.3s ease-out', style({ height: 0, opacity: 0 })),
]);

export function buildExpansionAnimation(triggerName: string) {
  return trigger(triggerName, [
    transition(':enter', useAnimation(enterAnimation)),
    transition(':leave', useAnimation(leaveAnimation)),
    state('*', style({ opacity: 1, overflow: 'hidden' })),
  ]);
}
