import { FuzzyDatePrecision } from '@app/utils';

export interface PatientAllergies {
  items: Array<PatientAllergy>;
  noKnownAllergies: NoKnownAllergies;
}

export interface NoKnownAllergies {
  editedBy: string;
  updatedAt: string;
}

export interface PatientAllergy {
  id?: number;
  patientAllergyHistoryId?: number;
  active?: boolean;
  allergy: Allergy;
  comment?: string;
  customAllergen?: string;
  reaction?: string;
  severityCode?: number;
  startedOn?: string;
  reactions?: AllergicReaction[];
}

export interface AllergicReaction {
  id?: number;
  description: string;
  snomed_code?: string;
  isCustom?: boolean;
}

export interface Allergy {
  drugAllergyCheckCompatible?: boolean;
  clinicalAbbreviation?: string;
  id?: number;
  name?: string;
  isActive?: boolean;
}

export interface PatientAllergiesResponse {
  items?: Array<PatientAllergyResponse>;
  patient_allergy?: PatientAllergyResponse;
  patient_no_known_allergy?: NoKnownAllergyResponse;
}

export interface PatientAllergyReactionsResponse {
  reaction?: string | null;
  custom_reactions?: AllergicReaction[];
  allergic_reactions?: AllergicReaction[];
}

export interface PatientAllergyResponse
  extends PatientAllergyReactionsResponse {
  active?: boolean;
  allergy?: AllergyResponse;
  comment?: string;
  custom_allergen?: string;
  id?: number;
  patient_allergy_history_id?: number;
  severity_code?: number;
  fuzzy_started_on?: string;
  started_on?: Date;
  started_on_precision?: FuzzyDatePrecision;
}

export interface AllergyResponse {
  drug_allergy_check_compatible?: boolean;
  clinical_abbreviation?: string;
  id?: number;
  name?: string;
  is_active?: boolean;
}

export interface NoKnownAllergyResponse {
  edited_by: string;
  updated_at: string;
}

export enum ReactionSnomedCode {
  mild = 255604002,
  moderate = 6736007,
  severe = 24484000,
  unknown = -1,
}

export interface AllergiesReconciliationEvent {
  patientId: number;
  reviewedByUserId: number;
  reviewedAtTimestamp: string;
  reviewerDetails?: {
    name: string;
  };
}

export interface AllergiesReconciliationNotification {
  message?: string;
}

export interface AllergiesReconciliationSuccessResponse {
  internal_user_id: number;
  reviewed_at: string;
  patient_id: number;
}

export interface AllergiesReconciliationErrorResponse {
  error: string;
}

export type AllergiesReconciliationResponse =
  | AllergiesReconciliationSuccessResponse
  | AllergiesReconciliationErrorResponse;

export interface PutAllergiesReconciliationRequest {
  reviewed_at: string;
}

export interface GetAllergiesReconciliationNotificationResponse {
  count: number;
  notification: {
    message: string;
  };
}
