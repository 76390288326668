/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type GetPresignedUploadUrlQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetPresignedUploadUrlQuery = { __typename?: 'Query', appointmentRecording?: { __typename?: 'AppointmentRecording', id: string, presignedUploadUrl?: unknown | null } | null };

export const GetPresignedUploadUrlDocument = gql`
    query GetPresignedUploadUrl($id: ID!) {
  appointmentRecording(id: $id) {
    id
    presignedUploadUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPresignedUploadUrlQueryService extends Query<GetPresignedUploadUrlQuery, GetPresignedUploadUrlQueryVariables> {
    document = GetPresignedUploadUrlDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }