import { Validators } from '@angular/forms';

import { DynamicFormGroup } from '@app/utils/forms/base';

export class PersonalTaskForm extends DynamicFormGroup {
  constructor() {
    super();
    this.addControl({ name: 'description', validators: [Validators.required] });
  }
}
