// @ts-strict-ignore
import { Injectable } from '@angular/core';
import {
  createFeatureSelector,
  createSelector,
  select,
  Store,
} from '@ngrx/store';

import { Summary } from '@app/features/summaries/shared/summaries.type';

import {
  getCurrentSummaryId,
  selectEntities,
  SummariesState,
  summariesStatePath,
} from './summaries.reducer';

export const selectSummariesState = createFeatureSelector<SummariesState>(
  summariesStatePath,
);

export const selectSummaries = createSelector(
  selectSummariesState,
  selectEntities,
);

export const selectSummary = createSelector(
  selectSummaries,
  (summaries, { id, problemId }): Summary => summaries[id],
);

export const selectCurrentSummaryId = createSelector(
  selectSummariesState,
  getCurrentSummaryId,
);

export const selectCurrentSummary = createSelector(
  selectSummaries,
  selectCurrentSummaryId,
  (summaries, id) => summaries[id],
);

export const selectHasAssessedProblemById = createSelector(
  selectCurrentSummary,
  (summary, { problemId }) =>
    summary &&
    summary.assessedProblems.find(problem => problem.id === problemId),
);

export const hasActiveSummary = createSelector(
  selectCurrentSummaryId,
  id => id !== null,
);

const selectAddendums = createSelector(
  selectCurrentSummary,
  summary => summary.summaryAddendums,
);

const selectAssessedProblemHistoryIds = createSelector(
  selectCurrentSummary,
  summary =>
    summary &&
    summary.assessedProblems &&
    summary.assessedProblems.map(problem => problem.problemHistoryId),
);

const selectAssessedProblems = createSelector(
  selectCurrentSummary,
  summary => summary && summary.assessedProblems,
);

export const selectLoading = createSelector(
  selectSummariesState,
  state => state.loading,
);

export const selectError = createSelector(
  selectSummariesState,
  state => state.error,
);

@Injectable({
  providedIn: 'root',
})
export class SummariesSelectors {
  constructor(private store: Store<SummariesState>) {}

  get summaries() {
    return this.store.pipe(select(selectSummaries));
  }

  get currentSummaryId() {
    return this.store.pipe(select(selectCurrentSummaryId));
  }

  get currentSummary() {
    return this.store.pipe(select(selectCurrentSummary));
  }

  getSummaryById(id: number) {
    return this.store.pipe(select(selectSummary, { id, problemId: null }));
  }

  hasAssessedProblem(problemId: number) {
    return this.store.pipe(select(selectHasAssessedProblemById, { problemId }));
  }

  get hasActiveSummary() {
    return this.store.pipe(select(hasActiveSummary));
  }

  get addendums() {
    return this.store.pipe(select(selectAddendums));
  }

  get assessedProblemHistoryIds() {
    return this.store.pipe(select(selectAssessedProblemHistoryIds));
  }

  get assessedProblems() {
    return this.store.pipe(select(selectAssessedProblems));
  }

  get loading() {
    return this.store.pipe(select(selectLoading));
  }

  get error() {
    return this.store.pipe(select(selectError));
  }
}
