/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type GetDocumentSummaryQueryVariables = Types.Exact<{
  documentId: Types.Scalars['ID']['input'];
}>;


export type GetDocumentSummaryQuery = { __typename?: 'Query', document: { __typename?: 'Document', summary?: { __typename?: 'DocumentSummary', id: string, createdAt: string, updatedAt: string, content: { __typename?: 'DocumentSummaryData', detailedSummary?: string | null } } | null } };

export type AddDocumentFeedbackMutationVariables = Types.Exact<{
  input: Types.AddDocumentSummaryFeedbackMutationInput;
}>;


export type AddDocumentFeedbackMutation = { __typename?: 'Mutation', addDocumentSummaryFeedback?: { __typename?: 'AddDocumentSummaryFeedbackMutationPayload', feedback?: { __typename?: 'DocumentSummaryFeedback', id: string } | null } | null };

export const GetDocumentSummaryDocument = gql`
    query GetDocumentSummary($documentId: ID!) {
  document(id: $documentId) {
    summary {
      id
      createdAt
      updatedAt
      content {
        detailedSummary
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDocumentSummaryQueryService extends Query<GetDocumentSummaryQuery, GetDocumentSummaryQueryVariables> {
    document = GetDocumentSummaryDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }
export const AddDocumentFeedbackDocument = gql`
    mutation AddDocumentFeedback($input: AddDocumentSummaryFeedbackMutationInput!) {
  addDocumentSummaryFeedback(input: $input) {
    feedback {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddDocumentFeedbackMutationService extends Mutation<AddDocumentFeedbackMutation, AddDocumentFeedbackMutationVariables> {
    document = AddDocumentFeedbackDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }