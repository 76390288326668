export enum FeatureFlagNames {
  alternateBookingFlowWeb = 'alternate_booking_flow_web',
  analytics = 'analytics',

  ascvdRiskCalculatorForPreventionAndScreening = 'ascvd-risk-calculator-for-prevention-and-screening',
  autoAdvancingDueDatesStructuredResults = 'auto-advancing-due-dates-structured-results',
  autosaveProblemsInChart = 'autosave-problems-in-chart',
  billingDisallowDocumentUploads = 'billing-disallow-document-uploads',
  bhxFollowUpVisitAppointmentTypeAutocomplete = 'follow-up-visit-appointment-type-autocomplete',
  bhxFollowUpVisitIncludesAdminBookedAppointmentTypes = 'follow-up-visit-includes-admin-booked-appointment-types',
  cehrtMedlinePlusConnectLink = 'cehrt-medline-plus-connect-link',
  connectedDeviceOrders = 'orders-connected-device-orders',
  consultantSearchDefaultSortValue = 'consultant-search-default-sort-value',
  consultantSearchSort = 'consultant-search-sort',
  contactValueStarRatings = 'contact-value-star-ratings',
  dsActivateNavbarResourceCenterTriggerAlsoRequiresPendoConfiguration = 'ds-activate-navbar-resource-center-trigger-also-requires-pendo-configuration',
  enhancedTimelineSearch = 'enhanced-timeline-search',
  frontendPlatformJadeTheme = 'frontend-platform-jade-theme',
  growthChartColoringPages = 'growth-chart-coloring-pages',
  growthChartsDownSyndrome = 'growth-charts-down-syndrome',
  healthGoalStructuredPatientReported = 'health-goal-structured-patient-reported',
  linkExternalDocs = 'link-external-docs',
  locationBasedLicensureTaskRouting = 'location-based-licensure-task-routing',
  medsAutoDymoLabel = 'meds-auto-dymo-label',
  medsCancelRx = 'meds-cancel-rx',
  medsChangeRx = 'meds-change-rx',
  medsChangeRxNotesToPharmacist = 'meds-change-rx-notes-to-pharmacist',
  medsCreateSuspectedProblems = 'meds-create-suspected-problems',
  medsDisplayLabAutoreportabilityRules = 'meds_display_lab_autoreportability_rules',
  medsSmokingStatusCehrtRequirements = 'meds-smoking-status-cehrt-requirements',
  medsFamilyHealthHistory = 'meds-family-health-history',
  medsFamilyHealthHistoryAddToNote = 'meds-family-health-history-add-to-note',
  medsRxChangeEditor = 'meds_rx_change_editor',
  medsRxRenewalRequestEditor = 'meds-rx-renewal-request-editor',
  medsSmokingCessationCreateSuspectedProblems = 'meds-smoking-cessation-create-suspected-problems',
  medsVaccineOrderDeleteAndEdit = 'meds-vaccine-order-delete-and-edit',
  medsVitalsRangeValidation = 'meds-vitals-range-validation',
  medsVaccineGrouping = 'meds-vaccine-grouping',
  orderingRemoveIsSelfCheckoutState = 'ordering-remove-is-self-checkout-state',
  orderingElabOrdersController = 'ordering-elab-orders-controller',
  orderingLabcorpApi = 'ordering-labcorp-api',
  orderingEditLabOrderCollectionDateTime = 'ordering-edit-lab-order-collection-date-time',
  orderingMedsRec = 'ordering-meds-rec-launch',
  orderingAllergiesRec = 'ordering-allergies-rec-launch',
  orderingVisuallyPrioritizeStatOrders = 'ordering-visually-prioritize-stat-orders',
  orderingCheckoutSigningAttestation = 'ordering-checkout-signing-attestation',
  orderingEnableProcedureOrderCloning = 'ordering-enable-procedure-order-cloning',
  memberManagementUi = 'member-management-ui',
  mixpanelEventsWithExperimentData = 'mixpanel-events-with-experiment-data',
  new1lifeEnableDeletingOfficeNote = 'new-1life-enable-deleting-office-note',
  new1lifeMessagingDeletion = 'new-1life-messaging-deletion',
  noteRedact = 'note-redact',
  openNewPatientTaskList = 'open-new-patient-task-list',
  patientReportedResultRedesign = 'patient-reported-result-redesign',
  pcpCommentBoxCollapse = 'pcp-comment-box-collapse',
  pcpCommentByline = 'pcp-comment-byline',
  pharmexPromisePhase2 = 'pharmex-promise-phase-2',
  pharmexPromisePhase3 = 'pharmex-promise-phase-3-pricing',
  problemSearchWithICD10Code = 'problem-search-with-icd-10-code',
  problemsScrollPositionOnClose = 'problems-scroll-position-on-close',
  problemToMedsLinkingPhases = 'problems-to-meds-linking-phased',
  acoReachEnabled = 'ramp-aco-reach-enabled',
  showBasicFollowUpOrders = 'show-basic-follow-up-orders',
  showRealTimeVbcRefreshSignals = 'show-real-time-vbc-refresh-signals',
  showAdmissionsInClinicalTimeline = 'show-admissions-in-clinical-timeline',
  smartDraft = 'smart-draft',
  smartDraftInChart = 'smart-draft-in-chart',
  useAuth0JwtAuthForSearch = 'auth0-jwt-auth-for-onelife-ui-search',
  vaccineRecommendation = 'vaccine-recommendation',
  taskChainingActionBar = 'task-chaining-action-bar',
  childHeadCirc = 'under-3-yo-head-circumference',
  draftRenewalCheckoutDenialMessage = 'draft-renewal-checkout-denial-message',
  useSurveySystemNameOnelifeUi = 'use-survey-system-name-onelife-ui',
  expandDocumentsFromWorkspace = 'expand-documents-from-workspace',
  chartDocumentSummarizationVisible = 'chart-document-summarization-visible',
  chartDocumentSummarizationFeedback = 'chart-document-summarization-feedback-enabled',
  chartConversationSummaries = 'chart-conversation-summaries',
  enhancedAPinSummaries = 'enhanced-a-p-in-summaries',
  healthscribeBannerVersionOne = 'healthscribe-banner-version-one',
  healthscribeInChart = 'healthscribe-in-chart',
  createSummaryWithGraphQL = 'create-summary-with-graphql',
  createPersonalTaskInChart = 'create-personal-task-in-chart',
}

type ValueOf<T> = T[keyof T];
export type FeatureFlag = ValueOf<FeatureFlagNames>;
