import { Component, Input } from '@angular/core';

import { formatCptCodes } from '@app/features/summaries/shared/summaries-utils';

import { VisitProcedure } from '../../shared/visit-procedure.type';

@Component({
  selector: 'omg-service-ticket-procedures',
  templateUrl: './service-ticket-procedures.component.html',
  styleUrls: ['./service-ticket-procedures.component.scss'],
})
export class ServiceTicketProceduresComponent {
  @Input() visitProcedure: VisitProcedure;
  @Input() errors: { [key: string]: string[] };

  formatCptCodes = formatCptCodes;

  constructor() { }
}
