// @ts-strict-ignore
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { environment } from '@environments/environment';
import { QueryRef } from 'apollo-angular';
import { get } from 'lodash';
import { MenuItem } from 'primeng/api';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';

import { LoggerService, PatientSelectors } from '@app/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import {
  AnalyticsEvent,
  TrackEventProperties,
} from '@app/core/analytics/analytics.type';
import { DefaultAnalyticsProps } from '@app/core/analytics/default-analytics-props';
import { ConfigService } from '@app/core/config';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { S3Pointer } from '@app/modules/aws/shared/aws-session.type';
import { createAttachmentKey } from '@app/modules/aws/shared/s3-utils';
import { S3Service } from '@app/modules/aws/shared/s3.service';
import { SmartDraftLimitationsModalComponent } from '@app/modules/messaging/components/smart-draft-limitations-modal/smart-draft-limitations-modal.component';
import { ProfileService } from '@app/modules/messaging/shared/profile.service';
import {
  DraftInfoFragment,
  GetSmartDraftQuery,
  GetSmartDraftQueryVariables,
} from '@app/modules/messaging/shared/smart-draft.onelife.generated';
import { SmartDraftService } from '@app/modules/messaging/shared/smart-draft.service';
import { TodoReassignmentFeedbackService } from '@app/modules/messaging/shared/todo-reassignment-feedback.service';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { TodoActions } from '@app/modules/todo/store/todo.actions';
import { TodoSelectors } from '@app/modules/todo/store/todo.selectors';
import { DialogService, FormModel } from '@app/shared';
import { RichTextEditorComponent } from '@app/shared/components/rich-text-editor/rich-text-editor.component';
import { FocusService } from '@app/shared/directives/focus/focus.service';
import { windowToken } from '@app/shared/window/window.service';
import { filterTruthy } from '@app/utils';

import { SmartDraftState } from '../../../../../graphql/onelife.type';
import { MessageTemplatesService } from '../../shared/message-templates.service';
import { isMessage, isPost } from '../../shared/messaging-utils';
import { Message, Post } from '../../shared/messaging.type';
import { Template } from '../../shared/template-insertion.type';
import { TodoReassignment } from '../../shared/todo-reassignment.service';

interface SmartDraftFlag {
  enabled: boolean;
  model?: string;
  prompt_name?: string;
  version?: string;
}
@Component({
  selector: 'omg-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagingComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() post: Post;
  @Input() todo: Todo;
  @Input() todoReassignment: TodoReassignment;
  @Input() docked: boolean;
  @Input() patientWarnings: string;
  @Input() inProgressMessage: Message | Post;
  @Input() postFormModel: FormModel;

  @Output() send = new EventEmitter<any>();
  @Output() deleteDraft = new EventEmitter<void>();
  @Output() closeMessagingComponent = new EventEmitter<void>();
  @Output() minimizeChange = new EventEmitter<boolean>();

  @ViewChild('smartDraftButtons')
  smartDraftButtons: TemplateRef<any>;
  @ViewChild(RichTextEditorComponent)
  editor: RichTextEditorComponent;

  smartDraftLoading = false;
  smartDraftComplete = false;
  pollForSmartDraft$: QueryRef<GetSmartDraftQuery, GetSmartDraftQueryVariables>;
  smartDraft$: Observable<DraftInfoFragment | null>;
  isMinimized = false;
  messageBodyFocusOnKey = 'currentMessage-body';
  canSendAndFinishTaskActions: MenuItem[] = [
    {
      label: 'Send & Leave Task Open',
      command: () => {
        this.onSend();
      },
    },
  ];
  errorMsg: string;
  formSubmitError: boolean;
  isUploading: boolean;
  patientId: number;
  patientAcceptsDigitalCommunications: Observable<boolean>;
  todo$: Observable<Todo>;
  canSmartDraftInChart$: Observable<boolean>;
  canSmartDraft$: Observable<boolean>;
  canViewThreadSummary$: Observable<boolean>;
  messageBodyControl: UntypedFormControl;
  showLicensingBodyOverride$: Observable<boolean>;
  doNotShowSmartDraftLimitationsAgain$: Observable<boolean>;
  insertTemplateEventProperties: Partial<TrackEventProperties>;

  private unsubscribe: Subject<void> = new Subject<void>();
  private currentCursorIndex = 0;

  constructor(
    private todoSelectors: TodoSelectors,
    private focusService: FocusService,
    private config: ConfigService,
    private todoActions: TodoActions,
    private logger: LoggerService,
    private messagingTemplates: MessageTemplatesService,
    private s3Service: S3Service,
    private patientSelectors: PatientSelectors,
    private analytics: AnalyticsService,
    private launchDarklyService: LaunchDarklyService,
    private todoReassignmentFeedbackService: TodoReassignmentFeedbackService,
    private smartDraftService: SmartDraftService,
    private profileService: ProfileService,
    private dialogService: DialogService,
    @Inject(windowToken) private window: Window,
  ) {}

  ngOnInit() {
    this.messageBodyControl = this.postFormModel.form.get(
      'html',
    ) as UntypedFormControl;
    this.patientSelectors.patientId
      .pipe(filterTruthy(), takeUntil(this.unsubscribe))
      .subscribe((patientId: number) => (this.patientId = patientId));

    this.patientAcceptsDigitalCommunications = this.patientSelectors.acceptsDigitalCommunications.pipe(
      filterTruthy(),
      takeUntil(this.unsubscribe),
    );

    this.todo$ = this.todoSelectors.todoById(this.todo.id);
    this.showLicensingBodyOverride$ = this.showLicensingBodyOverride();
    this.canSmartDraftInChart$ = this.launchDarklyService.variation$<boolean>(
      FeatureFlagNames.smartDraftInChart,
      false,
    );
    this.canViewThreadSummary$ = this.launchDarklyService.variation$<boolean>(
      FeatureFlagNames.chartConversationSummaries,
      false,
    );
    this.canSmartDraft$ = this.canOpenSmartDraftSidecar();
    this.setupAnalyticsProperties();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit() {
    if (this.post.contentAttributes.topic) {
      setTimeout(() => {
        this.focusService.setFocus(this.messageBodyFocusOnKey);
      }, 0);
    }
  }

  get sentMessages() {
    return this.post.messages.filter(message => !message.draft);
  }

  get messageThreadExists() {
    return !this.post.draft && !!this.post.contentAttributes.topic;
  }

  get inProgressMessageIsEmpty(): boolean {
    return !this.inProgressMessage || !this.inProgressMessage.id;
  }

  get canFinishTaskOnly(): Observable<boolean> {
    return this.hasIncompleteTodo().pipe(
      map(incomplete => incomplete && this.inProgressMessageIsEmpty),
    );
  }

  get canSendAndFinishTask(): Observable<boolean> {
    return this.hasIncompleteTodo().pipe(
      map(incomplete => incomplete && !this.inProgressMessageIsEmpty),
    );
  }

  get canSendOnly(): Observable<boolean> {
    return this.hasIncompleteTodo().pipe(
      map(incomplete => !incomplete && !!this.inProgressMessage),
    );
  }

  get canReopenTask(): Observable<boolean> {
    return this.hasCompleteTodo();
  }

  get showDeleteDraft(): boolean {
    return (
      this.post.draft || (this.inProgressMessage && !!this.inProgressMessage.id)
    );
  }

  get messageTemplatesIndex(): string {
    return this.config.searchIndex('message_templates');
  }

  get isSavingOrUploading(): boolean {
    return this.postFormModel.state === 'saving' || this.isUploading;
  }

  hasIncompleteTodo(): Observable<boolean> {
    return this.todo && this.todo.id
      ? this.todoSelectors.isIncomplete(this.todo.id)
      : of(false);
  }

  hasCompleteTodo(): Observable<boolean> {
    return this.todo && this.todo.id
      ? this.todoSelectors.isComplete(this.todo.id)
      : of(false);
  }

  showLicensingBodyOverride(): Observable<boolean> {
    return combineLatest([
      this.hasIncompleteTodo(),
      this.todoSelectors.isTreatMeNow(this.todo.id),
      this.launchDarklyService.variation$<boolean>(
        FeatureFlagNames.locationBasedLicensureTaskRouting,
        false,
      ),
    ]).pipe(
      map(
        ([isIncomplete, isTreatMeNow, flagEnabled]) =>
          isIncomplete && isTreatMeNow && flagEnabled,
      ),
    );
  }

  canOpenSmartDraftSidecar(): Observable<boolean> {
    return combineLatest([
      this.launchDarklyService.variation$<SmartDraftFlag>(
        FeatureFlagNames.smartDraft,
        {
          enabled: false,
        },
      ),
      this.canSmartDraftInChart$,
    ]).pipe(
      map(
        ([smartDraftFlag, smartDraftInChartFlag]) =>
          smartDraftFlag.enabled && !smartDraftInChartFlag,
      ),
    );
  }

  onSetMinimized(minimized: boolean) {
    this.isMinimized = minimized;
    this.minimizeChange.emit(minimized);
  }

  onMaximize() {
    if (this.isMinimized) {
      this.isMinimized = false;
      this.minimizeChange.emit(false);
      this.focusService.setFocus(this.messageBodyFocusOnKey);
    }
  }

  onShouldUpdate(event: { data: Message | Post; save: boolean }) {
    // always update for posts
    // only update message if there is text to prevent creating a
    // message without text
    if (event === null) {
      return;
    }

    if (isPost(event.data) || isMessage(event.data)) {
      this.postFormModel.patchValue({
        notify: event.data.notificationRecipient,
        assignRepliesTo: event.data.replyTo,
      });
      const blankMessage =
        isMessage(event.data) && (event.data as Message).html === '';
      if (event.save && !blankMessage) {
        this.postFormModel.save();
      }
    }
  }

  onCommentUpdate(update: 'add' | 'remove') {
    const currentComments = this.post.commentable.totalComments;
    this.post.commentable.totalComments =
      update === 'add' ? currentComments + 1 : currentComments - 1;
  }

  onDeleteDraft() {
    this.deleteDraft.emit();
  }

  onClose() {
    this.closeMessagingComponent.emit();
  }

  onCompleteTodo() {
    this.todoActions.completeAndSignNote(this.todo);
  }

  onReopenTodo() {
    this.todoActions.reopenTodo(this.todo);
  }

  onSend(event = '') {
    if (this.messageBodyPresent()) {
      this.send.emit(event);
      this.trackMessageSentEvent();
    }
  }

  onAddAttachments(files: File[]) {
    if (!this.inProgressMessage.s3Pointers) {
      this.inProgressMessage.s3Pointers = [];
    }
    this.isUploading = true;

    const uploads: Observable<boolean>[] = files.map(file =>
      this.uploadFile(file),
    );
    combineLatest(uploads).subscribe(results => {
      this.isUploading = false;
      if (results.includes(true)) {
        this.postFormModel.save();
      }
    });
  }

  private getPlainTextDraft(): string {
    let draftText = '';
    if (this.inProgressMessage && isPost(this.inProgressMessage)) {
      draftText = get(
        this.inProgressMessage as Post,
        'contentAttributes.html',
        '',
      );
    } else if (this.inProgressMessage && isMessage(this.inProgressMessage)) {
      draftText = get(this.inProgressMessage as Message, 'html', '');
    }
    return (draftText || '').replace(/<[^>]*>/g, '');
  }
  openSmartDraft() {
    const plainTextDraft = this.getPlainTextDraft();
    const draftUrl = `${environment.adminApp.host}/admin/smart-drafts/patients/${this.patientId}/threads/${this.post.id}#${plainTextDraft}`;
    this.window.open(draftUrl, '_blank');
  }

  cancelSmartDraft() {
    this.stopPollingForDraft();
  }
  createSmartDraft() {
    this.doNotShowSmartDraftLimitationsAgain$ = this.profileService
      .get()
      .valueChanges.pipe(
        map(({ data }) =>
          get(data, 'profile.doNotShowSmartDraftLimitationsAgain', false),
        ),
      );
    const plainTextDraft = this.getPlainTextDraft();
    this.smartDraftComplete = false;
    this.smartDraftLoading = true;
    this.smartDraftService
      .create(plainTextDraft, this.post.id.toString())
      .pipe(
        tap((smartDraftId: string | null) => {
          if (!!smartDraftId) {
            this.getSmartDraft(smartDraftId);
          }
        }),
      )
      .subscribe();
  }

  private getSmartDraft(smartDraftId: string) {
    this.pollForSmartDraft$ = this.smartDraftService.get(smartDraftId);
    this.pollForSmartDraft$.startPolling(400);
    this.smartDraft$ = this.pollForSmartDraft$.valueChanges.pipe(
      map(({ data }) => get(data, 'smartDraft')),
    );
    this.smartDraft$.pipe(takeUntil(this.unsubscribe)).subscribe(smartDraft => {
      // We want the very first smartDraft response that comes back as complete
      // Subsequent updates to the SmartDraft feedback values end up in this subscribe as well
      // we dont want to open the modal etc. for those
      const noFeedbackYet = !smartDraft?.feedback
      if (smartDraft?.state === 'COMPLETE' && noFeedbackYet) {
        this.smartDraftComplete = true;
        this.stopPollingForDraft();
        this.messageBodyControl.patchValue(smartDraft.text);
        this.openSmartDraftModal();
      }
      if (smartDraft?.state === SmartDraftState.Failed) {
        this.stopPollingForDraft();
      }
    });
  }

  private stopPollingForDraft() {
    this.smartDraftLoading = false;
    if (this.pollForSmartDraft$) {
      this.pollForSmartDraft$.stopPolling();
    }
  }

  private openSmartDraftModal() {
    this.doNotShowSmartDraftLimitationsAgain$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(doNotShowSmartDraftLimitationsAgain => {
        if (!doNotShowSmartDraftLimitationsAgain) {
          this.dialogService.open(SmartDraftLimitationsModalComponent, {
            autoFocus: true,
          });
        }
      });
  }
  insertText(text: string) {
    if (
      this.currentCursorIndex !== 0 &&
      this.editor.quill.getText()[this.currentCursorIndex] === '\n'
    ) {
      this.insertAndSetCursor(this.currentCursorIndex + 1, text);
    } else {
      this.insertAndSetCursor(this.currentCursorIndex, text);
    }
  }
  trackFieldSelected(subcomponent: string) {
    this.analytics.track(AnalyticsEvent.FieldSelected, {
      ...DefaultAnalyticsProps,
      component: 'Messages',
      subcomponent,
      patientTimelinePostId: this.post.id,
    });
  }

  trackFieldUnselected(subcomponent: string) {
    this.analytics.track(AnalyticsEvent.FieldUnselected, {
      ...DefaultAnalyticsProps,
      component: 'Messages',
      subcomponent,
      patientTimelinePostId: this.post.id,
    });
  }

  trackCommentToggle(expanded: boolean) {
    this.analytics.track(AnalyticsEvent.AddCommentClicked, {
      ...DefaultAnalyticsProps,
      component: 'Messages',
      subcomponent: 'Add Comment Button',
      patientTimelinePostId: this.post.id,
      method: expanded ? 'Expand' : 'Collapse',
    });
  }

  trackDeleteDraftClicked() {
    this.analytics.track(AnalyticsEvent.DeleteDraftClicked, {
      ...DefaultAnalyticsProps,
      component: 'Messages',
      subcomponent: 'Delete Draft Button',
      patientTimelinePostId: this.post.id,
    });
  }

  trackNotifyClicked() {
    this.analytics.track(AnalyticsEvent.NotifyClicked, {
      ...DefaultAnalyticsProps,
      component: 'Messages',
      subcomponent: 'Notify Button',
      patientTimelinePostId: this.post.id,
    });
  }

  trackAssignRepliesClicked() {
    this.analytics.track(AnalyticsEvent.AssignRepliesClicked, {
      ...DefaultAnalyticsProps,
      component: 'Messages',
      subcomponent: 'Assign Replies Button',
      patientTimelinePostId: this.post.id,
    });
  }

  private uploadFile(file: File): Observable<boolean> {
    const key = createAttachmentKey(this.patientId, file.name);
    const s3Pointer = { title: file.name };

    return this.s3Service.upload(key, file).pipe(
      take(1),
      map(data => {
        this.inProgressMessage.s3Pointers.push({
          ...s3Pointer,
          bucket: data.Bucket,
          key: data.Key,
        } as any);

        this.postFormModel.patchValue({
          s3Pointers: this.inProgressMessage.s3Pointers,
        });

        return true;
      }),
      catchError(err => {
        this.errorMsg = `Upload failed: ${err.message}`;
        this.formSubmitError = true;
        this.inProgressMessage.s3Pointers = this.inProgressMessage.s3Pointers.filter(
          pointer => pointer !== s3Pointer,
        );
        return of(false);
      }),
    );
  }

  onDeleteAttachment(attachment: S3Pointer, pointers: S3Pointer[] = []) {
    this.s3Service
      .delete(attachment.key, attachment.bucket)
      .pipe(take(1))
      .subscribe(() => {
        attachment.destroy = true;
        this.postFormModel.patchValue({ s3Pointers: pointers });
        this.postFormModel.save();
      });
  }

  onTextChange() {
    // if quill editor is in focus, getSelection returns index of cursor
    // while applying template, quill editor is not in focus
    // and getSelection returns null
    const cursorIndex =
      this.editor.quill.getSelection() &&
      this.editor.quill.getSelection().index;
    if (cursorIndex) {
      this.currentCursorIndex = cursorIndex;
    }
  }

  onSelectionChange({ range }: any) {
    if (!range) {
      return;
    }
    this.currentCursorIndex = range.index;
  }

  insertTemplate(template: string) {
    if (
      this.currentCursorIndex !== 0 &&
      this.editor.quill.getText()[this.currentCursorIndex] === '\n'
    ) {
      this.insertAndSetCursor(this.currentCursorIndex + 1, template);
    } else {
      this.insertAndSetCursor(this.currentCursorIndex, template);
    }
  }

  private insertAndSetCursor(insertIndex, template) {
    this.editor.insertText(insertIndex, template);
    this.currentCursorIndex = insertIndex + template.length;
  }

  onApplyMessageTemplate(data: { template: Template; done: Function }) {
    if (!data || !data.template) {
      return;
    }

    this.trackTemplateInsertion(data.template);

    this.messagingTemplates
      .get(data.template.id)
      .pipe(take(1))
      .subscribe(template => {
        this.focusService.setFocus(this.messageBodyFocusOnKey);
        this.insertTemplate(template);
        this.postFormModel.save();
        data.done();
      });
  }

  messageBodyPresent(): boolean {
    if (this.postFormModel.form.invalid) {
      this.errorMsg =
        'There was an error sending your message.  Please refresh the page and try again.';
      this.formSubmitError = true;

      this.logger.error(
        '[messaging] Message form invalid',
        this.postFormModel.form.errors,
      );
      return false;
    }

    const html = this.postFormModel.get('html').value;
    const msgAsHtmlDoc = new DOMParser().parseFromString(html, 'text/html');

    if (!html || msgAsHtmlDoc.body.textContent === '') {
      this.errorMsg = 'Please enter a message to send.';
      this.formSubmitError = true;
      return false;
    }

    this.errorMsg = '';
    this.formSubmitError = false;
    return true;
  }

  onAssigneeUpdated(assigneeIdentifier: string) {
    if (
      !this.todoReassignment ||
      this.todoReassignment.feedbackHasNewAssignee
    ) {
      return;
    }

    const [assigneeType, assigneeId] = assigneeIdentifier.split('-');
    this.todoReassignmentFeedbackService.saveAssignee(
      this.todoReassignment.id,
      assigneeId,
      assigneeType,
    );
  }

  /**
   * Suppress propagation of events to extensions etc.
   */
  suppressEnterPropagation($event: KeyboardEvent): void {
    $event.stopPropagation();
  }

  private setupAnalyticsProperties() {
    this.insertTemplateEventProperties = {
      workflow: 'Charting',
      component: 'Message',
      subcomponent: 'Insert Template Button',
      patientTimelinePostId: this.post.id,
      messageDraftType: this.messageDraftType,
    };
  }

  private trackTemplateInsertion(template: Template) {
    if (this.post) {
      this.analytics.track(AnalyticsEvent.TemplateInserted, {
        workflow: 'Charting',
        component: 'Message',
        subcomponent: 'Insert Template Button',
        patientTimelinePostId: this.post.id,
        messageDraftType: this.messageDraftType,
        templateId: template.id,
        templateName: template.name,
        templateType: template.internal_user_id ? 'Private' : 'Public',
      });
    }
  }

  private trackMessageSentEvent() {
    this.analytics.track(AnalyticsEvent.MessageSent, {
      workflow: 'Charting',
      component: 'Message',
      subcomponent: 'Send Button',
      patientTimelinePostId: this.post && this.post.id,
      messageDraftType: this.messageDraftType,
    });
  }

  get messageDraftType(): string {
    return this.docked ? 'Popover' : 'Workspace';
  }
}
