import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Self,
} from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FocusService } from './focus.service';

@Directive({
  selector: '[omgFocusOn]',
})
export class FocusOnDirective implements OnInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('omgFocusOn') focusOn: string;
  @Input() focusOnAction: any;
  @Output() focusOnRequest = new EventEmitter();

  private unsubscribe = new Subject<void>();

  constructor(
    @Self() private elementRef: ElementRef,
    private zone: NgZone,
    private focusService: FocusService,
  ) { }

  ngOnInit() {
    if (!this.focusOn) {
      return;
    }

    this.focusService.focusChanges
      .pipe(
        filter(key => this.focusOn === key),
        takeUntil(this.unsubscribe),
      )
      .subscribe(key => {
        this.focus(key);
      });
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private focus(key: string) {
    // The setTimeout ensures that the hotkey shortcut service, etc. is completed
    // before attempting to set focus on the desired element
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.zone.run(() => {
          this.elementRef.nativeElement.focus();
          this.focusOnRequest.emit(key);
        });
      });
    });
  }
}
