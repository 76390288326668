/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type CreateRecordingMutationVariables = Types.Exact<{
  input: Types.CreateAppointmentRecordingInput;
}>;


export type CreateRecordingMutation = { __typename?: 'Mutation', createAppointmentRecording?: { __typename?: 'CreateAppointmentRecordingPayload', success: boolean, appointmentRecording?: { __typename?: 'AppointmentRecording', id: string } | null } | null };

export const CreateRecordingDocument = gql`
    mutation CreateRecording($input: CreateAppointmentRecordingInput!) {
  createAppointmentRecording(input: $input) {
    success
    appointmentRecording {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateRecordingMutationService extends Mutation<CreateRecordingMutation, CreateRecordingMutationVariables> {
    document = CreateRecordingDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }