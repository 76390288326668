// @ts-strict-ignore
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import {
  EntityMetadataMap,
  getEntityMetadataInitialState,
  resetEntityMetadata,
  updateEntityMetadata,
} from '@app/utils/store';

import { newEntityId } from '../shared/medications.type';
import { PatientMedication } from '../shared/patient-medications.type';
import {
  PatientMedicationAction,
  PatientMedicationActionTypes,
} from './patient-medication.actions';

export const patientMedicationStatePath = 'patientMedications';

export interface PatientMedicationState extends EntityState<PatientMedication> {
  loaded: boolean;
  loading: boolean;
  error: any;
  metadata: EntityMetadataMap<PatientMedication>;
}

const adapter: EntityAdapter<PatientMedication> = createEntityAdapter<
  PatientMedication
>();

export const initialState: PatientMedicationState = adapter.getInitialState({
  loaded: false,
  loading: false,
  error: null,
  ...getEntityMetadataInitialState({}),
});

export function reducer(
  state = initialState,
  action: PatientMedicationAction,
): PatientMedicationState {
  switch (action.type) {
    case PatientMedicationActionTypes.LoadPatientMedications: {
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    }

    case PatientMedicationActionTypes.LoadPatientMedicationsSuccess: {
      return {
        ...adapter.setAll(action.payload, state),
        loaded: true,
        loading: false,
        error: null,
      };
    }

    case PatientMedicationActionTypes.LoadPatientMedicationsError: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: action.payload,
      };
    }

    case PatientMedicationActionTypes.LoadPatientMedicationPrescriptionHistory: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(action.payload, { pending: true }, state),
      };
    }

    case PatientMedicationActionTypes.LoadPatientMedicationPrescriptionHistorySuccess: {
      return {
        ...adapter.updateOne(
          {
            id: action.meta.id,
            changes: {
              prescriptionHistory: action.payload,
              hasPrescriptionHistoryLoaded: true,
            },
          },
          state,
        ),
        loading: false,
        error: null,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: null },
          state,
        ),
      };
    }

    case PatientMedicationActionTypes.LoadPatientMedicationPrescriptionHistoryError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case PatientMedicationActionTypes.LoadPatientMedicationRegimenHistory: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(action.payload, { pending: true }, state),
      };
    }

    case PatientMedicationActionTypes.LoadPatientMedicationRegimenHistorySuccess: {
      return {
        ...adapter.updateOne(
          {
            id: action.meta.id,
            changes: {
              regimenHistory: action.payload,
              hasRegimenHistoryLoaded: true,
            },
          },
          state,
        ),
        loading: false,
        error: null,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: null },
          state,
        ),
      };
    }

    case PatientMedicationActionTypes.LoadPatientMedicationRegimenHistoryError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case PatientMedicationActionTypes.AddPatientMedication: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(newEntityId, { pending: true }, state),
      };
    }

    case PatientMedicationActionTypes.AddPatientMedicationSuccess: {
      return {
        ...adapter.upsertOne(action.payload, state),
        loading: false,
        error: null,
        ...updateEntityMetadata(
          newEntityId,
          { pending: false, error: null },
          state,
        ),
      };
    }

    case PatientMedicationActionTypes.AddPatientMedicationError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          newEntityId,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case PatientMedicationActionTypes.UpdatePatientMedication: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(action.payload.id, { pending: true }, state),
      };
    }

    case PatientMedicationActionTypes.UpdatePatientMedicationSuccess: {
      return {
        ...adapter.updateOne(
          {
            id: action.meta.id,
            changes: {
              latestRegimen: action.payload.medicationRegimen,
              latestPatientMedicationRegimen: action.payload,
            },
          },
          state,
        ),
        loading: false,
        error: null,
        ...resetEntityMetadata(action.meta.id, state),
      };
    }

    case PatientMedicationActionTypes.UpdatePatientMedicationError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case PatientMedicationActionTypes.DeletePatientMedication: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(action.payload, { pending: true }, state),
      };
    }

    case PatientMedicationActionTypes.DeletePatientMedicationSuccess: {
      return {
        ...adapter.removeOne(action.payload, state),
        loading: false,
        ...resetEntityMetadata(action.payload, state),
      };
    }

    case PatientMedicationActionTypes.DeletePatientMedicationError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case PatientMedicationActionTypes.DiscontinuePatientMedication: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(action.payload, { pending: true }, state),
      };
    }

    case PatientMedicationActionTypes.DiscontinuePatientMedicationSuccess: {
      return {
        ...adapter.upsertOne(action.payload, state),
        loading: false,
        ...resetEntityMetadata(action.payload.id, state),
      };
    }

    case PatientMedicationActionTypes.DiscontinuePatientMedicationError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case PatientMedicationActionTypes.ResumePatientMedication: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(action.payload, { pending: true }, state),
      };
    }

    case PatientMedicationActionTypes.ResumePatientMedicationSuccess: {
      return {
        ...adapter.upsertOne(action.payload, state),
        loading: false,
        ...resetEntityMetadata(action.payload.id, state),
      };
    }

    case PatientMedicationActionTypes.ResumePatientMedicationError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case PatientMedicationActionTypes.WritePatientMedicationRx: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(newEntityId, { pending: true }, state),
      };
    }

    case PatientMedicationActionTypes.WritePatientMedicationRxSuccess: {
      return {
        ...state,
        loading: false,
        error: null,
        ...updateEntityMetadata(
          newEntityId,
          { pending: false, error: null },
          state,
        ),
      };
    }

    case PatientMedicationActionTypes.WritePatientMedicationRxError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          newEntityId,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case PatientMedicationActionTypes.RequestRenewal: {
      return {
        ...state,
        loading: true,
      };
    }

    case PatientMedicationActionTypes.RequestRenewalSuccess: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }

    case PatientMedicationActionTypes.RequestRenewalError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case PatientMedicationActionTypes.RequestChange: {
      return {
        ...state,
        loading: true,
      };
    }

    case PatientMedicationActionTypes.RequestChangeSuccess: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }

    case PatientMedicationActionTypes.RequestChangeError: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case PatientMedicationActionTypes.ClearPatientMedications: {
      return {
        ...adapter.removeAll(state),
        loading: false,
        error: null,
      };
    }

    default: {
      return state;
    }
  }
}

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of ids
export const selectPatientMedicationIds = selectIds;

// select the dictionary of entities
export const selectPatientMedicationEntities = selectEntities;

// select the array of items
export const selectAllPatientMedications = selectAll;

// select the total count
export const selectPatientMedicationTotal = selectTotal;
