import { MutationResult } from 'apollo-angular';
import { map, Observable, take, tap } from 'rxjs';

import {
  CreateProblemAttachmentMutation,
  DeleteProblemAttachmentMutation,
} from '@app/modules/problems/shared/problem-attachments.onelife.generated';
import { ProblemAttachmentsService } from '@app/modules/problems/shared/problem-attachments.service';

import {
  Problem,
  ProblemAttachmentAttachmentEnum,
} from '../../../../../graphql/onelife.type';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PatientMedicationAttachmentsServiceFactory {
  constructor(private problemAttachmentService: ProblemAttachmentsService) {}

  /**
   * Creates a new PatientMedicationAttachmentsService for the give patient Medication Id
   *
   * @param patientMedicationId -- patient medication id
   * @returns
   */
  service(patientMedicationId: number): PatientMedicationAttachmentsService {
    return new PatientMedicationAttachmentsService(
      patientMedicationId,
      this.problemAttachmentService,
    );
  }
}

export class PatientMedicationAttachmentsService {
  private problemAttachments: ProblemAttachment[];

  constructor(
    private patientMedicationId: number,
    private problemAttachmentService: ProblemAttachmentsService,
  ) {}

  /**
   * Fetch the problems attached to this patient medication.
   *
   * @returns ProblemAttachments
   */
  get problemAttachments$(): Observable<ProblemAttachment[]> {
    return this.problemAttachmentService
      .getProblemsByAttachment(
        this.patientMedicationId,
        ProblemAttachmentAttachmentEnum.PatientMedication,
      )
      .pipe(
        map(problems => this.problemsToProblemAttachments(problems)),
        tap(
          problemAttachments => (this.problemAttachments = problemAttachments),
        ),
      );
  }

  /**
   * Removes a problem as the patient medication attachment. You should have loaded problem
   * attachments before removing one.
   *
   * @param problemId -- Id of the problem to remove as an attachment.
   */
  removeProblemAttachment(
    problemId: number,
  ): Observable<MutationResult<DeleteProblemAttachmentMutation>> {
    const attachment = this.problemAttachments.find(
      attachment => (attachment.problemId = problemId),
    )!;

    return this.problemAttachmentService
      .deleteAttachment(attachment!.attachmentId)
      .pipe(take(1));
  }

  /**
   * Adds a given problem as an attachment of the patient medication.
   *
   * @param problemId -- problem id to add as attachment
   * @returns
   */
  addProblemAttachment(
    problemId: number,
  ): Observable<MutationResult<CreateProblemAttachmentMutation>> {
    return this.problemAttachmentService
      .createAttachment(
        problemId,
        this.patientMedicationId,
        ProblemAttachmentAttachmentEnum.PatientMedication,
      )
      .pipe(take(1));
  }

  /**
   * Triggers a refetch of all GetAttachmentsByProblem queries.
   *
   * @returns
   */
  refetchProblemAttachments(): void {
    this.problemAttachmentService.refetchAttachmentsByProblem();
  }

  private problemsToProblemAttachments(
    problems: Problem[],
  ): ProblemAttachment[] {
    return problems.map(problem => {
      return {
        problemId: Number(problem.id),
        attachmentId: Number(
          problem.attachments.edges!.find(
            edge => Number(edge!.node!.id) == this.patientMedicationId,
          )!.id,
        ),
      };
    });
  }
}

export type ProblemAttachment = {
  problemId: number;
  attachmentId: number;
};
