import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { waitFor } from '@app/utils';

import { ActiveRoutesActions } from '../store/store/active-routes.actions';
import { ActiveRouteItem } from '../store/store/active-routes.reducer';
import { ActiveRoutesSelectors } from '../store/store/active-routes.selectors';

@Injectable()
export class StickyStatesService implements OnDestroy {
  private regexp = /(?:.+?)patients\/[0-9]+?\/chart\/(.+)/;
  private unsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    private activeRoutesActions: ActiveRoutesActions,
    private activeRoutesSelectors: ActiveRoutesSelectors,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  navigate(url: string): void {
    this.router.navigateByUrl(url);
  }

  shouldClear(
    matchFor: string[],
    urlMatch: string,
    baseMatchUrl: string,
  ): boolean {
    if (
      matchFor.findIndex(route => route === urlMatch) > -1 &&
      this.router.url.indexOf(baseMatchUrl) > -1
    ) {
      return true;
    }

    return false;
  }

  useActiveItem(
    goingToUrl: string,
    urlMatch: string,
    matchFor: string[],
    activeItem: ActiveRouteItem,
  ): void {
    const baseUrl = goingToUrl.replace(urlMatch, '');

    if (matchFor.findIndex(route => route === urlMatch) > -1) {
      if (activeItem && activeItem.path !== '') {
        const fullUrl = `${baseUrl}${activeItem.path}`;
        this.navigate(fullUrl);
      }
    }
  }

  private matchAndNavigate(goingToUrl: string, urlMatch: string): void {
    waitFor(this.activeRoutesSelectors.activeOrders, () => true).subscribe(
      activeOrderRoute => {
        this.useActiveItem(
          goingToUrl,
          urlMatch,
          ['orders', 'orders/list'],
          activeOrderRoute,
        );
      },
    );

    waitFor(this.activeRoutesSelectors.activeWorkspace, () => true).subscribe(
      activeWorkspaceRoute => {
        this.useActiveItem(
          goingToUrl,
          urlMatch,
          ['summaries', 'summaries/new'],
          activeWorkspaceRoute,
        );
      },
    );
  }

  private setActiveItems(urlMatch: string | null): void {
    if (urlMatch) {
      if (urlMatch.indexOf('orders') > -1) {
        if (urlMatch !== 'orders' && urlMatch !== 'orders/list') {
          this.activeRoutesActions.setOrders({ path: urlMatch });
        }
      }

      if (urlMatch.indexOf('procedures') > -1) {
        this.activeRoutesActions.setOrders({ path: urlMatch });
      }

      if (urlMatch.indexOf('summaries') > -1) {
        if (urlMatch !== 'summaries' && urlMatch !== 'summaries/new') {
          this.activeRoutesActions.setWorkspace({ path: urlMatch });
        }
      }

      if (urlMatch.indexOf('notes') > -1) {
        if (urlMatch.startsWith('notes')) {
          this.activeRoutesActions.setWorkspace({ path: urlMatch });
        }
      }

      if (urlMatch.indexOf('patient_timeline_posts') > -1) {
        if (urlMatch.startsWith('patient_timeline_posts')) {
          this.activeRoutesActions.setWorkspace({ path: urlMatch });
        }
      }

      if (urlMatch.indexOf('letters') > -1) {
        if (urlMatch.startsWith('letters')) {
          this.activeRoutesActions.setWorkspace({ path: urlMatch });
        }
      }
    }
  }

  private findMatch(url: string): string | null {
    const matches = url.match(this.regexp);
    return matches && matches.length > 0 ? matches[1] : null;
  }

  setupListeners(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe(e => {
      if (e instanceof NavigationStart) {
        const urlMatch = this.findMatch(e.url);

        if (urlMatch) {
          const shouldClearOrders =
            this.shouldClear(['orders', 'orders/list'], urlMatch, 'orders') ||
            this.shouldClear(['orders', 'orders/list'], urlMatch, 'procedures');

          const shouldClearSummaries = this.shouldClear(
            ['summaries', 'summaries/new'],
            urlMatch,
            'summaries',
          );
          const shouldClearNotes = this.shouldClear(
            ['summaries', 'summaries/new'],
            urlMatch,
            'notes',
          );
          const shouldClearMessaging = this.shouldClear(
            ['summaries', 'summaries/new'],
            urlMatch,
            'patient_timeline_posts',
          );
          const shouldClearLetters = this.shouldClear(
            ['summaries', 'summaries/new'],
            urlMatch,
            'letters',
          );

          if (shouldClearOrders) {
            this.activeRoutesActions.clearOrders();
          }

          if (
            shouldClearSummaries ||
            shouldClearNotes ||
            shouldClearMessaging ||
            shouldClearLetters
          ) {
            this.activeRoutesActions.clearWorkspace();
          }

          if (
            !shouldClearOrders &&
            !shouldClearSummaries &&
            !shouldClearNotes &&
            !shouldClearMessaging &&
            !shouldClearLetters
          ) {
            this.matchAndNavigate(e.url, urlMatch);
          }
        }
      }

      if (e instanceof NavigationEnd) {
        this.setActiveItems(this.findMatch(e.url));
      }
    });
  }
}
