import { DocumentSummary } from './document.type';

export const UnavailableSummary: DocumentSummary = {
  id: '0',
  content: {
    detailedSummary: '',
  },
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
};
