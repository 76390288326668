<div class="omg-dialog-container"
     data-cy="personal-task-modal"
     omgTheme>
  <div omgDialogTitleDirective>
    <h5 class="dialog-title">Create a Personal Task</h5>
    <button omgButton
            variant="none"
            class="om-icon icon-close clickable"
            (click)="close()"></button>
  </div>
  <div omgDialogContentDirective
       om-layout="vertical"
       om-layout-gutter
       class="padding-normal">
    <p>
      Create a task for yourself for this patient. Personal tasks are not recorded in the patient's chart. They will
      only appear in your task queue.
    </p>
    <form [formGroup]="form.controls">
      <label class="plain-text"
             data-cy="task-description"
             om-layout="vertical">
        Task description
        <input omgInputText
               formControlName="description"
               om-flex />
      </label>
    </form>
  </div>
  <div omgDialogActionsDirective>
    <button omgButton
            variant="primary"
            data-cy="create-task-button"
            [disabled]="form.controls.invalid"
            (click)="createTask()">
      Save
    </button>
  </div>
</div>
