import { Component, Input } from '@angular/core';

@Component({
  selector: 'omg-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
  @Input() icon: string = 'primary';
  @Input() library: 'om-icon' | 'fa' = 'om-icon';

  @Input() disabled: boolean = false;
  iconClass(): string {
    let prefix = 'icon-';
    if (this.library == 'fa') {
      prefix = 'fa-';
    }
    return `${this.library} ${prefix}${this.icon}`;
  }
}
