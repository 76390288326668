import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';

import { PendingNewRxSelectors } from '@app/modules/rx-cart';
import { filter, orderBy, pipe } from '@app/utils';
import { CorrelationId, createEntityMetadataSelectors } from '@app/utils/store';

import { selectCart } from '../../rx-cart/store/rx-cart.selectors';
import { MedicationUtility } from '../shared/medications-utils';
import { clinicalSort } from '../shared/patient-medications-utils';
import {
  PatientMedication,
  PatientMedicationStatus,
  PatientMedicationWithCartDetails,
} from '../shared/patient-medications.type';
import { selectMedicationsRootState } from './medications-store-shared';
import { MedicationsRootState } from './medications-store.reducer';
import * as fromPatientMedication from './patient-medication.reducer';

// selects the state slice
export const selectPatientMedicationState = createSelector(
  selectMedicationsRootState,
  state => state.patientMedications,
);

// selects array of ids
export const selectPatientMedicationIds = createSelector(
  selectPatientMedicationState,
  fromPatientMedication.selectPatientMedicationIds,
);

// selects the dictionary
export const selectPatientMedicationEntities = createSelector(
  selectPatientMedicationState,
  fromPatientMedication.selectPatientMedicationEntities,
);

// selects the array
export const selectAllPatientMedications = createSelector(
  selectPatientMedicationState,
  fromPatientMedication.selectAllPatientMedications,
);

// selects by item id
export const selectPatientMedicationById = createSelector(
  selectPatientMedicationState,
  (state, { id }) => state.entities[id],
);

// selects the total number count
export const selectPatientMedicationTotal = createSelector(
  selectPatientMedicationState,
  fromPatientMedication.selectPatientMedicationTotal,
);

// selects loaded
export const selectLoadedPatientMedications = createSelector(
  selectPatientMedicationState,
  state => state.loaded,
);

// selects loading
export const selectLoadingPatientMedications = createSelector(
  selectPatientMedicationState,
  state => state.loading,
);

// selects the general state error
export const selectPatientMedicationsError = createSelector(
  selectPatientMedicationState,
  state => state.error,
);

export const selectPatientMedicationPrescriptionHistory = createSelector(
  selectPatientMedicationById,
  patientMedication => {
    if (patientMedication && patientMedication.prescriptionHistory) {
      const mappedPrescriptionHistory =
        patientMedication.prescriptionHistory.map(item => ({
          ...item,
          displayDate: item.sentAt || item.cartCompleteAt,
        }));
      return orderBy('displayDate', 'desc', mappedPrescriptionHistory);
    }
  },
);

export const selectPatientMedicationRegimenHistory = createSelector(
  selectPatientMedicationById,
  patientMedication => patientMedication && patientMedication.regimenHistory,
);

/**
 * Projected Views
 */

const selectPatientMedicationRoute = createSelector(
  selectAllPatientMedications,
  (medications, { id }) => medications.filter(i => i.medicationRouteId === id),
);

export const selectHasPatientMedication = createSelector(
  selectAllPatientMedications,
  patientMedications =>
    !!(
      patientMedications &&
      patientMedications.filter(MedicationUtility.isMedActive).length > 0
    ),
);

export const selectAllPatientMedicationRegimenIds = createSelector(
  selectAllPatientMedications,
  patientMedications =>
    patientMedications &&
    patientMedications.map(
      item =>
        item.latestPatientMedicationRegimen &&
        item.latestPatientMedicationRegimen.id,
    ),
);

// selects active
export const selectActivePatientMedications = createSelector(
  selectAllPatientMedications,
  patientMedications =>
    pipe(
      filter(MedicationUtility.isMedActive),
      clinicalSort,
    )(patientMedications) as PatientMedication[],
);

export const selectActivePatientMedicationRegimenIds = createSelector(
  selectActivePatientMedications,
  patientMedications =>
    patientMedications &&
    patientMedications.map(
      item =>
        item.latestPatientMedicationRegimen &&
        item.latestPatientMedicationRegimen.id,
    ),
);

export const selectActivePatientMedicationsByRouteId = createSelector(
  selectActivePatientMedications,
  (activeMedications, { id }) =>
    activeMedications.filter(i => i.medicationRouteId === id),
);

// selects inactive
export const selectInactivePatientMedications = createSelector(
  selectAllPatientMedications,
  patientMedications =>
    pipe(
      filter(
        (i: PatientMedication) =>
          i.status === PatientMedicationStatus.DISCONTINUED,
      ),
      clinicalSort,
    )(patientMedications) as PatientMedication[],
);

export const selectPatientMedicationsRxCartDetails = createSelector(
  selectActivePatientMedications,
  selectCart,
  (activeMedications, rxCart): PatientMedicationWithCartDetails[] => {
    const cartItems = rxCart && rxCart.cartItems;
    return activeMedications.map((medication: PatientMedication) => ({
      ...medication,
      cartDetails: {
        loaded: !!cartItems,
        inCart:
          !!cartItems &&
          !!cartItems.find(
            i => i.medicationRegimenId === medication.latestRegimen?.id,
          ),
        isComplete: rxCart && !!rxCart.cartCompleteAt,
      },
    }));
  },
);

/**
 * Entity Metadata
 */

const { selectEntityMetadata, selectEntityWithMetadata } =
  createEntityMetadataSelectors(
    selectPatientMedicationState,
    selectPatientMedicationById,
  );

const selectPatientMedicationMetadata = selectEntityMetadata;
const selectPatientMedicationWithMetadata = selectEntityWithMetadata;

/**
 * Service
 */

@Injectable({
  providedIn: 'root',
})
export class PatientMedicationSelectors {
  constructor(
    private store: Store<MedicationsRootState>,
    private pendingNewRxSelectors: PendingNewRxSelectors,
  ) { }

  get loaded() {
    return this.store.pipe(select(selectLoadedPatientMedications));
  }

  get loading() {
    return this.store.pipe(select(selectLoadingPatientMedications));
  }

  activePatientMedications() {
    return this.store.pipe(select(selectActivePatientMedications));
  }

  inactivePatientMedications() {
    return this.store.pipe(select(selectInactivePatientMedications));
  }

  patientMedication(id: number) {
    return this.store.pipe(select(selectPatientMedicationById, { id }));
  }

  patientMedicationRoute(id: number) {
    return this.store.pipe(select(selectPatientMedicationRoute, { id }));
  }

  patientMedicationMetadata(id: number) {
    return this.store.pipe(select(selectPatientMedicationMetadata, { id }));
  }

  patientMedicationWithMetadata(id: number) {
    return this.store.pipe(select(selectPatientMedicationWithMetadata, { id }));
  }

  patientMedicationPrescriptionHistory(id: number) {
    return this.store.pipe(
      select(selectPatientMedicationPrescriptionHistory, { id }),
    );
  }

  activeMedicationsWithRxCartDetails() {
    return this.store.pipe(select(selectPatientMedicationsRxCartDetails));
  }

  activePatientMedicationsByRouteId(id: number) {
    return this.store.pipe(
      select(selectActivePatientMedicationsByRouteId, { id }),
    );
  }

  patientMedicationRegimenHistory(id: number) {
    return this.store.pipe(
      select(selectPatientMedicationRegimenHistory, { id }),
    );
  }

  pendingOperation(correlationId: CorrelationId) {
    return this.pendingNewRxSelectors.pendingOperation(correlationId);
  }
}
