import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';

import { mapCredentialsToDropdownItem } from '@app/modules/shared-rx/utils';

import { PrescriberCredentialsState } from './prescriber-credentials.reducer';
import { selectRxCartState } from './rx-cart-store-shared';

export const selectPrescriberCredentialsState = createSelector(
  selectRxCartState,
  state => state && state.prescriberCredentials,
);

export const selectPrescriberCredentials = createSelector(
  selectPrescriberCredentialsState,
  state => state && state.entity,
);

export const selectPending = createSelector(
  selectPrescriberCredentialsState,
  state => state && state.pending,
);

export const selectPrescriberId = createSelector(
  selectPrescriberCredentials,
  prescriberCredentials => prescriberCredentials && prescriberCredentials.id,
);

export const selectCredentials = createSelector(
  selectPrescriberCredentials,
  prescriberCredentials =>
    prescriberCredentials && prescriberCredentials.prescribingCredentials,
);

export const selectCredential = createSelector(
  selectCredentials,
  (credentials, { id }) =>
    credentials &&
    (credentials || []).filter(credential => credential.id === id),
);

const selectCredentialDropdownItems = createSelector(
  selectCredentials,
  credentials => mapCredentialsToDropdownItem(credentials),
);

@Injectable({ providedIn: 'root' })
export class PrescriberCredentialsSelectors {
  constructor(private store: Store<PrescriberCredentialsState>) {}

  get prescriberCredentials() {
    return this.store.pipe(select(selectPrescriberCredentials));
  }

  get id() {
    return this.store.pipe(select(selectPrescriberId));
  }

  get credentials() {
    return this.store.pipe(select(selectCredentials));
  }

  get pending() {
    return this.store.pipe(select(selectPending));
  }

  credential(id: number) {
    return this.store.pipe(select(selectCredential, { id }));
  }

  get credentialDropdownItems() {
    return this.store.pipe(select(selectCredentialDropdownItems));
  }
}
