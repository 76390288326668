// @ts-strict-ignore
import { Validators } from '@angular/forms';

import { DynamicFormGroup } from '../../../utils/forms/base';

export class MLNoteRetitleFeedbackForm extends DynamicFormGroup {
  constructor() {
    super();
    this.initControls();
  }

  readonly specialtyOptions = [
    { value: 'allergy', label: 'Allergy' },
    { value: 'dermatology', label: 'Dermatology' },
    { value: 'gastroenterology', label: 'Gastroenterology' },
    { value: 'gynecology', label: 'Gynecology' },
    { value: 'obstetrics', label: 'Obstetrics' },
    {
      value: 'orthopedics and sports medicine',
      label: 'Orthopedics & Sports Medicine',
    },
    { value: 'otolaryngology', label: 'Otolaryngology' },
    { value: 'sleep medicine', label: 'Sleep Medicine' },
    { value: 'other', label: 'Other' },
  ];

  controlHasError(controlName: string): boolean {
    const control = this.controls.get(controlName);
    return control.touched && control.invalid;
  }

  otherSpecialtySelected(): boolean {
    const control = this.controls.get('specialty');
    return control.value === 'other';
  }

  private initControls(): void {
    this.addControl({
      name: 'specialty',
      validators: [Validators.required],
      defaultValue: null,
    });
    this.addControl({
      name: 'otherSpecialty',
    });
    this.addControl({
      name: 'otherFeedback',
    });
  }
}
