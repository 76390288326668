// @ts-strict
import { formatToLegacyFuzzyDatePrecision, parseFuzzyDate } from '@app/utils';

import {
  AllergicReaction,
  AllergiesReconciliationErrorResponse,
  AllergiesReconciliationNotification,
  AllergiesReconciliationEvent,
  AllergiesReconciliationResponse,
  AllergyResponse,
  GetAllergiesReconciliationNotificationResponse,
  NoKnownAllergies,
  NoKnownAllergyResponse,
  PatientAllergies,
  PatientAllergiesResponse,
  PatientAllergy,
  PatientAllergyReactionsResponse,
  PatientAllergyResponse,
  ReactionSnomedCode,
} from './allergies.type';
import { HttpErrorResponse } from '@angular/common/http';

const allergyPath = '/v2/admin/';

export const allergiesRoute = (
  patientId: number,
  baseRoute: string,
  subRoute: string = '',
  allergyId?: number,
): string => {
  if (allergyId) {
    return `${allergyPath}${baseRoute}/${allergyId.toString()}?patientId=${patientId}`;
  }

  return `${allergyPath}${baseRoute}/${patientId}${subRoute}`;
};

const mapToAllergyResponse = (
  item: PatientAllergyResponse,
): Partial<AllergyResponse> => {
  if (item.custom_allergen && !item.allergy) {
    return { name: item.custom_allergen };
  }
  return item.allergy || {};
};

export const mapAllergyPatientReactions = (
  allergy: PatientAllergyReactionsResponse,
): AllergicReaction[] => {
  let result = allergy.allergic_reactions ?? [];
  result = result.concat(
    allergy.custom_reactions?.map(r => ({ ...r, isCustom: true })) ?? [],
  );

  if (allergy.reaction) {
    result = result.concat({ description: allergy.reaction, isCustom: true });
  }

  return result.filter(r => r.description);
};

export const mapToPatientAllergyEntity = (
  item?: PatientAllergyResponse,
): PatientAllergy => {
  if (!item) {
    return {} as PatientAllergy;
  }

  const mappedAllergy = mapToAllergyResponse(item);
  return {
    id: item.id,
    active: item.active,
    allergy: {
      id: mappedAllergy.id,
      name: mappedAllergy.name,
      isActive: mappedAllergy.is_active,
      drugAllergyCheckCompatible: mappedAllergy.drug_allergy_check_compatible,
      clinicalAbbreviation: mappedAllergy.clinical_abbreviation,
    },
    comment: item.comment,
    customAllergen: item.custom_allergen,
    patientAllergyHistoryId: item.patient_allergy_history_id,
    reaction: item.reaction ?? undefined,
    severityCode: item.severity_code,
    startedOn: item.fuzzy_started_on,
    reactions: mapAllergyPatientReactions(item),
  };
};

export const mapAllergyResponseToEntity = (
  response: PatientAllergiesResponse,
): PatientAllergies => {
  // Allergy data returns as an array (items) or as an object (patient_allergy)
  const items: PatientAllergy[] = response.items
    ? response.items.map(mapToPatientAllergyEntity)
    : [mapToPatientAllergyEntity(response.patient_allergy)];

  return {
    items,
    noKnownAllergies:
      (response.patient_no_known_allergy && {
        editedBy: response.patient_no_known_allergy.edited_by,
        updatedAt: response.patient_no_known_allergy.updated_at,
      }) ||
      ({} as any),
  };
};

export const buildAllergyRequest = (
  entity: Partial<PatientAllergy>,
): PatientAllergyResponse => {
  const startedOnUnset = entity.startedOn === undefined;
  const fuzzyStartedOn = entity.startedOn
    ? parseFuzzyDate(entity.startedOn)
    : undefined;
  const startedOn = fuzzyStartedOn?.date;
  const startedOnPrecision =
    fuzzyStartedOn &&
    formatToLegacyFuzzyDatePrecision(fuzzyStartedOn.precision);

  return {
    id: entity.id,
    active: entity.active,
    allergy: {
      id: entity.allergy?.id,
      name: entity.allergy?.name,
    },
    custom_allergen: entity.customAllergen,
    comment: entity.comment,
    reaction: entity.reactions ? null : entity.reaction,
    severity_code: entity.severityCode,
    started_on: startedOnUnset ? undefined : startedOn,
    started_on_precision: startedOnUnset ? undefined : startedOnPrecision,
    allergic_reactions: entity.reactions?.filter(r => !r.isCustom),
    custom_reactions: entity.reactions?.filter(r => r.isCustom),
    patient_allergy_history_id: entity.patientAllergyHistoryId,
  };
};

export const mapNoKnownAllergyResponseToEntity = (
  response: NoKnownAllergyResponse,
): NoKnownAllergies => ({
  editedBy: response.edited_by,
  updatedAt: response.updated_at,
});

export const getActivePatientAllergies = (
  patientAllergies: PatientAllergies,
) => {
  const pa = (patientAllergies || {}) as any;
  const allergies = pa.items || [];

  return allergies
    .filter((item: PatientAllergy) => item.active)
    .sort(sortPatientAllergiesByName);
};

export const getInactivePatientAllergies = (
  patientAllergies: PatientAllergies,
) => {
  const pa = (patientAllergies || {}) as any;
  const allergies = pa.items || [];

  return allergies
    .filter((item: PatientAllergy) => !item.active)
    .sort(sortPatientAllergiesByName);
};

const sortPatientAllergiesByName = (
  a: PatientAllergy,
  b: PatientAllergy,
): number => {
  if (!a.allergy.name || !b.allergy.name) return 0;
  if (a.allergy.name > b.allergy.name) {
    return 1;
  } else {
    return b.allergy.name > a.allergy.name ? -1 : 0;
  }
};

export const getAllergyNotCheckedWarning = (
  patientAllergy: PatientAllergy,
): string | undefined => {
  if (patientAllergy.customAllergen) {
    return 'Custom allergens are not included during automatic drug-allergy checks.';
  } else if (
    patientAllergy.allergy &&
    !patientAllergy.allergy.drugAllergyCheckCompatible
  ) {
    return 'Inactive ingredients or retired allergy concepts are not included during automatic drug-allergy checks.';
  }
};

export const addAbbreviationToName = item => {
  if (item.clinical_abbreviation) {
    item.name = item.name + ` (${item.clinical_abbreviation})`;
  }
  return item;
};

export const reactionSeverityMap = {
  [ReactionSnomedCode.mild]: 'Mild',
  [ReactionSnomedCode.moderate]: 'Moderate',
  [ReactionSnomedCode.severe]: 'Severe',
  [ReactionSnomedCode.unknown]: '',
};

export const getReactionSeverityText = (
  patientAllergy: PatientAllergy,
): string => {
  const severity =
    (patientAllergy.severityCode !== undefined &&
      reactionSeverityMap[patientAllergy.severityCode]) ||
    '';
  const reactionDesc = patientAllergy.reactions
    ? patientAllergy.reactions.map(r => r.description).join(', ')
    : '';
  const separator = severity && reactionDesc ? ': ' : '';
  return [severity, reactionDesc].join(separator);
};

export const mapAllergiesReconciliationResponseToEntity = (
  response: AllergiesReconciliationResponse,
): AllergiesReconciliationEvent => {
  if (isAllergiesReconciliationErrorResponse(response)) {
    throw new HttpErrorResponse({
      error: {
        message: `Received an error response when fetching allergy rec. error:  ${response.error}`,
      },
      status: 404,
    });
  }

  return {
    patientId: response.patient_id,
    reviewedByUserId: response.internal_user_id,
    reviewedAtTimestamp: response.reviewed_at,
  };
};

export function mapAllergiesReconciliationNotificationToEntity(
  response: GetAllergiesReconciliationNotificationResponse,
): AllergiesReconciliationNotification {
  return {
    message: response.notification?.message,
  };
}

/**
 * Validate object matches type specification of a AllergyRecErrorResponse
 */
function isAllergiesReconciliationErrorResponse(
  response: unknown,
): response is AllergiesReconciliationErrorResponse {
  return (
    typeof response == 'object' &&
    response !== null &&
    'error' in response &&
    response['error'] !== null
  );
}
