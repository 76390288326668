import { distinctUntilChanged, map } from 'rxjs/operators';

import { StateSelector } from '@app/core/store/shared/state';
import { get } from '@app/utils/shared';

import { ProcedureOrder } from '../shared/procedure-order.type';

export class ProcedureOrderSelectors extends StateSelector<ProcedureOrder> {
  get errors() {
    return this.error.pipe(
      distinctUntilChanged(),
      map(error => get('error.errors', error)),
    );
  }
}
