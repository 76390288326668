/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type ProvideTodoMessageReassignmentFeedbackMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  feedbackCategory?: Types.InputMaybe<Types.Scalars['String']['input']>;
  feedbackText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ProvideTodoMessageReassignmentFeedbackMutation = { __typename?: 'Mutation', upsertMlFeedbackTodoMessageReassignment?: { __typename?: 'UpsertMlFeedbackTodoMessageReassignmentPayload', success: boolean, errors?: unknown | null } | null };

export type SaveNewTodoMessageReassignmentAssigneeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  assigneeId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  assigneeType: Types.Scalars['String']['input'];
}>;


export type SaveNewTodoMessageReassignmentAssigneeMutation = { __typename?: 'Mutation', upsertMlFeedbackTodoMessageReassignment?: { __typename?: 'UpsertMlFeedbackTodoMessageReassignmentPayload', success: boolean, errors?: unknown | null } | null };

export const ProvideTodoMessageReassignmentFeedbackDocument = gql`
    mutation ProvideTodoMessageReassignmentFeedback($id: ID!, $feedbackCategory: String, $feedbackText: String) {
  upsertMlFeedbackTodoMessageReassignment(
    input: {todoMessageReassignmentId: $id, feedbackCategory: $feedbackCategory, feedbackText: $feedbackText}
  ) {
    success
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProvideTodoMessageReassignmentFeedbackMutationService extends Mutation<ProvideTodoMessageReassignmentFeedbackMutation, ProvideTodoMessageReassignmentFeedbackMutationVariables> {
    document = ProvideTodoMessageReassignmentFeedbackDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }
export const SaveNewTodoMessageReassignmentAssigneeDocument = gql`
    mutation SaveNewTodoMessageReassignmentAssignee($id: ID!, $assigneeId: ID, $assigneeType: String!) {
  upsertMlFeedbackTodoMessageReassignment(
    input: {todoMessageReassignmentId: $id, newAssigneeId: $assigneeId, newAssigneeType: $assigneeType}
  ) {
    success
    errors
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveNewTodoMessageReassignmentAssigneeMutationService extends Mutation<SaveNewTodoMessageReassignmentAssigneeMutation, SaveNewTodoMessageReassignmentAssigneeMutationVariables> {
    document = SaveNewTodoMessageReassignmentAssigneeDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }