import { sortBy } from 'lodash/fp';

import {
  mapMedicationRegimenToForm,
} from './medications-utils';
import {
  PatientMedication,
  PatientMedicationForm,
} from './patient-medications.type';

export const mapPatientMedicationToForm = (
  data: PatientMedication,
): Partial<PatientMedicationForm> => ({
  id: data.id,
  ...mapMedicationRegimenToForm(data.latestRegimen),
});

export const clinicalSort = (medications: PatientMedication[]) =>
  sortBy(medication => {
    let duration = 100;
    let isPrn = 0;

    if (medication.latestPatientMedicationRegimen) {
      const latestRegimen = medication.latestPatientMedicationRegimen;
      if (latestRegimen.discontinuesAt) {
        duration = 0;
      }
      if (latestRegimen.isPrn) {
        isPrn = 1000;
      }
    }

    if (duration && isPrn) {
      duration = 0;
    }

    const routeName = medication.route.name;
    const firstCharacterCode = routeName.toLowerCase().charCodeAt(0);
    const lowerCaseA = 97;
    const alphabeticalDelta = firstCharacterCode - lowerCaseA;

    return duration + isPrn + alphabeticalDelta;
  }, medications);
