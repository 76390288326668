<omg-collapsible-header chevron="left"
                        [expanded]=true
                        (analyticsExpandedChange)="analyticsExpandedChange($event)"
                        data-testid="collapsible-header">
  <div header
       class="container">
    <div class="header">{{header}}</div>
    <omg-icon-button icon="copy-to-note"
                     (keypress)="copySection($event)"
                     (click)="copySection($event); false"
                     data-testid="copy-button"></omg-icon-button>
  </div>
  <div message
       class="text-body">

    {{body}}
  </div>
</omg-collapsible-header>
