import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LetDirective, PushPipe } from '@ngrx/component';

import { CollapseProviderDirective } from './collapse-provider.directive';
import { CollapseToggleDirective } from './collapse-toggle.directive';
import { CollapseDirective } from './collapse.directive';
import { CollapsedComponent } from './collapsed.component';
import { ExpandedComponent } from './expanded.component';

const declarations = [
  CollapseDirective,
  CollapseProviderDirective,
  CollapseToggleDirective,
  CollapsedComponent,
  ExpandedComponent,
];

@NgModule({
  imports: [CommonModule, LetDirective, PushPipe],
  exports: [...declarations],
  declarations: [...declarations],
  providers: [],
})
export class CollapseModule {}
