import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  ControlOption,
  DynamicFormGroup,
  SearchableFormGroup,
} from './../../../utils/forms/base';

@Component({
  selector: 'omg-searchable-auto-complete',
  templateUrl: './searchable-auto-complete.component.html',
  styleUrls: ['./searchable-auto-complete.component.css'],
})
export class SearchableAutoCompleteComponent {
  @Input() form: SearchableFormGroup & DynamicFormGroup;
  @Output() focusOn = new EventEmitter<void>();
  @Output() focusOut = new EventEmitter<void>();

  constructor() { }

  focus() {
    this.focusOn.emit();
  }

  focusout() {
    this.focusOut.emit();
  }

  trackByName(index, option: ControlOption<any>) {
    return option.name;
  }
}
