// @ts-strict-ignore
import { ActionReducerMap } from '@ngrx/store';

import {
  EXPAND_TIMELINE,
  EXPAND_TIMELINE_ERROR,
  EXPAND_TIMELINE_SUCCESS,
  EXPAND_WORKSPACE,
  EXPAND_WORKSPACE_ERROR,
  EXPAND_WORKSPACE_SUCCESS,
  PatientChartAction,
} from './patient-chart.actions';

export const patientChartStatePath = 'patientChart';

export interface Timeline {
  expanded: boolean;
}

export interface Workspace {
  expanded: boolean;
}

export interface TimelineState {
  entity: Timeline;
  error: boolean;
  loading: boolean;
}

export interface WorkspaceState {
  entity: Workspace;
  error: boolean;
  loading: boolean;
}

export interface PatientChartRootState {
  timeline: TimelineState;
  workspace: WorkspaceState;
}

export const timelineInitialState: TimelineState = {
  entity: null,
  error: null,
  loading: false,
};

export const workspaceInitialState: WorkspaceState = {
  entity: null,
  error: null,
  loading: false,
};

function workspaceReducer(
  state = workspaceInitialState,
  action: PatientChartAction,
): WorkspaceState {
  switch (action.type) {
    case EXPAND_WORKSPACE: {
      return {
        ...state,
        entity: action.payload,
        loading: true,
        error: null,
      };
    }
    case EXPAND_WORKSPACE_SUCCESS: {
      return { ...state, entity: action.payload, loading: false, error: null };
    }
    case EXPAND_WORKSPACE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}

function timelineReducer(
  state = timelineInitialState,
  action: PatientChartAction,
): TimelineState {
  switch (action.type) {
    case EXPAND_TIMELINE: {
      return {
        ...state,
        entity: action.payload,
        loading: true,
        error: null,
      };
    }
    case EXPAND_TIMELINE_SUCCESS: {
      return { ...state, entity: action.payload, loading: false, error: null };
    }
    case EXPAND_TIMELINE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}

export const reducers: ActionReducerMap<PatientChartRootState> = {
  timeline: timelineReducer,
  workspace: workspaceReducer,
};
